.country{
    color: white;
}
.top-search-field > .search-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex ;
}
.chart-head{
    color: #15264F;
    font-size: 18px;
    line-height: 21px;
    margin: 0 0 10px 0;
    height:40px;
}
/* CSS for responsive table */

.icon-accordion {
    display: none;
}
.content-box td h3 {
    margin: 0;
    padding: 0 0 5px 0;
    display: none;
    font-weight: bold;
}

@media only screen and (max-width: 1024px) and (min-width: 768px){
    .hide-in-tab{
        display: none;
    }
    .global-table .table tbody tr td table tbody tr td:first-child span{
        width: auto;
    }
    .global-table.customer-table.rowwidthtasktable .table thead tr th:first-child {
        width: 16%;
    }
}


@media(max-width: 1023px){
   
    
    .parts-group-btn + .global-table.customer-table .table thead tr th:first-child{
        width: 12%;
    }
    .table .arrow>span:after{
        right: -8px;
    }
    .table .arrow>span:before{
        right: -8px;
    }
    .global-table.customer-table .table.suppliyerToolTable thead tr th:nth-child(5) {
        width: 15%;
    }
}

@media (max-width: 767px) {
    .parts-group-btn + .global-table.customer-table .table.vehiclesToolTableList thead tr th:first-child{
        width: 70%;
    }
    .team-card-inn-body.top-search-field .global-table .table thead tr th:first-child{
        padding: 4px 0 0 12px;
    }
    .global-table .table tbody tr td table tbody tr td{
        text-align: left;
    }
    .accordion-opened .table-drop-status.status-select.status-report-select .custom-select{
        margin-left: 12px;
    }
    .hide-in-mob2 #add-icon{
        display: none;
    }
    /* accordion table tbody */
    .accordion-table > tbody > tr > td > h3 {
      font-weight: 500;
      font-size: 14px;
      margin: 0;
      padding: 0 0 0 8px;
      color: #4c4c4c;
      line-height: 30px;
      text-align: left;
      float: left;
      max-width: 30%;
  }
    .icon-accordion  {
        display: block;
        color: #B71B1B;
        text-align: center;
        width: 16px;
        height: 16px;
        position: absolute;
        right: 15px;
        top: 15px;
        font-style: normal;
        font-size: 1.6em;
        /* pointer-events: none; */
    }
    
      .accordion-table > tbody > tr > td {
        width: 100% !important;
    }
    .content-box td h3 {
        display: block;
    }
 
    .accordion-table > tbody > tr > td:not(:first-child){
        display: inline-block;
    }
    /* css for straight expanded table rows  */
    .accordion-table > tbody > tr  > td:not(:first-child){
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
    .accordion-table > tbody > tr  > td:not(:first-child):nth-child(6){
        padding-left: 0px !important;
    }
    .accordion-table > tbody > tr  > td:not(:first-child) > h3{
      min-width: 150px;
  }
  .global-table table table tbody tr td:first-child{
        padding-left: 15px;
    }
    #thid > tbody > tr > th > span {
        display: inline-block;
        width: 150px;
    }
    #thid > tbody > tr > i{
        display: none;
    }
    .accordion-table > tbody > tr > td:first-child{
      vertical-align: middle;
      line-height: 50px;
    }
    .accordion-table > tbody > tr > td > h3{
        min-width: 150px;
    }
    .accordion-table > tbody > tr > td  {
     
      display: -webkit-box;
     
      display: -ms-flexbox;
     
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
   }
   
   .table-header{
      width: 100%;
      display: inline-block;
   }
   .table .arrow span:before {
      right: -18px;
      top: 11px;
  }
  .table .arrow span:after{
      right: -18px;
      top: 11px;
  }
 
  #thid > tbody > tr > th :first-child > span{
      display: inline-block;
  }
  #thid > tbody > tr > th :first-child {
      line-height: 50px;
  }
  /* HIDING TABLE HEADING */
  .table.table-striped.table-sm > thead {
      display: block;
  }

  table.table-striped.table-sm > .show-in-mobile-table-head {
    display: block;
  }

  #thid > tbody > tr:first-child > th:first-child{
      width:300px;
      line-height: 50px;
  }


  .global-table .table thead tr th:first-child {
      padding-left: 0;
      padding: 18px 0 0 12px;
  }
  .global-table.global-sm-table .table thead tr th:first-child{
      min-width: 148px;
  }
  /* accordian table tbody ends here  */ 

/* css for main container starts */
    .filter-btn{
      display: none !important;
  }
  
  .filter-btn-grp .dropdown.filter-dropdown{
    /* display: none !important; */
  }

/* .top-search-field > h1, .top-search-field > .btn.btn_primary.add, .top-search-field > .btn.btn_primary,.filter-btn-grp > button.btn.btn_primary.add, #add-vehicle-responsive{
    display: none !important;
} */
/* .top-search-field > .search-container{
    display: none ;
} */

.pops-over{
    margin-left: 0px;
    left:0 !important;
    margin-bottom: 15px;
}
.filter-btn-grp {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
}
.filter-btn-grp .filter-row{
    margin-bottom: 14px;
}
.filter-row > .appliyed-filter{
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    overflow: scroll;
}
/* css for vertical floting icons starts */
  .vertical-bar{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding-right: 12px;
      z-index: 98;
  }
  .vertical-bar > div{
      margin-bottom: 10px;
  }
   /* custom */

 .vertical-bar > .speaker-wrap.user-dropdown > .dropdown-menu.show{
      left:-25px !important;
      top:-17px !important;
  }
  .vertical-bar > .speaker-wrap.user-dropdown .dropdown-menu.show:before {
      content: "";
      width: 26px;
      height: 15px;
      background: url(/img/header/top-arrow.png);
      position: absolute;
      right: 15px;
      top: auto;
      margin-left: -7px;
      bottom: -15px;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
     
  }

/* css for vertical floting icons end */ 

/* CSS for card body starts here */
 .card-body > .row > div.col-md-4{
     margin-bottom: 15px;
 }
/* CSS for card body ends here */
    .btn.btn-outline-primary.show-reports,.btn-container-above-table{
        display: none;
    }
    .btn-container-above-table{
      /* display : none !important; */
      padding-top: 12px;
    }
    .main-container#analytic-id:before{
        height: 100px !important;
    }
    /* .graph-area{
        margin-left: -25px;
        margin-right: -25px;
    } */
    .col-md-6 > h1{
        margin-top:10px !important;
        margin-bottom: 10px !important;
        font-size: 18px !important; 
    }
    .customer-detail-modal{
        width: auto;
    }
    .chart-head {       
        height: 18px;
        margin: 10px 0 10px 0;
    }
    
}

@media (max-width: 1024px) {
    .filter-btn-grp.inventory-finter-group {
        display: block;
    }
    .filter-btn-grp.inventory-finter-group .parts-group-btn{
        margin-bottom: 16px;
    }
    .filter-btn-grp.inventory-finter-group .parts-group-btn + .d-flex{
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-justify-content: space-between;
    }
    .mob-header-logo{
        display: block;
    }
    .mob-header-logo{
        margin: 0;
        padding-left: 20px;
    }
  
    /* .top-search-field > h1, .top-search-field > .btn.btn_primary.add, .top-search-field > .btn.btn_primary,.filter-btn-grp > button.btn.btn_primary.add, #add-vehicle-responsive{
        display: none !important;
    } */

    /* .top-search-field > .btn.btn_primary.add, .top-search-field > .btn.btn_primary,.filter-btn-grp > button.btn.btn_primary.add, #add-vehicle-responsive{
        display: none !important;
    } */
    /* css for removing right side extra padding on lower desktop and mobile */
        .main-container.add-side-report , .main-container{
            width: 100%;
            
            padding: 15px 12px 15px 12px;
        }
        .report-container .report-head {
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            -webkit-justify-content: space-between;
            padding: 0px 20px;
        }
        .btn-container-above-table{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            margin-bottom: 10px;
        }
        .btn.btn-outline-primary.show-reports{
            max-height: 40px;
        }
        .graph-area{
            z-index: 97;
        }
        .main-container#analytic-id:before{
            height: 300px;
        }
        .country ul li, .country li{
            color: white;
        }
        .country .select {
            background: black;
        }
        #country:before{
            background-position: -78px 527px;
        }
        #country.open:before {
            background-position: -78px 538px;
        }
        .pops-over {
            position: relative;
            margin-left: 0px;
            left:20px;
        }
        
}

@media (max-width: 480px){
    .global-table .table tbody tr td table tbody tr td{
        text-align: left;
    }
    .bottomLayer{
        display: block;
    }
    .responsive-mob  .bottomLayer .filter-btn-grp{
        display: block;
        width: 100%;
    }
    /* Show mobile Table */
    .showTableInMobileWebView {
        display: block;
    }
    /* .global-table table table tbody tr td:first-child{
        padding-left: 0;
    } */
    .accordion-table > tbody > tr > td > h3{
        min-width: 124px;
    }
    .parts-group-btn{
        width: 100%;
        overflow: hidden;
        display: block;
    }
    .parts-group-btn button{
        margin-bottom: 12px;
    }
    .inventory-finter-group .parts-group-btn + .d-flex{
        display: block !important;
    }
    .parts-group-btn button:first-child{
        margin-right: 0;
    }

    /* Hide tablet and web view table */
    .hideTableInMobileView {
        display: none;
    }

    .accordion-table > tbody > tr > td:not(:first-child) > h3 {
        min-width: 120px;
    }
    .accordion-table > tbody > tr > td:first-child > h3 {
        padding-left: 0;
    }
    .report-container .report-head {
        padding-right: 30px;
    }
    .report-container > .report-head{
        height: 50px;;
    }
    .content-box{
        overflow-x: hidden;
    }
    .design_fix_import_device_1 {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }
    .design_fix_import_device_2 {
        width: 100%;
        margin-left: 0;
    }    
}


/* css for buttons */
@media (max-width:480px){
    .btn_success, .disabled-btn.btn_table,.btn.btn_primary{
        min-width: 110px;
    }
    .accordion-table > tbody > tr > td:first-child{
        vertical-align: middle;
      }
    h1{
        font-size: 22px;
    }
    /* .btn.btn_primary.add{
        padding: 6px 6px;
    }
    .modal .modal-content .modal-body {
        padding: 12px;
    }
    .btn_success, .btn.btn_primary{
        font-size: 12px;
    }
    span > .icon.sprite-icon1.add{
        width: 12px;
        height: 12px;
        background-position: -63px -524px;
    } */
    .card-body{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .card > .card-header {
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        -webkit-align-items: flex-start;
        padding: 6px 15px;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-justify-content: center;
    }
    .card > .card-header > p{
        font-size: 14px;
    }
    .card > .card-header > div > label {
        font-size: 12px;
    }
    .pops-over > p{
       font-size: 12px;
    }
    .pops-over {
        min-width: 180px;
    }
    .tax-heading-wrap{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;

    }
    .tax-heading-wrap > div{
        margin-left: 0;
        margin-bottom: 10px;
    }
    .wrapper--w790 .card-body {
        padding-left: 10px !important;
        margin-right: 10px !important;
    }

}
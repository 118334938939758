.events {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .notes-month {
    text-align: center;
    font-size: 28px;
  }
  .notes-month section {
    font-size: 28px;
  }


  /* custom css */

.demo-app-calendar .fc-view-container .fc-head .fc-day-header {
  font-size: 14px;
  vertical-align: middle;
  color: #4c4c4c;
  font-weight: 500;
  height: 60px;
}


.demo-app-calendar .fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number{
  float: none;
  padding-top: 20px;
  display: inline-block;
  padding-left: 10px;
  font-size: 12px;
  color: #4c4c4c;
}

.demo-app-calendar tbody tr:nth-child(1) .fc-event, .demo-app-calendar .fc-event.fc-allow-mouse-resize{
  background-color: #f3f8f1;
  border: none;;
  color: #6AA14E;
  font-weight: 600;
  padding-left: 10px;
  border-left: 4px solid #6AA14E;
  margin-top: 2px;
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
  height: 20px;
}
.demo-app-calendar tbody tr:nth-child(2) .fc-event{
  background-color: #f1fafe;
  border: none;
  color: #4DBCE9;
  font-weight: 600;
  padding-left: 10px;
  border-left: 4px solid #4DBCE9;
  margin-top: 2px;
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
  height: 20px;
}




.ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
  padding-right: 12px;
  padding-bottom: 5px;
  text-align: center;
  height: 60px;
  font-size: 14px;
  color: #4c4c4c;
  font-weight: 500;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-table thead{
  border-bottom: 1px solid #efefef;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month, .ant-fullcalendar-fullscreen .ant-fullcalendar-date{
  display: block;
    height: 124px;
    margin: -2px -1px -1px -2px;
    padding: 4px 0px;
    color: rgba(0, 0, 0, 0.65);
    text-align: left;
    /* border-top: 2px solid #e8e8e8; */
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    border: 1px solid #efefef;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-value{
  padding-top: 10px;
  padding-left: 10px;
  text-align: left;
  font-size: 12px;
  color: #4c4c4c;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-content ul.events li{
  height: 20px;
  color: #6AA14E;
  background-color: #f3f8f1;
  position: relative;
  margin-bottom: 2px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-content{
  margin-top: 16px;
  height: 78px;
}
.ant-fullcalendar-fullscreen .ant-badge-status-dot {
  position: absolute;
  top: 0;
  display: inline-block;
  width: 4px;
  height: 20px;
  vertical-align: middle;
  border-radius: 0;
}
.ant-fullcalendar-fullscreen .ant-badge-status-success{
  background-color: #6AA14E;
}
.ant-fullcalendar-fullscreen .ant-badge-status-success + span{
  color:#6AA14E;
  font-weight: 600;
  font-size: 12px;
  padding-left: 6px;
  line-height: 20px;
}
.ant-fullcalendar-fullscreen .ant-badge-status-warning{
  background-color: #4DBCE9;
}
.ant-fullcalendar-fullscreen .ant-badge-status-warning + span{
  color:#4DBCE9;
  font-weight: 600;
  font-size: 12px;
  padding-left: 6px;
  line-height: 20px;
}

.ant-fullcalendar-fullscreen .ant-badge-status-error{
  background-color: #f5222d;
}
.ant-fullcalendar-fullscreen .ant-badge-status-error + span{
  color:#f5222d;
  font-weight: 600;
  font-size: 12px;
  padding-left: 6px;
  line-height: 20px;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-month:hover, .ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover{
  background: transparent;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month, .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date{
  border-top-color: #efefef;
}

.ant-fullcalendar table {
  background-color: white;
}

.ant-badge-status-text {
  color: #f5222d;
}

.ant-fullcalendar-date .job-list-count {
  position: absolute;
  top: 12px;
  right: 10px;
  background: #B71B1B;
  border-radius: 50%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
  padding: 4px;
  min-width: 30px;
  min-height: 30px;
  font-size: 12px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group{
  display: none;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-header {
  margin-top: -67px;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body {
  padding-top: 85px !important;
}

@media(max-width: 480px) {
  .ant-fullcalendar-fullscreen .ant-fullcalendar-header {
    margin-top: -20px;
  }
  
  .ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body {
    padding-top: 10px !important;
  }
}
body {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  color: #000;
}

#root-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

label {
  font-weight: normal;
}

.fixedpreview {
  width: 750px;
  height: 400px;
  overflow: auto;
}

.notificationlistitemmargintop {
  margin-top: -16px;
}

p {
  margin-bottom: 12px;
}

h1 {
  font-size: 30px;
  line-height: 35px;
  color: #4c4c4c;
  font-weight: normal;
  margin-bottom: 0;
}

ul li {
  list-style: none;
}

.icon-accordion {
  display: none;
}

/* padding define */

.pad-rt-12 {
  padding-right: 12px;
}

.pad-lt-12 {
  padding-left: 12px;
}

.pad-rt-15 {
  padding-right: 15px;
}

.pad-lt-15 {
  padding-left: 15px;
}

.pad-lt-0 {
  padding-left: 0;
}

.pad-rt-0 {
  padding-right: 0;
}

#pad-lt-0 {
  padding-left: 0 !important;
}

.pad-rt-20 {
  padding-right: 20px;
}

.pad-rt-24 {
  padding-right: 24px;
}

.pad-lt-20 {
  padding-left: 20px;
}

.pad-rt-10 {
  padding-right: 10px;
}

.pad-lt-10 {
  padding-left: 10px;
}

.pad-0 {
  padding: 0;
}

.mr-bt-30 {
  margin-bottom: 30px;
}

.mr-bt-0 {
  margin-bottom: 0;
}

.line-height-18 {
  line-height: 18px;
}

.mr-bt-20 {
  margin-bottom: 20px;
}

.mr-lt-20 {
  margin-left: 8%;
}

.mr-lt-10 {
  margin-left: 10px;
}

.mr-top-14 {
  margin-top: 14px;
}

.mr-top-44 {
  margin-top: 44px;
}

.mr-top-8 {
  margin-top: 8px;
}

.mr-top-0 {
  margin-top: 0;
}

.mr-top-minus-4 {
  margin-top: -4px;
}

hr.seperator.mr-top-0 {
  margin-top: 0;
}

.mr-top-24 {
  margin-top: 24px;
}

.mr-top-8 {
  margin-top: 8px;
}

.mr-top-16 {
  margin-top: 16px;
}

.mr-bt-16 {
  margin-bottom: 16px;
}

.mr-top-30 {
  margin-top: 30px;
}

.mr-bt-24 {
  margin-bottom: 24px;
}

.mr-lt-30 {
  margin-left: 30px;
}

.mr-rt-30 {
  margin-right: 30px;
}

.mr-rt-25 {
  margin-right: 25px;
}

.mr-rt-20 {
  margin-right: 20px;
}

.mr-rt-10 {
  margin-right: 10px;
}

.mr-lt-20 {
  margin-left: 8%;
}

.mr-lt-20-px {
  margin-left: 20px;
}

.mr-bt-10 {
  margin-bottom: 10px;
}

.posi-relative {
  position: relative;
}

.custom-select.min-176 {
  min-width: 176px;
}

.custom-select.min-250 {
  min-width: 250px;
}

/* .table-fixed > thead {
    width: 100%;
  }
  .table-fixed > tbody {
    height: 230px;
    overflow-y: auto;
    width: 100%;
  }
  .table-fixed > thead, .table-fixed > tbody, .table-fixed > tr, .table-fixed > td, .table-fixed > th {
    display: block;
  }

  .table-fixed tbody td, .table-fixed thead > tr> th {
    float: left;
    border-bottom-width: 0;
  } */

/* .table-fixed { overflow-y: auto; height: 250px; }
.table-fixed table > thead th { position: sticky; top: 0; background: #B71B1B !important; } */

/* .table-fixed {
    width: 100%;
  }
  .table-fixed > tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
  }
  .table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
    display: block;
  }
  .table-fixed tbody td {
    float: left;
  }
  .table-fixed thead tr th {
    float: left;
    background-color: #f39c12;
    border-color: #e67e22;
  } */

.sidebar div:first-child > div:first-child {
  overflow-y: auto !important;
}

.mr-rt-20 {
  margin-right: 20px;
}

.btn-type-right {
  text-align: right;
}

.float-rt {
  float: right;
}

.text-rt {
  text-align: right;
}

.text-center {
  text-align: center;
}

.dispaly-none {
  display: none;
}

/* end of padding define */

/* body dopshaow */

body.drop-shadow {
  position: relative;
  overflow: hidden;
}

body.drop-shadow:before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}

/* button css */

.btn {
  min-width: 154px;
  min-height: 40px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  background: #fff;
  font-weight: 500;
  padding: 6px 18px;
}

.unreadnotification {
  background-color: gainsboro;
}

.list-wrap.hoverToSeeMore .cust-checkbox .checkmark {
  left: 10px;
}

.material-icon-checkmark {
  position: absolute;
  right: 42px;
  top: -2px;
}

.material-icon-checkmark-report {
  position: absolute;
  left: -30px;
  top: -4px;
}

.btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn_table {
  min-height: 48px;
  height: 48px;
}

.btn_success {
  border: 1.5px solid #6aa14e;
  color: #6aa14e;
}

.btn_success:hover {
  border: 1.5px solid #6aa14e;
  color: #ffffff;
  background: #6aa14e;
}

.btn_danger {
  border: 1.5px solid #b71b1b;
  color: #b71b1b;
}

.btn_danger:hover {
  border: 1.5px solid #b71b1b;
  color: #ffffff;
  background: #b71b1b;
}

.btn_dark {
  border: 1.5px solid #282c37;
  color: #282c37;
}

.btn_dark:hover {
  border: 1.5px solid #282c37;
  color: #fff;
  background: #282c37;
}

.btn_primary {
  border: 1.5px solid #4dbce9;
  color: #4dbce9;
}

.btn_primary:hover {
  border: 1.5px solid #4dbce9;
  color: #fff;
  background: #4dbce9;
}

.btn_checkout {
  border: 1.5px solid #6aa14e;
  color: #6aa14e;
}

.btn_checkout:hover {
  border: 1.5px solid #6aa14e;
  color: #fff;
  background: #6aa14e;
}

.btn-icon-wrap .icon {
  position: relative;
  width: 16px;
  height: 16px;
  display: inline-block;
  background-position: -61px -522px;
  margin-right: 10px;
  top: 2px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.btn-icon-wrap .icon.upload-icon {
  background-position: -97px -213px;
}

.upload-icon:hover .btn-icon-wrap .icon.upload-icon {
  background-position: -121px -213px;
}

.btn-icon-wrap .icon.checkout {
  background-position: -97px -64px;
  width: 20px;
  height: 20px;
}

.checkout-btn:hover .chat-list-btn .icon.checkout {
  background-position: -127px -64px;
}

.notif-dot {
  background-color: #00ff00;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 3px;
  right: 3px;
  border-radius: 50%;
}

.chat-list-btn .icon.individuals {
  background-position: -142px -233px;
  width: 14px;
  height: 20px;
}

.chat-undelivered {
  text-align: end;
  margin-right: 40px;
}

.chat-undelivered-text {
  color: red;
}

.chat-list-btn button.active .icon.individuals {
  background-position: -121px -233px;
}

/* .checkout-btn:hover .chat-list-btn .icon.groupchat{
    background-position: -127px -64px;
} */

.chat-list-btn .icon.groupchat {
  background-position: -146px -258px;
  width: 20px;
  height: 22px;
  top: 4px;
}

.chat-list-btn button.active .icon.groupchat {
  background-position: -120px -258px;
}

.chat-list-btn button, .chat-list-btn button:hover {
  color: #a9aaae;
  line-height: 30px;
  padding: 0;
}

.chat-list-btn button.btn.active {
  border: 1px solid #b71b1b;
  background-color: #ffffff;
  /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
  color: #b71b1b;
  padding: 0 5px;
}

.btn.add:hover .btn-icon-wrap .icon {
  background-position: -61px -498px;
}

.block_btn {
  width: 100%;
  display: block;
}

.user-data-time-card-link {
  text-decoration: none;
}

.link-form {
  cursor: pointer;
}

.scrollbar {
  height: calc(100vh - 180px);
  overflow-y: scroll;
  margin-bottom: 25px;
}

.force-overflow-edit-team {
  /* min-height: 450px; */
}

#edit-team-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#edit-team-scrollbar::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#edit-team-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(180, 175, 175);
}

button.clone.btn {
  -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
  border-radius: 4px;
  min-height: 30px;
  height: 30px;
  border: 1px solid #6aa14e;
  min-width: auto;
  font-weight: normal;
  color: #6aa14e;
  padding: 5px 12px;
}

.search-chip {
  border: 1px solid #4dbce9;
  border-radius: 4px;
  color: #4dbce9;
  font-size: 14px;
  padding: 0 33px 0 10px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  font-weight: 500;
  min-width: 113px;
  display: inline-block;
  position: relative;
}

.search-chip span {
  position: absolute;
  width: 15px;
  height: 15px;
  background-position: -76px 446px;
  top: 8px;
  right: 10px;
}

.disabled-btn, .disabled-btn:hover {
  color: #e6e6e6;
  border: 1px solid #e6e6e6;
  background: transparent;
  min-width: 188px;
  cursor: not-allowed;
}

button.btn.mute-btn {
  border: 1.5px solid #292c36;
  max-width: 123px;
  min-width: 123px;
  background-color: #ffffff;
  min-height: 30px;
  padding: 0 15px 0 38px;
  position: relative;
}

button.btn.mute-btn .mute-icon.sprite-icon {
  position: absolute;
  left: 13px;
  background-position: -208px -546px;
  width: 20px;
  height: 20px;
}

.btn-zoom {
  border: 1px solid #4c4c4c;
  height: 60px;
  width: 60px;
  border-radius: 4px;
  background-color: #ffffff;
  cursor: pointer;
}

.btn-zoom:hover {
  border: 1px solid #4dbce9;
  background-color: #4dbce9;
}

/* end of buttom css */

/* bootstrap Modal design css */

#job-link.modal .modal-dialog {
  max-width: 730px;
}

#recipients-modal.modal .modal-dialog {
  max-width: 730px;
}

#hose-modal.modal .modal-dialog {
  max-width: 535px;
}

#special-hazard-modal.modal .modal-dialog {
  max-width: 730px;
}

#special-hazard-modal .modal-body {
  height: 130px;
}

.footer-btn-alg button {
  margin: 0 auto;
}

.modal-footer {
  border-top: none;
  padding: 24px;
  background: #f5f5f5;
  display: block;
}

.modal-title {
  text-transform: uppercase;
}

.modal-body.gry-bg {
  background: #f5f5f5;
}

.modal .modal-dialog {
  max-width: 448px;
}

#master-acc.modal .modal-dialog {
  max-width: 586px;
}

.modal .modal-content {
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.22), 0 24px 24px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.22), 0 24px 24px 0 rgba(0, 0, 0, 0.3);
  /* border-radius: 2px; */
  border: none;
  background-color: #ffffff;
}

#deleteConfirmationModal {
  max-width: 450px;
  margin: 0 auto;
}

.modal .modal-content .modal-header {
  border-radius: 2px 2px 0 0;
  background-color: #292c36;
  -webkit-box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
  height: 68px;
  border-bottom: 2px solid #b71b1b;
  padding: 24px;
}

.modal .modal-content .modal-header h4 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.modal .modal-content .modal-body {
  padding: 24px;
}

.modal .modal-content .modal-header button {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-position: -30px -418px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
  opacity: 1;
}

.modal .modal-content .modal-body {
  /* padding: 24px; */
}

.modal-backdrop.show {
  opacity: 0.45;
}

/* custom input type file css */

.custom-input-file.height-84 label {
  height: 84px;
}

.custom-input-file label {
  padding: 10px;
  background-color: #F2F2F2;
  display: table;
  color: #fff;
  height: 111px;
  border: 1px solid #CBD1D5;
  border-radius: 4px;
  margin: 0;
  cursor: pointer;
  width: 100%;
  font-weight: normal;
}

.custom-input-file label .inner-label {
  display: block;
  height: 100%;
  border: 1px dashed #c4c4c4;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  height: 111px;
}

.custom-input-file label .inner-label .upload-text {
  color: #000000;
  font-size: 14px;
  margin-top: 2px;
}

.custom-input-file input[type="file"] {
  display: none;
}

.activate-btns button {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-flex: 1;
}

.activate-btns button:first-child {
  margin-right: 16px;
}

.modal .modal-content .modal-body .modal-text {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 20px;
  color: #4c4c4c;
}

/* end of bootstrap Modal design css */

/* custom input type file css */

.custom-input-file.height-84 label {
  height: 84px;
}

.custom-input-file label {
  padding: 10px;
  background-color: #f2f2f2;
  display: table;
  color: #fff;
  height: 111px;
  border: 1px solid #cbd1d5;
  border-radius: 4px;
  margin: 0;
  cursor: pointer;
  width: 100%;
}

.custom-input-file label .inner-label {
  display: block;
  height: 100%;
  border: 1px dashed #c4c4c4;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.custom-input-file label .inner-label .upload-text {
  color: #000000;
  font-size: 14px;
  margin-top: 2px;
}

.custom-input-file input[type='file'] {
  display: none;
}

.custom-input-file label .inner-label .upload-icon {
  width: 24px;
  height: 24px;
  background-position: -58px -420px;
}

.custom-input-file.attach-icon label .inner-label .upload-icon {
  width: 24px;
  height: 24px;
  background-position: -549px -207px;
}

.custom-input-file label .inner-label .upload-icon.down {
  width: 24px;
  height: 24px;
  background-position: -277px -490px;
}

.custom-file-input1 {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.custom-file-input1 input[type='file'] {
  width: 100%;
  height: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  zoom: 1;
  /* Fix for IE7 */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.custom-input-file label .inner-label img {
  width: 40px;
}

.file-uploaded .upload-text, .file-uploaded .upload-icon {
  display: none;
}

.custom-input-file.file-uploaded input[type='file'] {
  display: block;
  opacity: 1;
  position: relative;
  /* width: calc(100% - 100px); */
  /* left: -50px; */
  color: #000;
  margin-left: -45px;
  outline: none;
}

.file-uploaded .wrap-input {
  position: relative;
  /* margin-left: 50px; */
}

.file-uploaded .wrap-input .show-after {
  width: 90px;
  position: absolute;
  height: 28px;
  top: 0;
  left: -45px;
  background: #fff;
  z-index: 999;
}

.custom-input-file.file-uploaded label .inner-label {
  background: #fff;
}

.custom-input-file.file-uploaded, .custom-input-file {
  width: 100%;
}

.custom-input-file.bg-chg.file-uploaded img {
  display: block;
}

.custom-input-file.bg-chg img {
  display: none;
}

.validation-error-style {
  color: #dc3545;
  /* font-size: 12px;
  font-weight: 500; */
  margin-left: 120px;
  /* margin-top: 8px; */
}

.upload-error-text {
  color: #b71b1b;
  font-size: 12px;
  font-weight: 500;
}

.custom-input-file.error-upload label {
  border: 1px solid #b71b1b;
  background: #fff;
}

.custom-input-file.error-upload label .inner-label {
  border: 1px dashed #b71b1b;
}

.custom-input-file.error-upload label .inner-label .upload-icon.down {
  width: 24px;
  height: 24px;
  background-position: -280px -558px;
}

/* end of custom input type file css */

/* date picker css */

.datepicker .datepicker-switch {
  width: 145px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.22), 0 24px 24px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.22), 0 24px 24px 0 rgba(0, 0, 0, 0.3);
  border: none;
}

.datepicker-dropdown th.next, .datepicker-dropdown th.prev {
  color: #fff;
  position: relative;
}

.datepicker-dropdown th.next:before {
  background: url(img/sprite.png);
  background-size: 600px;
  background-position: -77px 594px;
  content: '';
  width: 10px;
  height: 18px;
  position: absolute;
  display: inline-block;
}

.datepicker-dropdown th.prev:before {
  background: url(img/sprite.png);
  background-size: 600px;
  background-position: -76px 593px;
  content: '';
  width: 10px;
  height: 18px;
  position: absolute;
  display: inline-block;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.datepicker thead tr:first-child th:hover {
  background: transparent !important;
}

.datepicker table tr th {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.38);
  font-weight: normal;
}

.datepicker table tr td {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
}

.datepicker table tr td, .datepicker table tr th {
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  border: none;
}

.datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.active.active {
  color: #fff !important;
  background-color: #b71b1b !important;
  border-color: #b71b1b !important;
}

.datepicker table tr td.day:hover, .datepicker table tr td.day.focused {
  background: #b71b1b !important;
  color: #fff !important;
}

.datepicker-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  display: -webkit-flex;
}

.datepicker-wrap input.form-control+label {
  line-height: 19px;
}

.datepicker-wrap input:focus+label {
  color: #E33945;
}

.datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover:hover, .datepicker table tr td span.active:focus, .datepicker table tr td span.active:hover:focus, .datepicker table tr td span.active.disabled:focus, .datepicker table tr td span.active.disabled:hover:focus, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:hover.active, .open .dropdown-toggle.datepicker table tr td span.active, .open .dropdown-toggle.datepicker table tr td span.active:hover, .open .dropdown-toggle.datepicker table tr td span.active.disabled, .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #B71B1B !important;
  border-color: #B71B1B!important;
}

.datepicker table tr td span {
  height: 44px !important;
  line-height: 44px !important;
}

.datepicker-wrap.sprite-icon-before:before {
  background-position: -32px -446px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 14px;
  top: 40px;
  z-index: 12px;
  pointer-events: none;
  background: url(img/calender-vr-2.png);
  background-repeat: no-repeat;
}

.SingleDatePickerInput__withBorder {
  width: 100%;
}

.datepicker-wrap input {
  cursor: pointer;
}

/* end of date picker css */

/* pops over css */

.pops-over {
  border: 1px solid #000000;
  border-radius: 4px;
  background-color: #f4eb49;
  -webkit-box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
  position: absolute;
  margin-left: -183px;
  left: 50%;
  height: 48px;
  min-width: 345px;
}

.pops-over p {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 0;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0;
  line-height: 16px;
}

.pops-over p:before {
  content: '';
  width: 20px;
  height: 20px;
  background-position: -360px 30px;
  position: absolute;
  top: -2px;
  left: 0;
}

/* custom country dropdown */

.country {
  position: relative;
  margin: 0 auto;
  width: 73px;
  /* display: inline-block; */
}

.country ul {
  padding-left: 0;
  margin-bottom: 0;
}

.country .select {
  position: relative;
  padding: 0;
  height: 18px;
  line-height: 18px;
  border: none;
  background: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.country .select .flagstrap-icon {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  margin-right: 10px;
  width: 34px;
  height: 18px;
  background-repeat: no-repeat;
  background: url(img/sprite1.png);
  background-size: 600px;
  background-position: -15px 57px;
}

#country-drop .flagstrap-icon {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  margin-right: 10px;
  width: 34px;
  height: 18px;
  background-repeat: no-repeat;
  background: url(img/sprite1.png);
  background-size: 600px;
  background-position: -15px 57px;
}

.country .select .flagstrap-icon.flagstrap-ad {
  background-position: -15px 57px;
}

.country .select .flagstrap-icon.flagstrap-ae {
  background-position: -15px 57px;
}

.country .select .flagstrap-icon.flagstrap-af {
  background-position: -15px 57px;
}

.country .dropdown .flagstrap-icon {
  vertical-align: middle;
}

.country .dropdown li {
  padding: 6px 15px;
  line-height: 34px;
  font-size: 13px;
  font-weight: 400;
  color: #828282;
  cursor: pointer;
  border-bottom: 1px solid #cbd1d5;
}

.country .dropdown li:last-child {
  border-bottom: none;
}

.country .dropdown li:first-child {
  margin-top: 0px;
}

.country .dropdown li:last-child {
  margin-bottom: 0px;
}

.country .dropdown li:hover {
  background: rgba(196, 196, 196, 0.4);
  color: #4a4a4a;
  font-weight: 500;
}

.country .dropdown li.open {
  display: block;
}

.country .dropdown {
  display: none;
  position: absolute;
  top: 39px;
  left: auto;
  width: 100%;
  right: 0;
  background: #fff;
  z-index: 1;
  min-width: 177px;
  border: none;
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

.country .dropdown:before {
  content: '';
  width: 26px;
  height: 15px;
  background: url(img/top-arrow.png);
  position: absolute;
  right: 18px;
  top: -15px;
  margin-left: -7px;
}

.country ul li, .country li {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-align-items: center;
  color: #4c4c4c;
}

#country {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  position: relative;
}

#country:before {
  content: '';
  width: 12px;
  height: 12px;
  background: url(img/sprite.png);
  background-size: 600px;
  background-position: -78px 570px;
  position: absolute;
  top: 5px;
  right: -3px;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}

#country.open:before {
  background-position: -98px 571px;
}

.country-login:before {
  background-position: -78px 528px !important;
}

.country-login.open:before {
  background-position: -78px 538px !important;
}

/* end of custom contry dropdown */

/* status select box */

.status-select .select-selected {
  border: none;
  background: transparent;
  font-size: 14px;
  color: #41ba1a;
  height: 60px;
  position: relative;
  top: -5px;
  line-height: 44px;
}

.status-select .custom-select {
  background: transparent;
}

.status-select .select-selected:after {
  position: absolute;
  content: '';
  top: 26px;
  right: calc(50% - 42px);
  width: 11px;
  height: 8px;
  background: url(img/sprite.png);
  background-size: 600px;
  background-position: -77px 570px;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.bug-ticket-table .status-select .select-selected:after {
  /* this might need to remive */
  right: calc(50% - 58px);
}

.status-select .select-selected.select-arrow-active:after {
  position: absolute;
  content: "";
  top: 26px;
  right: calc(50% - 42px);
  width: 12px;
  height: 8px;
  background: url(img/sprite.png);
  background-size: 600px;
  background-position: -97px 570px;
}

.status-select .select-items .selecthover {
  height: 60px;
  line-height: 42px;
}

.status-select .select-items .selecthover.team-status-select {
  height: 42px;
  line-height: normal;
  display: block;
  padding-left: 42px;
}

.select-items span.team-status-select.sprite-icon-before:before {
  left: 9px;
  top: 9px;
}

.select-items .selecthover.team-status-select {
  padding: 10px 16px;
}

.status-select .select-items {
  top: calc(100% + 6px);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.status-select .select-selected.select-arrow-active {
  color: #41ba1a;
  background-color: transparent;
  border: 1px solid #cbd1d5;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.status-select .select-items .selecthover:hover {
  background: #f2f2f2;
  color: #b71b1b;
}

/* end of status select box */

/* status report select box */

.custom-select.status-report-select {
  width: 100%;
  text-align: left;
  min-width: 138px;
}

/* end of status report select box */

/* card css */

.card {
  border: 1px solid #c4c4c4;
  /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
  background-color: #ffffff;
}

.card .card-header p {
  margin: 0;
}

.card .card-header {
  border-radius: 4px 4px 0 0;
  background-color: #b71b1b;
  /* box-shadow: 0 0 7px 3px rgba(227,57,69,0.27); */
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding-left: 30px;
  position: relative;
}

#accordion .card .card-header a {
  color: #4c4c4c;
  display: block;
  width: calc(100% + 30px);
  height: 60px;
  line-height: 60px;
  margin-left: -30px;
  padding-left: 49px;
  font-size: 14px;
}

#accordion .card .card-header {
  padding-right: 0;
  border: none;
}

.card .card-body {
  padding: 30px 30px 20px;
}

.accordian2 .card .card-body {
  padding: 15px 10px 0;
}

#accordion .card {
  margin-bottom: 15px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  overflow: hidden;
}

#accordion .card .card-header a.card-link.collapsed {
  background: #fbfbfb;
  color: #4c4c4c;
}

#accordion .card .card-header a.card-link {
  background: #b71b1b;
  color: #fff;
}

a.collapsed.card-link.sprite-icon-before:before {
  content: '';
  width: 10px;
  height: 14px;
  position: absolute;
  background-position: -77px -9px;
  top: 23px;
  left: 30px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

a.card-link.sprite-icon-before:before {
  content: '';
  width: 14px;
  height: 14px;
  position: absolute;
  background-position: -94px -9px;
  top: 23px;
  left: 30px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

/* accordian 2 css */

/* #accordion.accordion2 .card .card-header a.card-link {
    background: #fff;
    color: #B71B1B;
    border-bottom: 1px dashed #979797;
} */

#accordion.accordian2 .card {
  border: none;
}

#accordion.accordian2 .card .card-header+div {
  border: 2px solid #b71b1b;
  border-top: 0px;
  position: relative;
}

#accordion.accordian2 .card>.card-header>a.card-link {
  background: #fff;
  color: #b71b1b;
  border: 2px solid #b71b1b;
  border-bottom: none;
}

#accordion.accordian2 .card>.card-header>a.card-link.collapsed {
  border: 1px solid #4c4c4c;
  border-radius: 4px;
  color: #4c4c4c;
}

#accordion.accordian2 .card .card-header+div:before {
  content: '';
  width: calc(100% - 16px);
  height: 1px;
  border-bottom: 1px dashed #979797;
  position: absolute;
  left: 8px;
}

.accordian2 .card .card-header {
  height: 48px;
}

#accordion.accordian2 .card .card-header a {
  height: 48px;
  line-height: 48px;
}

.accordian2 a.collapsed.card-link.sprite-icon-before:before {
  top: 16px;
}

.accordian2 a.collapsed.card-link.sprite-icon-before:before {
  content: '';
  width: 16px;
  height: 14px;
  position: absolute;
  background-position: -98px -88px;
  top: 16px;
  left: 20px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.accordian2 a.card-link.sprite-icon-before:before {
  content: '';
  width: 14px;
  height: 14px;
  position: absolute;
  background-position: -78px -84px;
  top: 20px;
  left: 20px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

/* video panel css */

.video-section:hover {
  -webkit-box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.13);
          box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.13);
}

.video-section {
  border: 1px solid #cacaca;
}

.video-section .video-play-area {
  min-height: 154px;
}

.video-section .video-play-area iframe {
  max-width: 100%;
}

.video-section .video-description {
  height: 56px;
  padding: 20px 12px 8px;
  line-height: 28px;
  font-size: 16px;
  color: #4c4c4c;
  font-weight: 500;
  overflow: auto;
}

.custom-input-file.manage-ht label {
  min-height: 204px;
}

/* end of video panel css */

/* card slider img */

.slick-slide img {
  width: 100%;
}

.slick-slide {
  margin: 0 15px !important;
  overflow: hidden;
}

/* the parent */

.slick-list {
  margin: 0 -15px !important;
}

.slick-slider button {
  text-indent: -99999px;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  z-index: 1;
  outline: none;
}

.slick-slider button.slick-prev {
  top: 50%;
  position: absolute;
  margin-top: -10px;
  left: -6px;
  background: url(img/sprite.png);
  background-size: 600px;
  background-position: -59px -454px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.slick-slider button.slick-next {
  top: 50%;
  position: absolute;
  margin-top: -10px;
  right: -6px;
  background: url(img/sprite.png);
  background-size: 600px;
  background-position: -59px -454px;
}

/* end of card slider img */

/* custom simple drop down */

.custom-drop-option button.dropdown-toggle, .custom-drop-option button.dropdown-toggle:hover, .custom-drop-option button.dropdown-toggle:focus {
  border: 1px solid #c4c4c4;
  /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
  border-radius: 4px;
  padding: 0 33px 0 16px;
  width: auto;
  color: #4c4c4c;
  font-weight: 500;
  height: 40px;
  line-height: 16px;
  background: #fff;
  outline: none;
}

.custom-drop-option .btn-primary:not(:disabled):not(.disabled).active, .custom-drop-option .btn-primary:not(:disabled):not(.disabled):active, .custom-drop-option.show>.btn-primary.dropdown-toggle {
  color: #4c4c4c;
  background: #fff;
  /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
  border: 1px solid #c4c4c4;
}

.custom-drop-option .dropdown-toggle::after {
  display: none;
}

.custom-drop-option {
  position: relative;
  display: inline-block;
}

/* .custom-drop-option button.dropdown-toggle{
    width: 140px;
} */

.custom-drop-option:before {
  content: '';
  width: 12px;
  height: 12px;
  background: url(img/sprite.png);
  background-size: 600px;
  background-position: -78px 570px;
  position: absolute;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  right: 16px;
  top: 15px;
  pointer-events: none;
}

.custom-drop-option.show:before {
  background-position: -98px 571px;
}

.custom-drop-option .dropdown-menu.show {
  display: block;
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 0px;
  margin-top: 5px;
  /* min-width: 140px; */
}

.services-due-heading {
  font-size: 13px;
  line-height: 25px;
  color: #4F4F51;
  font-weight: 450;
  margin-bottom: 0;
}

.service-due-headings-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.service-due-grid-wrapper {
  margin: 15px 0;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 20px;
}

.service-due-grid-wrapper > div {
  padding: 15px;
  background: white;
  text-align: center;
  border: 1px solid #cbd1d5;
  color: #4F4F51;
  font-size: 14px;
}

.service-due-grid-wrapper-on-modal > div{
  background: rgba(47, 50, 63, 0.2);
}

.service-due-grid-wrapper-on-modal {
  grid-template-columns: auto auto;
}

.count-service-due {
  cursor: pointer;
}

.due-for-count-digit {
  font-weight: 500;
}

.custom-drop-option .dropdown-menu.show li {
  text-align: center;
  border-bottom: 1px solid #efefef;
}

.custom-drop-option .dropdown-menu.show li:hover {
  background: rgba(196, 196, 196, 0.4);
}

.custom-drop-option .dropdown-menu.show li:hover a {
  /* font-size: 16px; */
  font-weight: 500;
}

.custom-drop-option .dropdown-menu.show li a {
  color: #828282;
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  display: block;
  padding: 9px 8px;
}

/* chip css */

.custom-chip {
  background: #dddddd;
  -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  height: 40px;
  color: #4c4c4c;
  font-size: 14px;
  line-height: 16px;
  min-width: 92px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
  display: -webkit-flex;
  -webkit-align-items: center;
  padding: 0 12px;
  cursor: pointer;
}

.custom-chip span {
  background-position: -71px -132px;
  display: inline-block;
  width: 18px;
  height: 20px;
  cursor: pointer;
}

/* end of chip css */

/* custom checkbox */

label.cust-checkbox.with-bg {
  width: 90px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #c4c4c4;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  padding-left: 40px;
  font-size: 18px;
  color: #4c4c4c;
}

.cust-checkbox.with-bg input:checked~.checkmark {
  top: 12px;
  left: 12px;
  background-position: -170px -572px;
}

.cust-checkbox.with-bg.bg-filled {
  background-color: #b71b1b;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #b71b1b;
}

.cust-checkbox.with-bg .checkmark {
  top: 12px;
  left: 12px;
}

.cust-checkbox.white-check {
  color: #ffffff;
}

.cust-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 15px;
  color: #828282;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
  /*  Removing bold from labels.... */
  font-weight: normal;
}

.custom-select2 .cust-checkbox {
  display: block;
}

.custom-select2 .cust-checkbox {
  display: block;
}

/* Hide the browser's default checkbox */

.cust-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.cust-checkbox .checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: url(img/sprite.png) no-repeat;
  background-size: 600px;
  background-position: -76px -107px;
}

.cust-checkbox.white-check .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: url(img/sprite.png) no-repeat;
  background-size: 600px;
  background-position: -140px -573px;
}

.cust-checkbox.white-check input:checked~.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: url(img/sprite.png) no-repeat;
  background-size: 600px;
  background-position: -169px -574px;
}

/* When the checkbox is checked, add a blue background */

.cust-checkbox input:checked~.checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: url(img/sprite.png) no-repeat;
  background-size: 600px;
  background-position: -105px -108px;
}

/* Create the checkmark/indicator (hidden when not checked) */

.cust-checkbox .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.cust-checkbox input:checked~.checkmark:after {
  display: block;
}

/* end of custom checkbox */

/* custom radio button */

.cust-radio {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
  cursor: pointer;
  color: #828282;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.cust-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

.cust-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #cacaca;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */

.cust-radio input:checked~.checkmark {
  background-color: #fff;
  border: 2px solid #b71b1b;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.cust-radio .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.cust-radio input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.cust-radio .checkmark:after {
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #b71b1b;
}

/* end of custom radio */

/* filter dropdown css */

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.filter-dropdown .dropdown-menu {
  min-width: 270px;
  padding: 0;
  border: none;
  position: relative;
  left: auto !important;
  right: 0;
  -webkit-transform: none !important;
          transform: none !important;
}

.filter-dropdown>.dropdown-menu {
  margin-top: 60px;
  -webkit-box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
}

.filter-dropdown>.dropdown-menu:before {
  content: '';
  width: 32px;
  height: 13px;
  background: url(img/filter-arrow.png);
  position: absolute;
  top: -12px;
  right: 16px;
}

.dropdown.filter-dropdown {
  display: inline-block;
}

.filter-dropdown ul.dropdown-menu.show>li:last-child {
  border-bottom: none;
}

.filter-dropdown ul.dropdown-menu.show>li:last-child ul {
  border-top: 1px solid #979797;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.filter-dropdown .apply-filter {
  min-height: 48px;
  background: #b71b1b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-flex;
  -webkit-justify-content: space-between;
  padding: 15px 12px 15px 15px;
}

.filter-dropdown .apply-filter span {
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.filter-dropdown .apply-filter span.apply {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.filter-dropdown .cust-checkbox .checkmark {
  top: 15px;
  left: 14px;
}

.filter-dropdown .cust-checkbox input:checked~.checkmark {
  top: 15px;
  left: 14px;
}
.mr-left-9 {
  margin-left: 9%;
}

button.dropdown-toggle, button.dropdown-toggle:hover {
  height: 40px;
  width: 84px;
  min-width: 84px;
  border: 1px solid #b71b1b;
  border-radius: 4px;
  background-color: #ffffff;
  -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
  color: #b71b1b;
  font-size: 14px;
  line-height: 15px;
  padding-left: 28px;
  padding-top: 9px;
}

.filter-dropdown .dropdown-submenu .dropdown-menu {
  /* position: relative; */
  top: 0px;
  left: 0;
  border: none;
  margin-top: 2px;
  /* float: none; */
}

.filter-dropdown .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0;
  vertical-align: 0;
  content: '';
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  width: 17px;
  height: 18px;
  background: url(img/sprite.png);
  background-size: 600px;
  background-position: -64px 208px;
  position: absolute;
  left: 11px;
  top: 11px;
}

.filter-dropdown ul.dropdown-menu.show li {
  /* height: 49px; */
  border-bottom: 1px solid #979797;
  padding: 0px;
  font-size: 14px;
}

.filter-dropdown ul.dropdown-menu.show li a {
  color: #4c4c4c;
  text-decoration: none;
  display: inline-block;
  height: 48px;
  padding-left: 64px;
  padding-top: 16px;
  width: 100%;
  position: relative;
}

.filter-dropdown ul.dropdown-menu.show li label {
  display: block;
  height: 48px;
  padding-top: 15px;
  padding-left: 64px;
}

.filter-dropdown .cust-checkbox.checked {
  background: #f2f2f2;
  color: #4c4c4c;
}

.filter-dropdown .dropdown-submenu .dropdown-head {
  position: relative;
}

.filter-dropdown .dropdown-submenu .dropdown-head:before {
  content: '';
  background: url(img/sprite.png);
  background-size: 600px;
  background-position: -99px 510px;
  position: absolute;
  width: 15px;
  height: 10px;
  top: 20px;
  left: 20px;
}

.filter-dropdown .dropdown-submenu .dropdown-head.menu-drop:before {
  background-position: -77px 510px;
}

/* End of filter dropdown css */

/* custom tabel design css */

.global-table {
  margin-top: 20px;
}

.global-table .table thead tr th, .global-table .table thead tr th:first-child, .global-table .table thead tr th:nth-child(2), .global-table .table thead tr th:nth-child(3), .global-table .table thead tr th:nth-child(4), .global-table .table thead tr th:nth-child(5) {
  width: 20%;
}

.global-table .table tbody tr td table tbody tr td {
  width: 20%;
}

.global-table table table tbody tr td {
  height: 60px;
  vertical-align: middle;
  font-size: 14px;
  color: #4c4c4c;
  font-weight: normal;
}

.global-table table table tbody tr td:first-child {
  /* font-weight: 500; */
  padding-left: 30px;
}

.global-table.analytic-pop-table table table tbody tr td:first-child {
  font-weight: 400;
}

/* end of custom tabel design css */

/* input form css */

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(76, 76, 76, .6);
  font-size: 16px;
  letter-spacing: 0.75px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(76, 76, 76, .6);
  ;
  font-size: 16px;
  letter-spacing: 0.75px;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(76, 76, 76, .6);
  ;
  font-size: 16px;
  letter-spacing: 0.75px;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(76, 76, 76, .6);
  ;
  font-size: 16px;
  letter-spacing: 0.75px;
}

input.form-control {
  height: 48px;
  border: 1px solid #CBD1D5;
  border-radius: 4px;
  background-color: #F2F2F2;
  padding-left: 15px;
  padding-right: 15px;
  color: #4C4C4C;
  font-size: 16px;
  margin-bottom: 8px;
}

input[type="time"].input-type-time-clock-icon::-webkit-calendar-picker-indicator{
  position: absolute;
  left: 15%;
}

textarea.form-control {
  border: 1px solid #CBD1D5;
  border-radius: 4px;
  background-color: #F2F2F2;
  padding-left: 15px;
  padding-right: 15px;
  color: #4C4C4C;
  font-size: 16px;
  margin-bottom: 8px;
  resize: none;
}

input.form-control:focus, input.form-control:active, textarea.form-control:focus, textarea.form-control:active {
  -webkit-box-shadow: 0 0 7px 0 rgba(227, 57, 69, 0.36);
          box-shadow: 0 0 7px 0 rgba(227, 57, 69, 0.36);
  border: 1px solid #E33945;
}

input.form-control+label, textarea.form-control+label {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  font-weight: normal;
}

input.form-control:focus+label, textarea.form-control:focus+label {
  color: #E33945;
}

.dis-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.f-dir-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

input.input-state-tax {
  border: 1px solid #c4c4c4;
  /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
  height: 48px;
  border-radius: 4px;
  outline: none;
  text-align: center;
  /* width: 72px; */
  width: 48px;
}

/* end of input box css */

/* select box css */

.custom-select, .custom-select2 {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 48px;
  padding: 0px;
  /* font-size: 1rem; */
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  /* background: #f2f2f2; */
  background-color: #f2f2f2;
  border: none;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select select, .custom-select2 select {
  display: none;
  /*hide original SELECT element:*/
}

.select-selected {
  background-color: #f2f2f2;
  border-radius: 4px;
  line-height: 32px;
}

/*style the arrow inside the select element:*/

.select-selected:after {
  position: absolute;
  content: '';
  top: 19px;
  right: 18px;
  width: 17px;
  height: 13px;
  background: url(img/sprite.png);
  background-size: 600px;
  background-position: -77px 556px;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.select-selected.select-arrow-active:after {
  position: absolute;
  content: '';
  top: 20px;
  right: 18px;
  width: 17px;
  height: 13px;
  background: url(img/sprite.png);
  background-size: 600px;
  background-position: -97px 556px;
}

.select-selected.select-arrow-active {
  color: rgba(76, 76, 76, 0.3);
  background-color: #ffffff;
  border: 1px solid #e33945;
  -webkit-box-shadow: 0 0 7px 0 rgba(227, 57, 69, 0.36);
          box-shadow: 0 0 7px 0 rgba(227, 57, 69, 0.36);
}

/*point the arrow upwards when the select box is open (active):*/

/* .select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
  } */

/*style the items (options), including the selected item:*/

.select-items .selecthover {
  padding: 8px 16px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 36px;
  border-bottom: 1px solid #CBD1D5;
  height: auto;
}

.select-items div:last-child {
  border-bottom: none;
}

.select-selected {
  color: rgba(76, 76, 76, .6);
  padding: 8px 16px;
  border: 1px solid #CBD1D5;
  /* border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;  */
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 48px;
  overflow: hidden;
}

.year-selection .select-selected {
  color: #4c4c4c;
  font-weight: 500;
  line-height: 34px;
}

/*style items (options):*/

.select-items {
  position: absolute;
  background-color: #ffffff;
  top: calc(100% + 9px);
  left: 0;
  right: 0;
  max-height: 291px;
  z-index: 99;
  overflow: auto;
  border-radius: 4px;
  border: 1px solid #CBD1D5;
}

/*hide the items when the select box is closed:*/

.select-hide {
  display: none;
}

.select-items .selecthover:hover {
  background: rgba(173, 166, 166, 0.27);
  font-weight: 500;
  color: #4c4c4c;
}

.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.label-color+label {
  color: #E33945;
}

/* end of select box css */

/*style the items (options), including the selected item:*/

.select-items .selecthover {
  /* color: #828282; */
  padding: 8px 16px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 36px;
  border-bottom: 1px solid #cbd1d5;
  height: auto;
}

.select-items div:last-child {
  border-bottom: none;
}

.select-selected {
  color: rgba(76, 76, 76, 0.6);
  padding: 8px 16px;
  border: 1px solid #cbd1d5;
  /* border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;  */
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 48px;
}

.year-selection .select-selected {
  color: #4c4c4c;
  font-weight: 500;
  line-height: 34px;
}

/*style items (options):*/

.select-items {
  position: absolute;
  background-color: #ffffff;
  top: calc(100% + 9px);
  left: 0;
  right: 0;
  max-height: 291px;
  z-index: 99;
  overflow: auto;
  border-radius: 4px;
  border: 1px solid #cbd1d5;
}

/*hide the items when the select box is closed:*/

.select-hide {
  display: none;
}

.select-items .selecthover:hover {
  background: #f2f2f2;
  font-weight: 500;
  color: #4c4c4c;
}

.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.label-color+label {
  color: #e33945;
}

/* end of select box css */

/* select 2 css override */

.select2-container--open .select2-dropdown--below {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.select2-container--default .select2-results__options .select2-results__option {
  color: #828282;
  padding: 8px 16px;
  cursor: pointer;
  height: 36px;
  border-bottom: 1px solid #cbd1d5;
}

.select2-search--dropdown {
  border-bottom: 1px solid #cbd1d5;
  position: relative;
}

.select2-search--dropdown:before {
  content: '';
  width: 18px;
  height: 18px;
  position: absolute;
  top: 12px;
  right: 36px;
  background: url(img/sprite.png) no-repeat;
  background-size: 600px;
  background-position: -98px -137px;
}

.select2-container--default .select2-results__options .select2-results__option:last-child {
  border-bottom: none;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: rgba(255, 183, 188, 0.2) !important;
  font-weight: 500;
  color: #4c4c4c !important;
}

.select2-container--default .select2-results__options .select2-results__option[aria-selected='true'] {
  background: transparent;
}

.select2-container--default .select2-results>.select2-results__options {
  /* max-height: 200px !important; */
}

.select2-container {
  width: 100% !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 48px !important;
  outline: none;
}

.select2-container .select2-selection--single {
  height: 48px !important;
  outline: none;
}

.select2-container--default.select2-container--open .select2-selection--single {
  color: rgba(76, 76, 76, 0.3);
  background-color: #ffffff;
  border: 1px solid #e33945;
  -webkit-box-shadow: 0 0 7px 0 rgba(227, 57, 69, 0.36);
          box-shadow: 0 0 7px 0 rgba(227, 57, 69, 0.36);
  border-radius: 4px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: transparent !important;
  border-style: solid;
  border-width: 0 !important;
  left: auto !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
  position: absolute;
  top: 18px !important;
  right: 18px !important;
  width: 17px !important;
  height: 13px !important;
  background: url(img/sprite.png);
  background-size: 600px;
  background-position: -77px 556px;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent !important;
  border-style: solid;
  border-width: 0 !important;
  left: auto !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
  position: absolute;
  top: 18px !important;
  right: 18px !important;
  width: 17px !important;
  height: 13px !important;
  background: url(img/sprite.png);
  background-size: 600px;
  background-position: -97px 556px;
}

.select2-container--open .select2-dropdown {
  top: 12px;
  border: 1px solid #c4c4c4 !important;
  /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #eaeaea !important;
  border-radius: 4px;
  height: 30px;
  width: calc(100% - 22px);
  outline: none;
  padding: 0 28px 0 10px;
}

/* search placeholder color */

.search-container ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cacaca;
  font-size: 14px;
}

.search-container ::-moz-placeholder {
  /* Firefox 19+ */
  color: #cacaca;
  font-size: 14px;
}

.search-container :-ms-input-placeholder {
  /* IE 10+ */
  color: #cacaca;
  font-size: 14px;
}

.search-container :-moz-placeholder {
  /* Firefox 18- */
  color: #cacaca;
  font-size: 14px;
}

.label-color label {
  color: #e33945;
}

.label-color label span {
  color: #828282;
}

/* end of search placeholder color */

/* Header css */

nav+.container-fluid {
  /* margin-top: 64px; */
}

nav.navbar {
  height: 64px;
  background: #fff;
  border-bottom: 2px solid #B71B1B;
  padding: 0 60px 0 0;
}

nav.navbar-login {
  height: 64px;
  background: #232323;
  border-bottom: none;
  padding: 0 60px 0 0;
}

.breadcrumes {
  padding-left: 34px;
}

.breadcrumes ul {
  margin: 0;
  padding: 0;
}

.breadcrumes ul li {
  display: inline-block;
}

.breadcrumes ul li.active a {
  font-weight: normal;
}

.breadcrumes ul li.active {
  position: relative;
  padding-right: 16px;
}

.breadcrumes ul li.active:before {
  content: ">";
  width: 12px;
  height: 16px;
  position: absolute;
  right: 0;
  color: #535252;
}

.breadcrumes ul li a {
  color: #535252;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-decoration: none;
}

.right-nav-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: space-between;
  -webkit-align-items: center;
}

.right-nav-menu.head-width {
  width: calc(100% - 243px);
}

nav.navbar .header-logo {
  min-width: 243px;
  width: 243px;
  background: #292C36;
  height: 62px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
}

nav.navbar .header-logo .navbar-brand {
  margin: 0;
}

.profileselector {
  border: 1px solid #D2D2D2;
  border-radius: 23.5px;
  padding: 5px 29px 5px 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  position: relative;
  cursor: pointer;
  max-width: 95%;
}

.profileselector .user-image {
  min-width: 32px;
  min-height: 35px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  margin-right: 8px;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-align-self: center;
}

.profileselector .user-image img {
  max-width: 100%;
}

.profileselector .user-name {
  font-size: 14px;
  color: #4C4C4C;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-align-self: center;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 98px;
  white-space: nowrap;
}

.user-dropdown .profileselector .user-arrow {
  width: 12px;
  height: 13px;
  position: absolute;
  right: 0;
  background-position: -77px -27px;
  top: 16px;
  right: 12px;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.user-dropdown.show .profileselector .user-arrow {
  background-position: -97px -27px;
}

.user-dropdown {
  position: relative;
  min-width: 185px;
}

.user-dropdown .dropdown-menu {
  min-width: 177px;
  border: none;
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
  margin-top: 17px;
  padding: 0;
}

.user-dropdown .dropdown-menu ul {
  padding: 0;
  margin: 0;
}

/* .user-dropdown .dropdown-menu.show{
    position: relative;
} */

.speaker-wrap.user-dropdown {
  min-width: auto;
}

.speaker-wrap.user-dropdown .dropdown-menu {
  left: auto;
  right: 11px;
  min-width: 147px;
  max-width: 147px;
}

.speaker-wrap.user-dropdown .dropdown-menu ul {
  padding-left: 0;
  margin-bottom: 0;
}

.speaker-wrap.user-dropdown .dropdown-menu ul li a {
  padding: 0 15px 0 36px;
  min-height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-align-items: center;
}

.speaker-wrap.user-dropdown .dropdown-menu ul li {
  border-bottom: 1px solid #efefef;
}

#notification-dropdown {
  left: -586%;
  top: 115%;
  width: 360px;
  height: 540px;
  overflow: auto;
}

#notification-mobile-dropdown {
  left: -855%;
  top: -523%;
  width: 332px;
  height: 517px;
  overflow: auto;
}

.speaker-wrap.user-dropdown .dropdown-menu ul li:last-child {
  padding: 12px;
}

.user-dropdown .dropdown-menu.show:before, .circleBorderOfOffline .dropdown-menu.show:before {
  content: '';
  width: 26px;
  height: 15px;
  background: url(img/top-arrow.png);
  position: absolute;
  right: 18px;
  top: -15px;
  margin-left: -7px;
}

.user-dropdown .dropdown-menu a {
  color: #828282;
  font-size: 14px;
  padding: 12px 15px;
  border-radius: 4px;
  position: relative;
}

.dropdown-menu a.Logout {
  text-align: left;
  padding-left: 38px;
}

.user-dropdown .dropdown-menu a.toggle-drop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
}

.user-dropdown .dropdown-menu a.toggle-drop ul {
  margin-top: 2px;
  padding-right: 12px;
}

.user-dropdown .dropdown-menu>ul>li {
  border-bottom: 1px solid #efefef;
}

.user-dropdown .dropdown-menu>ul>li:last-child {
  border-bottom: none;
}

.user-dropdown .dropdown-menu a:active, .user-dropdown .dropdown-menu a:focus, .user-dropdown .dropdown-menu a:hover {
  background: transparent;
  border-radius: 4px;
  border: none;
  outline: none;
}

.dropdown-menu a.Logout span {
  width: 20px;
  height: 20px;
  position: unset;
  margin-right: 5px;
  background-position: -9px -524px;
}

#logout-icon-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dropdown-menu a.mick span {
  width: 18px;
  height: 20px;
  position: absolute;
  left: 15px;
  background-position: -208px -516px;
  display: inline-block;
  top: 6px;
}

.dropdown-menu a.mute span {
  width: 18px;
  height: 20px;
  position: absolute;
  left: 15px;
  background-position: -232px -516px;
  display: inline-block;
  top: 6px;
}

.dropdown-menu a.mute-all span {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 51px;
  background-position: -9px -524px;
  display: inline-block;
}

.cart-item.sprite-icon {
  width: 47px;
  height: 46px;
  border-radius: 50%;
  background-position: -151px -459px;
  cursor: pointer;
  position: relative;
}

.cart-item.chat-icon-v2 {
  width: 47px;
  height: 46px;
  border-radius: 50%;
  background: #B71B1B;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  cursor: pointer;
  position: relative;
}

span.badge {
  font-size: 8px;
  color: #fff;
  background: #292c36;
  width: 14px;
  height: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 10px;
  left: 8px;
}

.speaker-icon.sprite-icon {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  background-position: -151px -403px;
  cursor: pointer;
  margin-right: 20px;
}

.gray-speaker.speaker-icon.sprite-icon {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  background-position: -208px -403px;
  cursor: pointer;
  margin-right: 20px;
}

.speaker-mute-icon.sprite-icon {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  background-position: -151px -516px;
  cursor: pointer;
  margin-right: 20px;
}

/* left side pannel */

.sidebar {
  width: 243px;
  background: #292c36;
  height: calc(100vh - 64px);
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 64px;
  z-index: 9;
}

.collapse-lists {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed, #sidebar .collapse-lists a.list-group-item {
  border: none;
  background: transparent;
  border-radius: 0;
  font-size: 14px;
  line-height: 16px;
  color: rgba(225, 225, 225, 0.6);
  text-decoration: none;
  padding: 16px 16px 16px 33px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover, #sidebar .collapse-lists a.list-group-item:hover {
  background: #2f323f;
  font-weight: 500;
  color: #fff;
}

#sidebar .collapse-lists a.list-group-item.active-inner-list {
  background: #b71b1b;
  color: #fff;
  font-weight: 700;
  position: relative;
}

#sidebar .collapse-lists a.list-group-item.active-inner-list:before {
  content: '';
  width: 3px;
  height: 100%;
  background: #b71b1b;
  position: absolute;
  left: -9px;
  top: 0;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.de-active {
  background: transparent;
}

#sidebar .collapse-lists>a.list-group-item.d-inline-block.collapsed, #sidebar .collapse-lists>a.list-group-item {
  font-size: 16px;
  line-height: 19px;
  color: rgba(225, 225, 225, 0.6);
  background: transparent;
  padding: 21px 42px 20px 84px;
  border: none;
  border-bottom: 1px solid rgba(169, 170, 174, 0.1);
  border-radius: 0;
  text-decoration: none;
  font-weight: normal;
}

/* #sidebar .collapse-lists > a.list-group-item.d-inline-block.collapsed, #sidebar .collapse-lists > a.list-group-item{
    border-bottom: 1px solid rgba(169,170,174,0.1);
} */

#sidebar .collapse-lists a.list-group-item.d-inline-block {
  background: #B71B1B;
  color: #fff;
  font-weight: 500;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover {
  background: #2F323F;
  color: #fff;
  font-weight: 500;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:last-child {
  border-bottom: none;
}

.sprite-icon {
  background: url(img/sprite.png);
  background-size: 600px;
}

.sprite-icon1 {
  background-image: url(img/sprite1.png);
  background-size: 600px;
}

.sprite-icon-before, .sprite-icon-before1 {
  position: relative;
}

.update-icon .sprite-icon {
  background-position: -222px 300px;
}

.sprite-icon-before:before {
  content: "";
  background: url(img/sprite.png);
  background-size: 600px;
}

.sprite-icon-before1:before {
  content: "";
  background: url(img/sprite1.png);
  background-size: 600px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.customer {
  background-position: -8px -9px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.customer, #sidebar .collapse-lists a.list-group-item.d-inline-block i.customer {
  background-position: -42px -9px;
  width: 28px;
  height: 24px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.dashboard {
  background-position: -136px -8px;
  width: 28px;
  height: 24px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.dashboard {
  background-position: -170px -8px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.team {
  background-position: -136px -73px;
  width: 28px;
  height: 28px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.dashboard {
  background-position: -170px -8px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.team {
  background-position: -136px -73px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.team {
  background-position: -170px -73px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.joblink {
  background-position: -136px -100px;
  width: 28px;
  height: 28px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.team {
  background-position: -170px -73px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.joblink {
  background-position: -136px -100px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.joblink {
  background-position: -170px -100px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.report-list {
  background-position: -135px -127px;
  width: 28px;
  height: 28px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.joblink {
  background-position: -170px -100px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.report-list {
  background-position: -135px -127px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.building {
  background-position: -59px -416px;
  width: 28px;
  height: 28px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.building {
  background-position: -59px -416px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block:hover i.building {
  background-position: -93px -416px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.report-list {
  background-position: -169px -127px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.task {
  background-position: -135px -157px;
  width: 28px;
  height: 28px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px
}

#sidebar .collapse-lists a.list-group-item.d-inline-block:hover i.building {
  background-position: -93px -416px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.report-list {
  background-position: -169px -127px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.task {
  background-position: -135px -157px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.task {
  background-position: -169px -157px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.service-rqst {
  background-position: -135px -183px;
  width: 28px;
  height: 28px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.task {
  background-position: -169px -157px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.service-rqst {
  background-position: -135px -183px;
  width: 28px;
  height: 28px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 24px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.inventory {
  /* background-position: -136px -216px; */
  width: 28px;
  height: 28px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
  background: url(img/icons-warehouse.png);
}

#sidebar .collapse-lists a.list-group-item.d-inline-block:hover i.inventory {
  /* background-position: -170px -216px; */
  background: url(img/icons-warehouse-hover.png);
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.service-rqst {
  background-position: -169px -183px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.deficiencies {
  background-position: -136px -245px;
  width: 28px;
  height: 28px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.deficiencies {
  background-position: -170px -245px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.analytics {
  background-position: -8px -42px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.analytics, #sidebar .collapse-lists a.list-group-item.d-inline-block i.analytics {
  background-position: -42px -42px;
  width: 28px;
  height: 24px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.tickets {
  background-position: -8px -76px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.tickets, #sidebar .collapse-lists a.list-group-item.d-inline-block i.tickets {
  background-position: -42px -76px;
  width: 28px;
  height: 24px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.reports {
  background-position: -8px -110px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.reports, #sidebar .collapse-lists a.list-group-item.d-inline-block i.reports {
  background-position: -42px -110px;
  width: 28px;
  height: 24px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.Settings {
  /* background-position: -8px -143px; */
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.Settings, #sidebar .collapse-lists a.list-group-item.d-inline-block i.Settings {
  /* background-position: -42px -143px; */
  width: 28px;
  height: 30px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.console {
  background-position: -8px -176px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.console, #sidebar .collapse-lists a.list-group-item.d-inline-block i.console {
  background-position: -42px -176px;
  width: 28px;
  height: 24px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 27px;
}

#sidebar .collapse-lists .menu-design, #sidebar .collapse-lists .menu-design {
  position: relative;
  border-bottom: 1px solid rgba(169, 170, 174, 0.1);
  padding-left: 50px;
  margin-top: 1px;
}

#sidebar .collapse-lists .menu-design a:last-child, #sidebar .collapse-lists .menu-design a:last-child {
  margin-bottom: 19px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.inventory {
  /* background-position: -136px -216px; */
  width: 28px;
  height: 28px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block:hover i.inventory {
  /* background-position: -170px -216px; */
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.service-rqst {
  background-position: -169px -183px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.deficiencies {
  background-position: -136px -245px;
  width: 28px;
  height: 28px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.deficiencies {
  background-position: -170px -245px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.analytics {
  background-position: -8px -42px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.analytics, #sidebar .collapse-lists a.list-group-item.d-inline-block i.analytics {
  background-position: -42px -42px;
  width: 28px;
  height: 24px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.tickets {
  background-position: -8px -76px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.tickets, #sidebar .collapse-lists a.list-group-item.d-inline-block i.tickets {
  background-position: -42px -76px;
  width: 28px;
  height: 24px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.reports {
  background-position: -8px -110px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.reports, #sidebar .collapse-lists a.list-group-item.d-inline-block i.reports {
  background-position: -42px -110px;
  width: 28px;
  height: 24px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.Settings {
  /* background-position: -8px -143px; */
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.Settings, #sidebar .collapse-lists a.list-group-item.d-inline-block i.Settings {
  /* background-position: -42px -143px; */
  width: 28px;
  height: 32px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.console {
  background-position: -8px -176px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.console, #sidebar .collapse-lists a.list-group-item.d-inline-block i.console {
  background-position: -42px -176px;
  width: 28px;
  height: 24px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 27px;
}

#sidebar .collapse-lists .menu-design, #sidebar .collapse-lists .menu-design {
  position: relative;
  border-bottom: 1px solid rgba(169, 170, 174, 0.1);
  padding-left: 50px;
  margin-top: 1px;
}

#sidebar .collapse-lists .menu-design a:last-child, #sidebar .collapse-lists .menu-design a:last-child {
  margin-bottom: 19px;
}

/* #sidebar .collapse-lists .show{
    padding-bottom: 19px;
} */

#sidebar .collapse-lists .menu-design:before, #sidebar .collapse-lists .menu-design:before {
  content: '';
  position: absolute;
  height: calc(100% - 19px);
  width: 1px;
  background: rgba(225, 225, 225, 0.2);
  left: 42px;
  top: 1px;
}

/* #sidebar .collapse-lists .menu-design.show a.list-group-item{
    background: #292C36;
    font-weight: normal;
    color: #fff;
    font-weight: 700;
}
#sidebar .collapse-lists .menu-design.show a.list-group-item.collapsed{
    background: #B71B1B;
    color: #fff;
    font-weight: 700;
} */

/* end of left side pannel */

/* table css */

.global-table {
  border-radius: 4px;
  border: 1px solid #cbd1d5;
}

.global-table .table {
  margin-bottom: 0;
}

/* .global-table .table td, .global-table .table th {
    border-top: none;
} */

.admin-table.global-table>table {
  border-radius: 4px;
  overflow: hidden;
  margin: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  /* background-color: #E4E5E6; */
  background-color: #f2f2f2;
}

.table thead tr th {
  border-top: none;
  height: 60px;
  vertical-align: middle;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

.table-striped tbody tr {
  position: relative;
}

.table-striped tbody tr tbody tr:hover {
  /* box-shadow: 0 0 4px 3px rgba(0,0,0,0.13); */
  background: rgba(47, 50, 63, 0.2);
}

.table-striped tbody tr tbody tr:last-child {
  border-bottom: 1px solid #cbd1d5;
}

.table-striped tbody tr tbody tr:last-child .posi-relative .sprite-icon:after {
  top: 0;
  left: -78px;
}

.design_fix_import_device_1 {
  width: 45%;
  margin-right: 20px;
}

.design_fix_import_device_2 {
  width: 45%;
  margin-left: 20px;
}

.table-striped tbody tr .open-icons {
  display: none;
}

.table-striped tbody tr .open-icons.display-eye {
  display: block;
  right: 48px;
}

.ellipses-icon {
  display: block;
}

.table-striped tbody tr tbody tr:hover .open-icons {
  display: block;
}

.table-striped tbody tr:hover .open-icons .delete, .table-striped tbody tr:hover .open-icons .remove-offline, .table-striped tbody tr:hover .open-icons .graph, .table-striped tbody tr:hover .open-icons .eye, .table-striped tbody tr:hover .open-icons .refresh, .table-striped tbody tr:hover .open-icons .sync-offline, .table-striped tbody tr:hover .open-icons .download, .table-striped tbody tr:hover .open-icons .uplaod, .table-striped tbody tr:hover .open-icons .email, .table-striped tbody tr:hover .open-icons .user {
  position: relative;
}

.table-striped tbody tr:hover .open-icons.display-eye .eye {
  position: absolute;
}

.table-striped tbody tr:hover .open-icons .delete:hover, .table-striped tbody tr:hover .open-icons .remove-offline:hover, .table-striped tbody tr:hover .open-icons .graph:hover, .table-striped tbody tr:hover .open-icons .eye:hover {
  cursor: pointer;
}

.table-striped tbody tr:hover .open-icons .delete:hover:before, .table-striped tbody tr:hover .open-icons .remove-offline:hover:before, .table-striped tbody tr:hover .open-icons .graph:hover:before, .table-striped tbody tr:hover .open-icons .eye:hover:before, .table-striped tbody tr:hover .open-icons .refresh:hover:before, .table-striped tbody tr:hover .open-icons .sync-offline:hover:before, .table-striped tbody tr:hover .open-icons .user:hover:before, .table-striped tbody tr:hover .open-icons .email:hover:before, .table-striped tbody tr:hover .open-icons .download:hover:before, .table-striped tbody tr:hover .open-icons .edit:hover:before, .table-striped tbody tr:hover .open-icons .upload:hover:before {
  content: '';
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: -7px;
}

.table-striped tbody tr:hover .open-icons .remove-offline:hover:after {
  content: 'Remove';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .upload:hover:after {
  content: 'Upload';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .delete:hover:after {
  content: 'Delete';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .eye:hover:after {
  content: 'View';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .graph:hover:after {
  cursor: pointer;
  content: 'Graph';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.__floater__open {
  /* top: -25px !important; */
}

.mr-rt-10-imp {
  margin-right: 10px !important;
}

.mr-rt-0-imp {
  margin-right: 0px !important;
}

#mr-rt-10-imp {
  margin-right: 10px !important;
}

.react-joyride__tooltip {
  padding: 0 !important;
  height: auto !important;
}

.table-striped tbody tr:hover .open-icons .sync-offline:hover:after {
  cursor: pointer;
  content: 'Sync';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .refresh:hover:after {
  cursor: pointer;
  content: 'Refresh';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .user:hover:after {
  cursor: pointer;
  content: 'Profile';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .email:hover:after {
  cursor: pointer;
  content: 'Email';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.open-icons .upload {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-position: -81px 121px;
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: -47px;
}

.table-striped tbody tr:hover .open-icons .upload:hover:after {
  cursor: pointer;
  content: 'Upload';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .download:hover:after {
  cursor: pointer;
  content: 'Download';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .edit:hover:after {
  cursor: pointer;
  content: 'Edit';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.table-striped tbody tr tbody tr:hover .ellipses-icon {
  display: none;
}

.admin-table table table tbody tr td:last-child {
  position: relative;
}

.open-icons {
  position: absolute;
  top: 17px;
  right: 30px;
}

.open-icons .graph {
  display: inline-block;
  width: 20px;
  height: 16px;
  background-position: -18px -220px;
  position: absolute;
  cursor: pointer;
  /* left: -10px; */
}

.open-icons .eye {
  display: inline-block;
  width: 24px;
  height: 17px;
  background-position: -16px -264px;
  position: absolute;
  cursor: pointer;
  /* left: 0; */
}

.open-icons .remove-offline {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-position: -18px -310px;
  position: absolute;
  left: -10px;
  cursor: pointer;
}

.open-icons .delete {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-position: -18px -310px;
  position: absolute;
  left: -5px;
  cursor: pointer;
}

.open-icons .edit {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-position: -456px -296px;
  position: absolute;
  cursor: pointer;
  /* left: -2px; */
}

.open-icons .download {
  display: inline-block;
  width: 22px;
  height: 20px;
  background-position: -458px -203px;
  position: absolute;
  cursor: pointer;
  left: -12px
}

.open-icons .sync-offline {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-position: -457px -64px;
  position: absolute;
  cursor: pointer;
  left: -35px;
}

.open-icons .refresh {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-position: -457px -64px;
  position: absolute;
  cursor: pointer;
  left: -25px;
}

.open-icons .user {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-position: -456px -111px;
  position: absolute;
  cursor: pointer;
  left: -28px;
}

.open-icons .email {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-position: -458px -158px;
  position: absolute;
  cursor: pointer;
  left: -20px;
}

.delete-icon {
  display: inline-block;
  /* width: 20px; */
  width: 23px;
  height: 23px;
  background-position: -18px -310px;
  position: absolute;
  /* right: 34px; */
  right: 30px;
  top: 45%;
  cursor: pointer;
  background: url(fonts/delete-vr-2.svg);
  margin-top: -10px;
}

.delete-icon:hover:before {
  content: '';
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -6px;
  left: -6px;
}

.delete-icon:hover:after {
  content: 'Delete';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.edit-icon {
  display: inline-block;
  width: 22px;
  height: 24px;
  background-position: -173px -374px;
  position: absolute;
  right: 68px;
  top: 45%;
  cursor: pointer;
  background: url(fonts/edit-icon.svg);
  margin-top: -10px;
}

.edit-icon:hover:before {
  content: '';
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -6px;
  left: -6px;
}

.edit-icon:hover:after {
  content: 'Edit';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.notes-icon-sprite-icon {
  cursor: pointer;
}

.notes-header-icon-sprite-icon:hover:before {
  content: '';
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-position: 150px -296px;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -6px;
  right: 42px;
}

.notes-icon-sprite-icon:hover:after {
  content: 'Add a note about this question.';
  width: 215px;
  height: 25px;
  background: #337AB7;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 31px;
  left: -72px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
  z-index: 1;
}

.notes-header-icon-sprite-icon:hover::after {
  content: 'Add inspector notes.';
  background-color: #4c4c4c;
  left: 45px;
  top: 37px;
  width: 160px;
}

.hide-icon-sprite-icon:hover:after {
  content: 'Clicking hide will remove the question from the PDF report.';
  width: 220px;
  height: 43px;
  padding: 0 10px;
  background: #337AB7;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 31px;
  right: -83px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
  z-index: 1;
}

.qrcode-flip-icon-sprite-icon {
  cursor: pointer;
}

.qrcode-flip-icon-sprite-icon:hover:after {
  content: 'Flip';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
  z-index: 1;
}

.qrcode-icon-sprite-icon {
  cursor: pointer;
}

.qrcode-icon-sprite-icon:hover:before {
  content: '';
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -6px;
  right: 5px;
}

.qrcode-icon-sprite-icon:hover:after {
  content: 'QR code';
  width: 73px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 37px;
  right: -13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
  z-index: 1;
}

span.pdf-icon.sprite-icon {
  width: 26px;
  height: 26px;
  background-position: -457px -431px;
  position: absolute;
  top: 16px;
  right: 30px;
}

span.word-icon.sprite-icon {
  width: 26px;
  height: 26px;
  background-position: -458px -385px;
  position: absolute;
  top: 16px;
  right: 75px;
}

span.note-icon.sprite-icon {
  width: 26px;
  height: 26px;
  background-position: -457px -339px;
  position: absolute;
  top: 16px;
  right: 125px;
}

.table thead {
  background: #b71b1b;
}

.table thead tr th {
  border-bottom: none;
}

/* .admin-table table table tbody tr td:first-child {
    padding-left: 25px;
    width: 22%;
    font-weight: 500;
} */

/* .admin-table.global-table .table thead tr th:first-child {
    padding-left: 45px;
    width: 20%;
} */

.title-class {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 144px;
  display: inline-block;
}

.table .arrow span {
  position: relative;
  display: table;
}
/* .global-table.customer-table .table thead tr th:first-child */


/* .global-table.customer-table .table thead tr th table tbody tr th span {
  margin: 0 auto;
} */
.global-table.customer-table .table thead tr th table tbody tr th:first-child span {
  margin: 0 !important;
  text-align: left;
}
.table .arrow #test-span {
  margin: 0 auto;
}

.table .arrow>span:before {
  content: "";
  background: url(img/sprite.png);
  background-size: 600px;
  position: absolute;
  width: 11px;
  height: 8px;
  right: -16px;
  background-position: -76px -64px;
  top: 50%;
  margin-top: -6px;
}

.table .arrow>span:after {
  content: "";
  background: url(img/sprite.png);
  background-size: 600px;
  position: absolute;
  width: 11px;
  height: 8px;
  right: -16px;
  background-position: -76px -74px;
  top: 50%;
  margin-top: 2px;
}

.admin-table.global-table .table tbody tr td table tbody tr td {
  width: 18%;
}

.admin-table.global-table .table thead tr th {
  width: 18%;
}

.admin-table.global-table .table tbody tr td table tbody tr td:last-child {
  min-width: 104px;
}

.admin-table.global-table .table tbody tr td table tbody tr td:nth-child(5) {
  text-align: left;
  padding-left: 62px;
}

.global-table.admin-table .table thead tr th:nth-child(5) {
  padding-left: 34px;
  text-align: left;
}

/* small table css */

.global-sm-table .table thead tr th {
  height: 30px;
}

.global-table.global-sm-table table table tbody tr td {
  height: 30px;
}

.global-sm-table .delete-icon {
  top: 5px;
  background-size: 400px;
  background-position: -305px -163px;
  cursor: pointer;
}

.global-sm-table .delete-icon:hover:before {
  content: '';
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -2px;
  left: -6px;
}

.global-sm-table tbody tr tbody tr:last-child .delete-icon:hover:before {
  content: '';
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -2px;
  left: -6px;
}

.global-sm-table tbody tr tbody tr:last-child .delete-icon:hover:after {
  top: -30px;
  z-index: 9;
}

.global-sm-table .delete-icon:hover:after {
  top: 25px;
  z-index: 9;
}

/* .admin-table.global-table .table thead tr th:nth-child(2) {
    padding-left: 12px;
    width: 12%;
}
.admin-table.global-table .table thead tr th:nth-child(3) {
    padding-left: 32px;
    width: 16%;
}
.admin-table.global-table .table thead tr th:nth-child(5) {
    width: 15%;
    text-align: center;
}
.admin-table.global-table .table thead tr th:nth-child(4) {
    width: 18%;
}
.global-table.admin-table .table tbody tr td table tbody tr td:nth-child(2) {
    width: 15%;
}
.global-table.admin-table .table tbody tr td table tbody tr td:nth-child(3){
    width: 14%;
}
.global-table.admin-table .table tbody tr td table tbody tr td:nth-child(4){
    width: 18%;
    text-align: center;
}
.global-table.admin-table .table tbody tr td table tbody tr td:nth-child(5){
    width: 22%;
    padding-left: 60px;
} */

.trial, .Subscibe, .expired, .canceled, .unsubscribe, .free-trial {
  position: relative;
  font-weight: 500;
}

.trial {
  color: #4dbce9;
}

.resolved {
  color: #4dbce9;
  font-weight: 500;
}

.Subscibe {
  color: #6aa14e;
}

.expired {
  color: #e76c04;
}

.canceled {
  color: #e33945;
}

.unsubscribe {
  color: #999999;
}

.free-trial {
  color: #c261ff;
}

.trial:before {
  content: '';
  background: url(img/sprite.png);
  background-size: 600px;
  position: absolute;
  width: 12px;
  height: 12px;
  right: -16px;
  background-position: -10px -459px;
  top: 2px;
  left: -16px;
}

.Subscibe:before {
  content: '';
  background: url(img/sprite.png);
  background-size: 600px;
  position: absolute;
  width: 12px;
  height: 12px;
  right: -16px;
  background-position: -11px -394px;
  top: 2px;
  left: -16px;
}

.report-not-needed:before {
  display: none;
}

.expired:before {
  content: '';
  background: url(img/sprite.png);
  background-size: 600px;
  position: absolute;
  width: 12px;
  height: 12px;
  right: -16px;
  background-position: -10px -416px;
  top: 2px;
  left: -16px;
}

.canceled:before {
  content: '';
  background: url(img/sprite.png);
  background-size: 600px;
  position: absolute;
  width: 12px;
  height: 12px;
  right: -16px;
  background-position: -10px -438px;
  top: 2px;
  left: -16px;
}

.unsubscribe:before {
  content: '';
  background: url(img/sprite.png);
  background-size: 600px;
  position: absolute;
  width: 12px;
  height: 12px;
  right: -16px;
  background-position: -10px -504px;
  top: 2px;
  left: -16px;
}

.free-trial:before {
  content: '';
  background: url(img/sprite.png);
  background-size: 600px;
  position: absolute;
  width: 12px;
  height: 12px;
  right: -16px;
  background-position: -10px -482px;
  top: 2px;
  left: -16px;
}

/* end of left pannel css */

/* Report panel css */

.report-container {
  -webkit-box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #000000;
  background-color: #F9F9F9;
  width: 327px;
  height: auto;
  margin-top: 64px;
  position: fixed;
  top: 0;
  right: 0;
}

.report-container .report-head {
  -webkit-box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
  height: 68px;
  background-color: #292C36;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-items: center;
}

.report-container .report-head p {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #ffffff;
  margin: 0;
  text-transform: uppercase;
}

.report-container .report-body {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 134px);
}

.report-container .report-body {
  padding: 20px 6px 20px 0;
}

.report-container .report-body .report-list ul {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
}

.report-container .report-body .report-list ul li {
  width: 82px;
  height: 84px;
  border: 1px solid #B71B1B;
  border-radius: 4px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
  margin: 0 0 20px 19px;
}

.report-container .report-body .report-list ul li .report-lists .report-icon {
  min-height: 43px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.report-container .report-body .report-list ul li .report-lists .report-icon>div {
  height: 43px;
  width: 40px;
  margin: 0 auto;
}

.report-container .report-body .report-list ul li:hover {
  background: #B71B1B;
}

.report-container .report-body .report-list ul li.exit .report-lists .report-icon, .report-container .report-body .report-list ul li.SpecialHazard .report-lists .report-icon, .report-container .report-body .report-list ul li.Gcleaning .report-lists .report-icon, .report-container .report-body .report-list ul li.hose .report-lists .report-icon, .report-container .report-body .report-list ul li.HoodSystem .report-lists .report-icon {
  margin-bottom: 3px;
  margin-top: 6px;
}

.report-container .report-body .report-list ul li.exit .report-lists .report-icon .exit-in {
  background-position: -266px -9px;
}

.report-container .report-body .report-list ul li.exit:hover .report-lists .report-icon .exit-in {
  background-position: -206px -9px;
}

.report-container .report-body .report-list ul li.Alarm .report-lists .report-icon .Alarm-in {
  background-position: -265px -97px;
}

.report-container .report-body .report-list ul li.Alarm:hover .report-lists .report-icon .Alarm-in {
  background-position: -205px -97px;
}

.report-container .report-body .report-list ul li.Pump .report-lists .report-icon .Pump-in {
  background-position: -268px -55px;
  width: 44px;
}

.report-container .report-body .report-list ul li.Pump:hover .report-lists .report-icon .Pump-in {
  background-position: -208px -54px;
  width: 44px;
}

.report-container .report-body .report-list ul li.Backflow .report-lists .report-icon .Backflow-in {
  background-position: -268px -140px;
  width: 44px
}

.report-container .report-body .report-list ul li.Backflow:hover .report-lists .report-icon .Backflow-in {
  background-position: -208px -140px;
}

.report-container .report-body .report-list ul li.Security .report-lists .report-icon .Security-in {
  background-position: -269px -235px;
  width: 48px;
}

.report-container .report-body .report-list ul li.Security:hover .report-lists .report-icon .Security-in {
  background-position: -208px -235px;
}

.report-container .report-body .report-list ul li.Extinguisher .report-lists .report-icon .Extinguisher-in {
  background-position: -263px -183px;
}

.report-container .report-body .report-list ul li.Extinguisher:hover .report-lists .report-icon .Extinguisher-in {
  background-position: -202px -183px;
}

.report-container .report-body .report-list ul li.SpecialHazard .report-lists .report-icon .SpecialHazard-in {
  background-position: -270px -334px;
  width: 42px;
}

.report-container .report-body .report-list ul li.SpecialHazard:hover .report-lists .report-icon .SpecialHazard-in {
  background-position: -209px -334px;
}

.report-container .report-body .report-list ul li.FirePump .report-lists .report-icon .FirePump-in {
  background-position: -328px -8px;
  width: 46px;
}

.report-container .report-body .report-list ul li.FirePump:hover .report-lists .report-icon .FirePump-in {
  background-position: -393px -8px;
}

.report-container .report-body .report-list ul li.Sprinkler .report-lists .report-icon .Sprinkler-in {
  background-position: -262px -287px;
}

.report-container .report-body .report-list ul li.Sprinkler:hover .report-lists .report-icon .Sprinkler-in {
  background-position: -202px -287px;
}

.report-container .report-body .report-list ul li.Gcleaning .report-lists .report-icon .Gcleaning-in {
  background-position: -330px -103px;
  width: 50px;
}

.report-container .report-body .report-list ul li.Gcleaning:hover .report-lists .report-icon .Gcleaning-in {
  background-position: -395px -103px;
}

.report-container .report-body .report-list ul li.hose .report-lists .report-icon .hose-in {
  background-position: -320px -152px;
  width: 50px;
}

.report-container .report-body .report-list ul li.hose:hover .report-lists .report-icon .hose-in {
  background-position: -384px -152px;
}

.report-container .report-body .report-list ul li.HoodSystem .report-lists .report-icon .HoodSystem-in {
  background-position: -330px -56px;
}

.report-container .report-body .report-list ul li.HoodSystem:hover .report-lists .report-icon .HoodSystem-in {
  background-position: -395px -56px;
}

.report-container .report-body .report-list ul li.Suppression .report-lists .report-icon .Suppression-in {
  background-position: -330px -307px;
  width: 50px;
}

.report-container .report-body .report-list ul li.Suppression:hover .report-lists .report-icon .Suppression-in {
  background-position: -395px -307px;
}

.report-container .report-body .report-list ul li.Hydrant .report-lists .report-icon .Hydrant-in {
  background-position: -323px -254px;
}

.report-container .report-body .report-list ul li.Hydrant:hover .report-lists .report-icon .Hydrant-in {
  background-position: -388px -254px;
}

.report-container .report-body .report-list ul li.WaterTank .report-lists .report-icon .WaterTank-in {
  background-position: -323px -202px;
}

.report-container .report-body .report-list ul li.WaterTank:hover .report-lists .report-icon .WaterTank-in {
  background-position: -387px -202px;
}

.report-container .report-body .report-list ul li.Monitoring .report-lists .report-icon .Monitoring-in {
  background-position: -319px -466px;
}

.report-container .report-body .report-list ul li.Monitoring:hover .report-lists .report-icon .Monitoring-in {
  background-position: -384px -466px;
}

.report-container .report-body .report-list ul li.Sensitivity .report-lists .report-icon .Sensitivity-in {
  background-position: -329px -411px;
  width: 54px;
}

.report-container .report-body .report-list ul li.Sensitivity:hover .report-lists .report-icon .Sensitivity-in {
  background-position: -394px -411px;
}

.report-container .report-body .report-list ul li.Others .report-lists .report-icon .Others-in {
  background-position: -329px -360px;
}

.report-container .report-body .report-list ul li.Others:hover .report-lists .report-icon .Others-in {
  background-position: -395px -360px;
}

.report-container .report-body .report-list ul li.WorkOrder .report-lists .report-icon .WorkOrder-in {
  background-position: -326px -517px;
}

.report-container .report-body .report-list ul li.WorkOrder:hover .report-lists .report-icon .WorkOrder-in {
  background-position: -391px -517px;
}

.report-container .report-body .report-list ul li.Quote .report-lists .report-icon .Quote-in {
  background-position: -271px -385px;
}

.report-container .report-body .report-list ul li.Quote:hover .report-lists .report-icon .Quote-in {
  background-position: -271px -437px;
}

.report-container .report-body .report-list ul li .report-lists p {
  margin: 0;
  font-size: 12px;
  line-height: 13px;
  word-break: normal;
  text-align: center;
  color: #000;
  padding: 0 6px;
}

#hose {
  word-break: break-word;
}

.report-container .report-body .report-list ul li:hover p {
  color: #fff;
  font-weight: 500;
}

/* end of Report panel css */

/* deficiencies table css */

.global-table.deficiencies-table .table tbody tr td table tbody tr td:nth-child(1) {
  width: 18%;
}

.global-table.deficiencies-table .table tbody tr td table tbody tr td:nth-child(2) {
  width: 10%;
}

.global-table.deficiencies-table .table tbody tr td table tbody tr td:nth-child(3) {
  width: 10%;
}

.global-table.deficiencies-table .table tbody tr td table tbody tr td:nth-child(4) {
  width: 10%;
}

.global-table.deficiencies-table .table tbody tr td table tbody tr td:nth-child(5) {
  width: 16%;
}

.global-table.deficiencies-table .table tbody tr td table tbody tr td:nth-child(6) {
  width: 26%;
  padding-left: 20px;
  text-align: left;
}

.global-table.deficiencies-table .table tbody tr td table tbody tr td:nth-child(7) {
  width: 10%;
}

.global-table.deficiencies-table .table thead tr th:first-child {
  width: 18%;
}

.global-table.deficiencies-table .table thead tr th:nth-child(2) {
  width: 10%;
}

.global-table.deficiencies-table .table thead tr th:nth-child(3) {
  width: 10%;
}

.global-table.deficiencies-table .table thead tr th:nth-child(4) {
  width: 10%;
}

.global-table.deficiencies-table .table thead tr th:nth-child(5) {
  width: 16%;
}

.global-table.deficiencies-table .table thead tr th:nth-child(6) {
  width: 26%;
  text-align: left;
}

.global-table.deficiencies-table .table thead tr th:nth-child(7) {
  width: 10%;
}

/* email icon on table css */

.email-icon.sprite-icon-before:before {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -28px;
  top: -4px;
  background-position: -31px -474px;
  cursor: pointer;
}

.email-icon.sprite-icon-before.email-icon-pos:before {
  right: 0;
  top: -2px;
}

/* end of email icon on table css */

/* toggle button css */

.tgl {
  display: none;
}

.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl+.tgl-btn {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.tgl::-moz-selection, .tgl:after::-moz-selection, .tgl:before::-moz-selection, .tgl *::-moz-selection, .tgl *:after::-moz-selection, .tgl *:before::-moz-selection, .tgl+.tgl-btn::-moz-selection {
  background: none;
}

.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl+.tgl-btn::selection {
  background: none;
}

.tgl+.tgl-btn {
  outline: 0;
  display: block;
  width: 34px;
  height: 14px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tgl+.tgl-btn:after, .tgl+.tgl-btn:before {
  position: relative;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
}

.tgl+.tgl-btn:after {
  left: 0;
}

.tgl+.tgl-btn:before {
  display: none;
}

.tgl:checked+.tgl-btn:after {
  left: 57%;
}

.tgl:checked+.tgl-btn-offline:after {
  left: 50%;
}

.tgl-light+.tgl-btn {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 2em;
  padding: 2px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.tgl-light+.tgl-btn-offline {
  /* background-color: #b71b1b !important; */
  border: 3px solid white;
}

.tgl+.tgl-btn-offline{
  height: 25px;
}

.tgl-light+.tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.80), 0 1px 30px 3px rgba(0, 0, 0, 0.50);
          box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.80), 0 1px 30px 3px rgba(0, 0, 0, 0.50);
  width: 17px;
  height: 17px;
  position: absolute;
  left: -1px;
  top: 1px;
}

.tgl-light+.tgl-btn-offline:after{
  /* background: #b71b1b; */
  /* border: 3px solid white; */
  left: 1px;
}

.tgl-light:checked+.tgl-btn {
  background: lightgray;
}

.tgl-light:checked+.tgl-btn-offline {
  background: #b71b1b;
  border: 3px solid white;
}

.tgl-ios+.tgl-btn {
  background: #fbfbfb;
  border-radius: 2em;
  padding: 2px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border: 1px solid #e8eae9;
}

.tgl-ios+.tgl-btn:after {
  border-radius: 2em;
  background: #fbfbfb;
  -webkit-transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}

.tgl-ios+.tgl-btn:hover:after {
  will-change: padding;
}

.image-reset-button {
  min-width: 0px;
  padding: 5px;
  font-size: 13px;
  word-break: break-all;
  min-width: 60px;
  max-width: 60px;
}

.tgl-ios+.tgl-btn:active {
  -webkit-box-shadow: inset 0 0 0 2em #e8eae9;
          box-shadow: inset 0 0 0 2em #e8eae9;
}

.tgl-ios+.tgl-btn:active:after {
  padding-right: 0.8em;
}

.tgl-ios:checked+.tgl-btn {
  background: #86d993;
}

.tgl-ios:checked+.tgl-btn:active {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.tgl-ios:checked+.tgl-btn:active:after {
  margin-left: -0.8em;
}

.tgl-skewed+.tgl-btn {
  overflow: hidden;
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-family: sans-serif;
  background: #888;
}

.tgl-skewed+.tgl-btn:after, .tgl-skewed+.tgl-btn:before {
  -webkit-transform: skew(10deg);
  transform: skew(10deg);
  display: inline-block;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.tgl-skewed+.tgl-btn:after {
  left: 100%;
  content: attr(data-tg-on);
}

.tgl-skewed+.tgl-btn:before {
  left: 0;
  content: attr(data-tg-off);
}

.tgl-skewed+.tgl-btn:active {
  background: #888;
}

.tgl-skewed+.tgl-btn:active:before {
  left: -10%;
}

.tgl-skewed:checked+.tgl-btn {
  background: #86d993;
}

.tgl-skewed:checked+.tgl-btn:before {
  left: -100%;
}

.tgl-skewed:checked+.tgl-btn:after {
  left: 0;
}

.tgl-skewed:checked+.tgl-btn:active:after {
  left: 10%;
}

.tgl-flat+.tgl-btn {
  padding: 2px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: #fff;
  border: 4px solid #f2f2f2;
  border-radius: 2em;
}

.tgl-flat+.tgl-btn:after {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: #f2f2f2;
  content: '';
  border-radius: 1em;
}

.tgl-flat:checked+.tgl-btn {
  border: 4px solid #7fc6a6;
}

.tgl-flat:checked+.tgl-btn:after {
  left: 50%;
  background: #7fc6a6;
}

.tgl-flip+.tgl-btn {
  padding: 2px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-family: sans-serif;
  -webkit-perspective: 100px;
  perspective: 100px;
}

.tgl-flip+.tgl-btn:after, .tgl-flip+.tgl-btn:before {
  display: inline-block;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 4px;
}

.tgl-flip+.tgl-btn:after {
  content: attr(data-tg-on);
  background: #02c66f;
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.tgl-flip+.tgl-btn:before {
  background: #ff3a19;
  content: attr(data-tg-off);
}

.tgl-flip+.tgl-btn:active:before {
  -webkit-transform: rotateY(-20deg);
  transform: rotateY(-20deg);
}

.tgl-flip:checked+.tgl-btn:before {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.tgl-flip:checked+.tgl-btn:after {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
  left: 0;
  background: #7fc6a6;
}

.tgl-flip:checked+.tgl-btn:active:after {
  -webkit-transform: rotateY(20deg);
  transform: rotateY(20deg);
}

/* end of toggle button css */

/* main container css */

.main-container {
  /* width: calc(100% - 243px);
    background: #E4E5E6;
    padding: 30px 60px 0 30px; */
  width: 100%;
  background: #E4E5E6;
  padding: 30px 60px 0 30px;
  padding-left: 273px;
  /* height: 100vh; */
  overflow: auto !important;
  margin-top: 64px;
  scroll-behavior: smooth;
}

.main-container.add-side-report {
  width: calc(100% - 327px);
  padding: 30px;
  padding-left: 273px;
  /* height: 100%; */
  overflow: auto !important;
}

/* use only one */

.top-search-field, .top-search-feild {
  margin-bottom: 24px;
}

.search-container input {
  height: 40px;
  border: 1px solid #c4c4c4;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #fff;
  padding: 12px;
  min-width: 356px;
  border-right: none;
}

#master-acc .search-container input {
  min-width: calc(100% - 75px);
}

.search-container button {
  position: relative;
}

.search-container button .search-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 10px;
  background-position: -30px -392px;
  left: 28px;
  color: #fff;
}

.search-container input:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.search-container button {
  background: #b71b1b;
  width: 75px;
  height: 40px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
}

.search-container button:focus {
  outline: none;
}

/* small search area */

.sm-search-fld.search-container button {
  height: 30px;
}

.sm-search-fld.search-container input {
  min-width: 230px;
  height: 30px;
}

.sm-search-fld.search-container button .search-icon {
  top: 6px;
}

.sm-search-fld .cross-button {
  height: 1em;
  left: -20px;
  top: 4px;
}

/* end of small search */

.filter-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-justify-content: space-between;
}

.filter-section label {
  color: #4c4c4c;
  font-weight: 500;
  padding-right: 15px;
}

.filter-section span.clip {
  height: 40px;
  width: 92px;
  border-radius: 4px;
  background: #dddddd;
  color: #4c4c4c;
  text-align: center;
  display: inline-block;
}

.ellipses-icon {
  position: relative;
}

.ellipses-icon:before {
  content: '';
  background: url(img/sprite1.png);
  background-size: 600px;
  position: absolute;
  width: 28px;
  height: 10px;
  right: -16px;
  background-position: -60px -482px;
  top: -3px;
  right: 44px;
}

/* cutom  model */

.modal-sub-heading {
  background: #fff;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 24px;
}

.modal-sub-heading.in {
  width: calc(100% + 48px);
  margin-left: -23px;
}

.modal-sub-heading p {
  margin: 0;
  color: #b71b1b;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.customer-detail-modal {
  width: 876px;
  height: auto;
  position: fixed;
  top: 64px;
  z-index: 99;
  right: -876px;
  /* background: red; */
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.customer-detail-modal.open {
  right: 0;
}

.customer-detail-modal .heading-panel {
  background: #292c36;
  height: 64px;
  padding: 22px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-flex;
  -webkit-justify-content: space-between;
  border-bottom: 2px solid #b71b1b;
}

.heading-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #fff;
}

.customer-modal-body {
  background: #fff;
  padding: 22px 24px 24px;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 1px solid #c4c4c4;
  border-top: 0;
}

.add-customer-modal .customer-modal-body {
  background: #fff;
  padding: 22px 24px 24px;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: none;
  border-top: 0;
  background: #f5f5f5;
}

.cross {
  content: '';
  width: 20px;
  height: 20px;
  display: inline-block;
  background-position: -30px -418px;
  cursor: pointer;
}

.btn-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  margin-top: 26px;
}

.btn-section button {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-flex: 1;
}

.btn-section button:nth-child(2) {
  margin: 0 24px;
}

/* Analytics table css */

.analytics-table .tab-report-icons {
  position: absolute;
  background-position: -18px -357px;
  width: 20px;
  height: 20px;
  top: 18px;
  right: 30px;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.analytics-table .tab-report-icons:hover:before {
  content: '';
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: -7px;
}

.analytics-table .tab-report-icons:hover:after {
  content: 'Reports';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.table-striped th table tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.global-table .table thead tr th, .global-table .table thead tr th:first-child, .global-table .table thead tr th:nth-child(2), .global-table .table thead tr th:nth-child(3), .global-table .table thead tr th:nth-child(4), .global-table .table thead tr th:nth-child(5) {}

.global-table .table thead tr th {
  text-align: center;
}

.global-table .table thead tr th:first-child {
  text-align: left;
  padding-left: 30px;
}

.global-table.analytics-table .table thead tr th:first-child {
  width: 12%;
  text-align: left;
}

.global-table.analytics-table .table thead tr th:nth-child(2) {
  width: 20%;
}

.global-table.analytics-table .table thead tr th:nth-child(3) {
  width: 15%;
}

.global-table.analytics-table .table thead tr th:nth-child(4) {
  width: 15%;
}

.global-table.analytics-table .table thead tr th:nth-child(5) {
  width: 17%;
}

.global-table.analytics-table .table thead tr th:nth-child(6) {
  width: 18%;
}

.global-table.analytics-table .table thead tr th:nth-child(7) {
  width: 6%;
}

/* Supplier-table css */

.global-table.Supplier-table .table thead tr th:first-child {
  width: 12%;
}

.global-table.Supplier-table .table tbody tr td table tbody tr td:first-child {
  width: 12%;
}

.Supplier-table .edit-icon {
  right: 30px;
}

/* end of Supplier-table css *

/* td width */

.global-table .table tbody tr td table tbody tr td {
  text-align: center;
}

.global-table .table tbody tr td table tbody tr td:first-child {
  text-align: left;
}

.global-table.analytics-table .table tbody tr td table tbody tr td:first-child {
  width: 12%;
  text-align: left;
}

.global-table.analytics-table .table tbody tr td table tbody tr td:nth-child(2) {
  width: 20%;
}

.global-table.analytics-table .table tbody tr td table tbody tr td:nth-child(3) {
  width: 15%;
}

.global-table.analytics-table .table tbody tr td table tbody tr td:nth-child(4) {
  width: 15%;
}

.global-table.analytics-table .table tbody tr td table tbody tr td:nth-child(5) {
  width: 17%;
}

.global-table.analytics-table .table tbody tr td table tbody tr td:nth-child(6) {
  width: 18%;
}

.global-table.analytics-table .table tbody tr td table tbody tr td:nth-child(7) {
  width: 6%;
  position: relative;
}

.cus-inter-table.global-table .table tbody tr td table tbody tr td:nth-child(2) {
  width: 10%;
}

.cus-inter-table.global-table .table tbody tr td table tbody tr td:nth-child(5) {
  width: 8%;
}

.cus-inter-table.global-table .table thead tr th:nth-child(2) {
  width: 10%;
}

.cus-inter-table.global-table .table thead tr th:nth-child(5) {
  width: 8%;
}

.customer-in-table.global-table .table tbody tr td table tbody tr td:first-child {
  width: 8%;
}

.customer-in-table.global-table .table thead tr th:first-child {
  width: 8%;
}

span.open-icons.fixed-width-icon {
  width: 172px;
}

span.open-icons.fixed-width-icon span {
  margin-left: 8px;
}

span.open-icons.fixed-width-icon span.edit {
  left: -11px;
  top: 2px;
}

.global-table.deficiency-table .table tbody tr td table tbody tr td:nth-child(4) {
  text-align: left;
}

.global-table.deficiency-table .table thead tr th:nth-child(4) {
  text-align: left;
  left: -13px;
  position: relative;
}

.global-table.system-info .table thead tr th:nth-child(5) {
  width: 10%;
}

.global-table.system-info .table tbody tr td table tbody tr td:nth-child(5) {
  width: 10%;
}

/* .global-table.deficiency-table .table tbody tr td table tbody tr td:nth-child(1), .global-table.deficiency-table .table tbody tr td table tbody tr td:nth-child(2), .global-table.deficiency-table .table tbody tr td table tbody tr td:nth-child(3) {
    width: 12%;
}
.global-table.deficiency-table .table thead tr th:nth-child(3){
    width: 12%;
}
.global-table.deficiency-table .table thead tr th:first-child{
    width: 12%;
}
.global-table.deficiency-table .table thead tr th:nth-child(2) {
    width: 16%;
} */

.task-table.global-table .table tbody tr td table tbody tr td:nth-child(1) {
  width: 12%;
}

.task-table.global-table .table tbody tr td table tbody tr td:nth-child(3), .task-table.global-table .table tbody tr td table tbody tr td:nth-child(4), .task-table.global-table .table tbody tr td table tbody tr td:nth-child(5) {
  width: 15%;
}

.task-table.global-table .table thead tr th:nth-child(5), .task-table.global-table .table thead tr th:nth-child(4), .task-table.global-table .table thead tr th:nth-child(3) {
  width: 15%;
}

.task-table.global-table .table thead tr th:nth-child(1) {
  width: 12%;
}

.global-table.bind-info-table .table tbody tr td table tbody tr td:nth-child(2) {
  width: 28%;
}

.global-table.bind-info-table .table thead tr th:nth-child(2) {
  width: 28%;
}

/* custom popup table css */

.scroll-wrap {
  max-height: calc(100vh - 128px);
  /* overflow-y: scroll;
    overflow-x: hidden; */
}

.customer-detail-modal.customer-modal-table {
  /* width: 586px; */
  width: 610px;
}

.customer-modal-table .customer-modal-body {
  padding: 0;
  border: none;
}

.customer-modal-table .customer-modal-body .global-table {
  border-radius: 0;
  border: 0;
}

/* nationwide table */

.global-table.nationwide-table .table tbody tr td table tbody tr td:first-child {
  width: 30%;
}

.global-table.nationwide-table .table tbody tr td table tbody tr td:nth-child(2) {
  width: 30%;
}

.global-table.nationwide-table .table tbody tr td table tbody tr td:nth-child(3) {
  width: 30%;
}

.global-table.nationwide-table .table tbody tr td table tbody tr td:nth-child(4) {
  width: 10%;
  position: relative;
}

.global-table.nationwide-table .table thead tr th:first-child {
  width: 30%;
}

.global-table.nationwide-table .table thead tr th:nth-child(2) {
  width: 30%;
}

.global-table.nationwide-table .table thead tr th:nth-child(3) {
  width: 30%;
}

.global-table.nationwide-table .table thead tr th:nth-child(4) {
  width: 10%;
}

/* nationwide-2 table */

.global-table.nationwide-table-2 .table tbody tr td table tbody tr td:first-child {
  width: 65%;
}

.global-table.nationwide-table-2 .table tbody tr td table tbody tr td:nth-child(2) {
  width: 23%;
}

.global-table.nationwide-table-2 .table tbody tr td table tbody tr td:nth-child(3) {
  width: 12%;
  position: relative;
}

.global-table.nationwide-table-2 .table thead tr th:first-child {
  width: 65%;
}

.global-table.nationwide-table-2 .table thead tr th:nth-child(2) {
  width: 23%;
}

.global-table.nationwide-table-2 .table thead tr th:nth-child(3) {
  width: 12%;
}

/* customer table css */

.customer-table .table-striped tbody tr tbody tr:last-child {
  border-bottom: none;
}

.global-table.customer-table .table tbody tr td table tbody tr td:first-child {
  width: 20%;
  word-break: normal;
}

.global-table.customer-table .table.rowClassNameTeam tbody tr td table tbody tr td:first-child {
  width: 16%;
  word-break: normal;
}

.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(2) {
  width: 14%;
}

.global-table.customer-table .table.recurrInsClass tbody tr td table tbody tr td:nth-child(2) {
  width: 14%;
  text-align: left;
}

.global-table.customer-table .table.systemInfoTableClass tbody tr td table tbody tr td:nth-child(2) {
  width: 22%;
}

.global-table.customer-table .table.rowClassNameTeam tbody tr td table tbody tr td:nth-child(2) {
  width: 26%;
}

.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(3) {
  width: 16%;
}

.global-table.customer-table .table.systemInfoTableClass tbody tr td table tbody tr td:nth-child(3) {
  width: 25%;
}

.global-table.customer-table .table.rowClassNameTeam tbody tr td table tbody tr td:nth-child(3) {
  width: 26%;
}

.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(4) {
  width: 15%;
}

.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(5) {
  width: 13%;
}

.global-table.customer-table.content-box.bind-info-table .table tbody tr td table tbody tr td:nth-child(5) {
  width: 22%;
}

.global-table.customer-table.content-box.bind-info-table .table.systemInfoTableClass tbody tr td table tbody tr td:nth-child(5) {
  width: 13%;
}

.global-table.customer-table.content-box.bind-info-table .table.customerMainTableClass tbody tr td table tbody tr td:nth-child(5) {
  width: 16%;
}

.global-table.customer-table.content-box.bind-info-table .table.rowClassNameTeam tbody tr td table tbody tr td:nth-child(5) {
  width: 13%;
}

.global-table.customer-table .table thead tr th:first-child {
  width: 20%;
}
.global-table.customer-table .table.bugTicketTables thead tr th:first-child{
  width: 14%;
}
.global-table.customer-table .table .rowClassNameTeam thead tr th:first-child {
  width: 14%;
}

.global-table.customer-table .table thead tr th:nth-child(2) {
  width: 14%;
}

.global-table.customer-table .table.recurrInsClass thead tr th:nth-child(2) {
  width: 25%;
}

.global-table.customer-table .table.rowClassNameTeam thead tr th:nth-child(2) {
  width: 25%;
}

.global-table.customer-table .table thead tr th:nth-child(3) {
  width: 16%;
}

.global-table.customer-table .table.rowClassNameTeam thead tr th:nth-child(3) {
  width: 25%;
}

.global-table.customer-table .table thead tr th:nth-child(4) {
  width: 15%;
}

.global-table.customer-table .table.rowClassNameTeam thead tr th:nth-child(4) {
  width: 15%;
  text-align: left;
}

.global-table.customer-table .table.myjobUniqueTable thead tr th:nth-child(4) {
  padding-left: 48px;
}

.global-table.customer-table.content-box.bind-info-table .table.myjobUniqueTable tbody tr td table tbody tr td:nth-child(5) {
  width: 10%;
}

/* Fixing table width for team listing. */

.global-table.customer-table .table.rowClassNameTeam thead tr th:nth-child(5) {
  width: 13%;
}

.global-table.customer-table .table.rowClassNameTeam tbody tr td table tbody tr td:first-child {
  width: 16%;
  word-break: normal;
}

/* view report table css */

.global-table.view-report-table .table thead tr th:nth-child(2) {
  width: 15%;
}

.global-table.view-report-table .table thead tr th:nth-child(3) {
  width: 15%;
}

.global-table.view-report-table .table thead tr th:nth-child(4) {
  width: 18%;
}

.global-table.customer-table.serviceRequestTableonly .table thead tr th:first-child {
  width: 10%;
}

.global-table.customer-table.serviceRequestTableonly .table thead tr th:nth-child(5) {
  width: 26%;
}

.global-table.view-report-table .table tbody tr td table tbody tr td:nth-child(2) {
  width: 15%;
}

.global-table.view-report-table .table tbody tr td table tbody tr td:nth-child(3) {
  width: 15%;
}

.global-table.view-report-table .table tbody tr td table tbody tr td:nth-child(4) {
  width: 18%;
}

/* report table css */

.global-table.report-table .table tbody tr td table tbody tr td:first-child {
  width: 20%;
}

.global-table.report-table .table tbody tr td table tbody tr td:nth-child(2) {
  width: 14%;
}

.global-table.report-table .table tbody tr td table tbody tr td:nth-child(3) {
  width: 16%;
}

.global-table.report-table .table tbody tr td table tbody tr td:nth-child(4) {
  width: 15%;
}

.global-table.report-table .table tbody tr td table tbody tr td:nth-child(5) {
  width: 13%;
}

.global-table.report-table .table tbody tr td table tbody tr td:nth-child(6) {
  width: 10%;
}

.global-table.report-table .table tbody tr td table tbody tr td:nth-child(7) {
  width: 12%;
}

.global-table.report-table .table thead tr th:first-child {
  width: 20%;
}

.global-table.report-table .table thead tr th:nth-child(2) {
  width: 14%;
}

.global-table.report-table .table thead tr th:nth-child(3) {
  width: 16%;
}

.global-table.report-table .table thead tr th:nth-child(4) {
  width: 15%;
}

.global-table.report-table .table thead tr th:nth-child(5) {
  width: 13%;
}

.global-table.report-table .table thead tr th:nth-child(6) {
  width: 10%;
}

.global-table.report-table .table thead tr th:nth-child(7) {
  width: 12%;
}

/* inspection table css */

.global-table.inspection-table .table thead tr th:first-child {
  width: 10%;
}

.global-table.inspection-table .table tbody tr td table tbody tr td:first-child {
  width: 10%;
}

/* recent tble css */

.global-table.recent-table .table tbody tr td table tbody tr td:first-child {
  width: 10%;
}

.global-table.recent-table .table thead tr th:first-child {
  width: 10%;
}

/* incomplete table css */

.global-table.incomplete-table .table thead tr th:first-child {
  width: 10%;
}

.global-table.incomplete-table .table thead tr th:nth-child(2) {
  width: 15%;
}

.global-table.incomplete-table .table thead tr th:nth-child(3) {
  width: 18%;
}

.global-table.incomplete-table .table thead tr th:nth-child(4) {
  width: 12%;
}

.global-table.incomplete-table .table thead tr th:nth-child(5) {
  width: 15%;
}

.global-table.incomplete-table .table thead tr th:nth-child(6) {
  width: 15%;
}

.global-table.incomplete-table .table tbody tr td table tbody tr td:nth-child(6) {
  width: 15%;
}

.global-table.incomplete-table .table tbody tr td table tbody tr td:nth-child(5) {
  width: 15%;
}

.global-table.incomplete-table .table tbody tr td table tbody tr td:nth-child(4) {
  width: 12%;
}

.global-table.incomplete-table .table tbody tr td table tbody tr td:nth-child(3) {
  width: 18%;
}

.global-table.incomplete-table .table tbody tr td table tbody tr td:nth-child(2) {
  width: 15%;
}

.global-table.incomplete-table .table tbody tr td table tbody tr td:nth-child(1) {
  width: 10%;
}

/* service request table css */

.global-table.service-req-table .table thead tr th:nth-child(1) {
  width: 15%;
}

.global-table.service-req-table .table thead tr th:nth-child(4) {
  width: 25%;
}

.global-table.service-req-table .table thead tr th:nth-child(5) {
  text-align: left;
}

.global-table.service-req-table .table tbody tr td table tbody tr td:last-child {
  text-align: left;
  padding-left: 20px;
}

.global-table.service-req-table .table tbody tr td table tbody tr td:nth-child(1) {
  width: 15%;
}

.global-table.service-req-table .table tbody tr td table tbody tr td:nth-child(4) {
  width: 25%;
}

/* analytic screen css */

.year-selection .custom-select {
  min-width: 106px;
  /* height: 40px; */
}

/* .year-selection .select-selected{
    height: 40px;
} */

.main-container#analytic-id {
  position: relative;
}

.main-container#analytic-id:before {
  display: block;
  content: '';
  background: url(img/analytic-bg.png);
  height: 367px;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}

#analytic-id h1 {
  color: #ffffff;
  position: relative;
}

.analytic-boxes ul {
  padding-left: 0;
}

.analytic-boxes .analytic-box {
  -webkit-box-shadow: 0 6px 18px 3px rgba(0, 0, 0, 0.12);
          box-shadow: 0 6px 18px 3px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  height: 150px;
  /* width: 324px; */
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-align-items: center;
  padding-left: 28px;
  position: relative;
}

.analytic-boxes .analytic-box:before {
  content: '';
  width: 108px;
  height: 140px;
  position: absolute;
  right: 0;
  top: 0;
  background: url(img/analytic-circles.png);
  background-repeat: no-repeat;
}

.analytic-boxes .analytic-no {
  padding-right: 20px;
  color: #2f353b;
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
}

.analytic-boxes .analytic-text {
  font-size: 16px;
  line-height: 24px;
  color: #4c4c4c;
  max-width: 110px;
}

.analytic-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.analytic-container .flex-size {
  /* flex: 1;
    -webkit-flex:1 */
  width: 33.333%;
  margin-bottom: 30px;
}

.graph-section img {
  max-width: 104%;
  width: 106%;
  margin-left: -2%;
  margin-top: -15px;
}

/* nationwide css */

.search-with-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.search-with-btn .search-container {
  margin-right: 30px;
}

.cust-select-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  margin-top: 24px;
}

.cust-select-btns button {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-flex: 1;
}

.cust-select-btns button:first-child {
  margin-right: 24px;
}

/* Report screnn css */

.next-btn-wrap {
  text-align: center;
  margin-top: 24px;
}

.select-group-sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.select-group-sec .firt-select, .select-group-sec .second-select {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-flex: 1;
}

.select-group-sec .firt-select {
  margin-right: 8px;
}

.filter-btn-grp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-justify-content: space-between;
  margin-bottom: 20px;
}

.head-back {
  position: relative;
  padding-left: 23px;
}

.head-back .back-arrow {
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  background-position: -101px -394px;
  top: 8px;
}

#select-end-date .DateInput_fang_1 {
  bottom: -14px !important;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  right: 10px;
  left: auto;
}

#select-end-date .SingleDatePicker_picker {
    left: auto !important;
    right: 0;
    top: 60px;
}

#select-start-date .DateInput_fang_1 {
  bottom: -14px !important;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  right: auto;
  left: 10px;
}

#select-start-date .SingleDatePicker_picker {
  bottom: auto !important;
  top: 60px;
}

.filter-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.appliyed-filter .custom-chip {
  margin-left: 8px;
}

.appliyed-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-right: 52px;
  display: -webkit-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-items: center;
}

.appliyed-filter span {
  color: #4c4c4c;
  font-weight: 500;
  font-size: 14px;
}

.search-list {
  margin-top: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
}

.search-list .search-chip {
  margin-right: 8px;
  margin-top: 8px;
}

.active-list {
  background: #b71b1b !important;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list.active-list {
  color: #fff;
  font-weight: 500;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.analytics {
  background-position: -42px -42px;
  width: 28px;
  height: 24px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.console {
  background-position: -42px -176px;
  width: 28px;
  height: 24px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 27px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.customer {
  background-position: -42px -9px;
  width: 28px;
  height: 24px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.team {
  background-position: -170px -73px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.task {
  background-position: -169px -157px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.building {
  background-position: -93px -416px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.dashboard {
  background-position: -170px -8px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.Settings {
  /* background-position: -42px -143px; */
  background: url(img/icons8-settings-hover.png);
  /* background-position: -42px -143px; */
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.service-rqst {
  background-position: -169px -183px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.report-list {
  background-position: -169px -127px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.building {
  background-position: -93px -416px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.inventory {
  /* background-position: -170px -216px; */
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.joblink {
  background-position: -170px -100px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.reports {
  background-position: -42px -110px;
  width: 28px;
  height: 24px;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 18px;
}

/*upto here */

/* subscription card css */

.subscription-icon {
  width: 36px;
  height: 46px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 20px;
}

.subscription-icon:before {
  width: 36px;
  height: 46px;
  background-position: -97px -9px;
  position: absolute;
  left: 0;
}

.subdcription-heading {
  color: #4c4c4c;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
}

.subdcription-heading+p {
  text-align: center;
  margin-bottom: 0;
  color: #4c4c4c;
}

.card-body.pad-20 {
  padding: 20px 20px 30px 20px;
}

.card-footer .rate {
  margin: 0;
  font-size: 32px;
  color: #b71b1b;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
}

.card-footer .rate .dolr {
  color: #4c4c4c;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
}

.card-footer .rate .month {
  color: #4c4c4c;
  font-size: 12px;
  line-height: 19px;
}

.card-footer {
  background: rgba(235, 235, 235, 0.23);
  border-top: none;
  padding: 8px 10px;
}

.subdcription-heading+p {
  height: 62px;
  overflow-y: auto;
  overflow-x: hidden;
}

.subdcription-heading+p {
  text-align: center;
  margin-bottom: 0;
  color: #4C4C4C;
}

.card-body.pad-20 {
  padding: 20px 20px 30px 20px;
}

.card-footer .rate {
  margin: 0;
  font-size: 32px;
  color: #B71B1B;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
}

.card-footer .rate .dolr {
  color: #4C4C4C;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
}

.card-footer .rate .month {
  color: #4C4C4C;
  font-size: 12px;
  line-height: 19px;
}

.card-footer {
  background: rgba(235, 235, 235, .23);
  border-top: none;
  padding: 8px 10px;
}

.subscription-icon {}

/* search list item css */

.search-list-items ul {
  padding-left: 0;
  margin-bottom: 24px;
}

.search-list-items ul li {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-align-items: center;
  width: calc(100% + 48px);
  margin-left: -24px;
  padding-left: 24px;
}

.search-list-items ul li.list-bg {
  background: rgba(255, 183, 188, 0.2) !important;
}

.search-list-items ul li:nth-child(odd) {
  background: rgba(76, 76, 76, 0.03);
}

/* custom scroll css */

.mCSB_scrollTools .mCSB_draggerRail {
  width: 0px !important;
}

.mCSB_draggerContainer #mCSB_1_dragger_vertical {
  max-height: 160px !important;
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #cacaca !important;
}

.mCSB_inside>.mCSB_container {
  margin-right: 0 !important;
}

.mCSB_outside+.mCSB_scrollTools {
  right: 0 !important;
}

/* end of custom scroll css */

/* table scroll css */

.table-wrap-scroll {
  position: relative;
}

.table-body-scroll {
  max-height: calc(100vh - 128px);
  overflow: auto;
}

/* customer home screen css */

.state-tax {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-align-items: center;
}

.state-tax p {
  margin: 0;
  margin-right: 10px;
  font-weight: 500;
  color: #4c4c4c;
}

.tax-heading-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.add-qr button {
  width: 100%;
}

.flip-icon {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.radio-hide-notes {
  margin-top: -20px;
}

#test-delete-icon-span {
  right: 0;
}

.mark-lists {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.mark-lists label {
  margin-right: 12px;
}

.mark-lists label:last-child {
  margin-right: 0px;
}

.selected-month-text {
  margin-top: 10px;
}

.add-task-inspection {
  position: relative;
}

.info-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 2px;
  background-position: -60px -483px;
  cursor: pointer;
}

.add-another-task {
  font-size: 16px;
  margin-top: 24px;
  color: #dd231d;
  padding-left: 24px;
}

.modal-footer input.form-control {
  background: #fff;
}

.modal-footer .select-selected {
  background: #fff;
}

label.editable-text {
  padding-right: 36px;
  position: relative;
  font-weight: normal;
}

label.editable-text .edit-label.sprite-icon1 {
  width: 15px;
  height: 20px;
  position: absolute;
  right: 28px;
  background-position: -61px -571px;
  cursor: pointer;
}

label.editable-text .edit-label.sprite-icon1.edit-vr-2 {
  background: none;
  font-size: 20px;
  color: #B71B1B;
}

label.editable-text .del-label.sprite-icon1 {
  width: 15px;
  height: 20px;
  position: absolute;
  right: 0px;
  background-position: -61px -545px;
  cursor: pointer;
}

label.editable-text .edit-icon.sprite-icon1.edit-notes-icon {
 right: 28px;
 width: 16px;
 height: 20px;
 cursor: pointer; 
 background-size: contain;
 background-repeat: no-repeat;
 position: absolute;
 margin-top: -5px;
}

label.editable-text .del-label.sprite-icon1.del-vr-2 {
  background: none;
  right: 4px;
  margin-top: -3px;
}

/* BUilding image info screen css */

.building-info-wrap {
  margin-bottom: 30px;
}

.upload-img-wrap {
  background: #eee;
  margin-bottom: 12px;
  border-radius: 4px;
  height: 110px;
  overflow: hidden;
  text-align: center;
}

.upload-img-wrap img {
  max-width: 100%;
}

.maximize-icon {
  position: absolute;
  right: 27px;
  top: 12px;
  width: 20px;
  height: 20px;
  background-position: -10px 24px;
}

.name-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  padding-right: 72px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-items: center;
}

.name-wrap .flname {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #b71b1b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
}

.name-text-wrap p {
  margin-bottom: 0;
  color: #4c4c4c;
}

.name-text-wrap p.name-heading {
  font-size: 16px;
  font-weight: 500;
}

.read-more, .read-more:hover {
  color: #b71b1b;
  font-size: 12px;
  text-decoration: underline;
  line-height: 14px;
}

.comment-details {
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-justify-content: space-between;
}

.cmnt-textarea textarea {
  margin-bottom: 0;
}

.cmnt-detail-list .flname {
  background: #4dbce9;
  width: 30px;
  height: 30px;
  min-width: 30px;
  font-size: 12px;
}

.comment-details.with-border {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 0;
  padding-bottom: 12px;
}

.cmnt-detail-list {
  padding-left: 30px;
  padding-top: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-flex;
  -webkit-justify-content: space-between;
}

.modification-items {
  position: relative;
}

.modification-items span {
  width: 20px;
  height: 20px;
  position: absolute;
  cursor: pointer;
}

.modification-items span.delete {
  /* background-position: -58px 55px; */
  top: -2px;
  right: 0;
}

.modification-items span.edit {
  background-position: -58px 31px;
  top: 0;
  right: 26px;
}

.modification-items span.edit.edit-vr-2 {
  font-size: 20px;
  background-position: 0 0;
  color: #b71b1b;
}

.modification-items span.reply {
  background-position: -85px 29px;
  top: 0;
  right: 52px;
}

/* dashboard css */

.graph-area {
  z-index: 8;
  position: relative;
  padding: 30px 15px 5px 30px;
  margin-bottom: 40px;
  height: auto;
  border-radius: 4px;
  background: #ffffff;
  -webkit-box-shadow: 0 6px 18px 3px rgba(0, 0, 0, 0.12);
  box-shadow: 0 6px 18px 3px rgba(0, 0, 0, 0.12);
  font-family: 'Rubik', sans-serif;
  -webkit-box-align: center;
  -ms-flex-align: center;
      align-items: center;
  margin: auto;
}

.income-chart-head-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-items: center;
  margin: 40px 0px 10px 0px;
}

.income-chart-head-wrap .chart-head {
  color: #15264f;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
}

.income-chart-head-wrap .chart-month-select {
  color: #15264f;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
}

.income-chart-head-wrap .chart-month-select span {
  font-weight: 500;
}

#chart-month>span {
  font-weight: normal;
}

.income-chart-head-wrap .dis-flex, .income-chart-head-wrap .filter-dropdown {
  margin-left: 20px;
}

.income-chart-rt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-align-items: center;
}

.income-chart-head-wrap .custom-select {
  height: 40px;
  min-width: 106px;
}

.income-chart-head-wrap .select-selected {
  height: 40px;
  line-height: 22px;
}

.income-chart-head-wrap .select-selected:after {
  top: 14px;
}

.income-chart-head-wrap .dis-flex.min-134 {
  min-width: 134px;
}

.job-invoice-chart div {
  max-width: 100%;
}

.job-invoice-chart {
  /* margin-left: -15px; this will be remove once actual code will come. */
  /* margin-right: -15px; this will be remove once actual code will come. */
  font-family: 'Rubik', sans-serif;
  -webkit-box-shadow: 0 6px 18px 3px rgba(0, 0, 0, 0.12);
  box-shadow: 0 6px 18px 3px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background: #ffffff;
  -webkit-box-align: center;
  -ms-flex-align: center;
      align-items: center;
  margin: auto;
}

.job-due-charts div {
  max-width: 100%;
}

.job-due-chart {
  /* margin-left: -15px; this will be remove once actual code will come. */
  /* margin-right: -15px; this will be remove once actual code will come. */
  font-family: 'Rubik', sans-serif;
  -webkit-box-shadow: 0 6px 18px 3px rgba(0, 0, 0, 0.12);
  box-shadow: 0 6px 18px 3px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background: #ffffff;
  -webkit-box-align: center;
  -ms-flex-align: center;
      align-items: center;
  margin: auto;
}

/* subscription css */

.subscription-plan {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #cbd1d5;
  padding: 30px;
  text-align: center;
  position: relative;
}

.subscription-plan:before {
  content: '';
  width: 100%;
  height: 6px;
  background: #b71b1b;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px 4px 0 0;
}

.subscription-plan .subscription-head {
  font-size: 16px;
  color: #4c4c4c;
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
}

.subscription-plan .bill-text {
  font-size: 12px;
  color: #4c4c4c;
  line-height: 24px;
  margin: 0;
}

.subscription-plan ul {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 20px;
}

.subscription-plan ul li {
  border-top: 1px solid rgba(151, 151, 151, 0.2);
  padding: 7px;
  min-height: 39px;
}

.subscription-plan ul li p {
  font-size: 14px;
  color: #4c4c4c;
  line-height: 24px;
  margin: 0;
}

.subscription-plan-rate {
  height: 78px;
  width: calc(100% + 60px);
  margin-left: -30px;
  background: rgba(235, 235, 235, 0.23);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 14px;
}

.subscription-plan-rate p {
  margin: 0;
  font-size: 40px;
  color: #b71b1b;
}

.rt-rate p {
  margin: 0;
  font-size: 40px;
  color: #4c4c4c;
}

.subscription-plan-rate p .dolr {
  font-size: 20px;
  color: #4c4c4c;
  font-weight: 500;
}

.subscription-lists ul li.active .rt-rate p {
  color: #b71b1b;
}

.rt-rate p .dolr {
  font-size: 20px;
  color: #4c4c4c;
  font-weight: 500;
}

.subscription-plan-rate p .per-month {
  font-size: 20px;
  color: #4c4c4c;
}

.rt-rate p .per-month {
  font-size: 20px;
  color: #4c4c4c;
}

.subscription-lists ul {
  padding-left: 0;
}

.subscription-lists ul li {
  border: 1px solid #cbd1d5;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  min-height: 96px;
  padding: 13px 24px;
  margin-bottom: 20px;
}

.subscription-lists ul li.active {
  border: 1.5px solid #b71b1b;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
}

.subscription-lists .lt-check {
  padding-top: 10px;
  padding-right: 12px;
}

.subscription-lists .rt-rate {
  border-left: 1px solid rgba(151, 151, 151, 0.23);
  padding-left: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -wekit-flex;
  -webkit-align-items: center;
}

.subscription-lists .list-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-justify-content: space-between;
}

.subscription-lists .cust-checkbox {
  color: #4c4c4c;
}

.subscription-lists .cust-checkbox+p {
  color: #4c4c4c;
  font-size: 14px;
  line-height: 24px;
  margin-top: 4px;
  margin-bottom: 0;
  margin-left: 29px;
}

.qr-code {
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  height: 111px;
  width: 111px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-justify-content: center;
}

.qr-wrap {
  width: 111px;
}

.qr-wrap p {
  text-align: center;
  margin-top: 10px;
}

.qr-code img {
  max-width: 100%;
}

.company-logo-part {
  width: calc(100% - 95px);
}

.company-logo-part img {
  width: 100%;
}

.company-logo-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-flex;
  -webkit-justify-content: space-between;
}

button.btn-zoom.out.sprite-icon-before1:before {
  width: 30px;
  height: 34px;
  background-position: -98px -92px;
  position: absolute;
  top: 13px;
  left: 14px;
}

button.btn-zoom.out.sprite-icon-before1:hover:before {
  background-position: -138px -92px;
}

button.btn-zoom.in.sprite-icon-before1:before {
  width: 30px;
  height: 34px;
  background-position: -98px -132px;
  position: absolute;
  top: 13px;
  left: 14px;
}

button.btn-zoom.in.sprite-icon-before1:hover:before {
  background-position: -138px -132px;
}

button.btn-zoom.delete.sprite-icon-before1:before {
  width: 30px;
  height: 34px;
  background-position: -96px -173px;
  position: absolute;
  top: 13px;
  left: 14px;
}

button.btn-zoom.delete.sprite-icon-before1:hover:before {
  background-position: -131px -173px;
}

.company-logo-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  display: -webkit-flex;
}

.company-logo-action button {
  margin-bottom: 28px;
}

.company-logo-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: calc(100% - 88px);
  margin-bottom: 24px;
}

.company-logo-btns .uploads-btn button {
  margin-left: 20px;
}

.report-search-result {
  margin-top: 12px;
}

.report-search-result .search-chip {
  margin-left: 16px;
}

.report-search-result .search-chip:first-child {
  margin-left: 0;
}

.elected-report {
  font-size: 10px;
}

/* setting css */

.settins-card {
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  padding: 30px 30px 0;
}

.settins-card .heading {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #4c4c4c;
  margin-bottom: 14px;
}

.settins-card p {
  font-size: 12px;
  line-height: 24px;
  color: #4c4c4c;
  margin-bottom: 0;
}

.settins-card ul {
  padding-left: 0;
  margin-bottom: 0;
}

.settins-card ul li {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
}

.settins-card ul li:last-child {
  border-bottom: none;
}

.setting-card-scroll-ht {
  height: 600px;
}

/* chat box css */

.people-list ul li .user-img {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  overflow: hidden;
}

.people-list ul li .user-img img {
  max-width: 100%;
}

.start-new-chat {
  background: #f2f2f2;
  height: 74px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  padding: 14px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-items: center;
  cursor: pointer;
}

.new-chat.sprite-icon1 {
  width: 48px;
  height: 48px;
  background-position: -60px -274px;
  margin-right: 16px;
}

.start-new-chat p {
  margin-bottom: 0;
  color: #b71b1b;
  font-size: 15px;
}

.chat-list-btn {
  padding: 15px;
}

.chat-header-back {
  display: none;
}

.chat-icon-bottom {
  display: none;
}

.chat-list-btn button {
  min-width: 140px;
  width: 140px;
  font-size: 14px;
  /* padding: 0 12px; */
  font-weight: 400;
}

.chat-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.people-list {
  min-height: calc(100vh - 160px);
  max-height: 509px;
  overflow: scroll;
  width: 320px;
  min-width: 320px;
  background: #fff;
  margin-right: 16px;
  border: 1px solid #CBD1D5;
  border-radius: 4px;
}

.people-list .search {
  padding: 20px;
}

.people-list input {
  border-radius: 3px;
  border: none;
  padding: 14px;
  color: white;
  background: #6A6C75;
  width: 90%;
  font-size: 14px;
}

.people-list .fa-search {
  position: relative;
  left: -25px;
}

.people-list ul {
  padding: 0 0px 14px 15px;
  height: auto;
  margin-bottom: 0;
}

.chat-history ul li {
  padding: 0 45px;
}

.message.other-message.float-right .chat-user-img {
  right: -45px;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  top: 0;
  overflow: hidden;
}

.message.my-message .chat-user-img {
  position: absolute;
  left: -45px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  top: 0;
  overflow: hidden;
}

.message.my-message .chat-user-img img, .message.other-message.float-right .chat-user-img img {
  max-width: 100%;
}

.chat .chat-history ul {
  padding-left: 0;
}

.people-list ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-align-items: center;
  height: 74px;
  border-bottom: 1px solid rgba(26, 26, 26, 0.1);
  position: relative;
}

.people-list ul li .time {
  font-size: 12px;
  position: absolute;
  right: 8px;
  top: 20px;
  color: #cecece;
}

.people-list ul li.active .time {
  color: #fff;
  font-weight: 500;
  right: 25px;
}

.people-list img {
  float: left;
}

.people-list .about {
  float: left;
  margin-top: 8px;
}

.people-list .about {
  padding-left: 16px;
}

.people-list .status {
  color: #92959e;
}

.people-list .about .name {
  color: #4c4c4c;
  font-size: 14px;
  width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ;
}

.people-list ul li.active {
  width: calc(100% + 15px);
  background: #b71b1b;
  margin-left: -15px;
  padding: 15px 14px;
}

.people-list li.active .about .name {
  color: #fff;
  font-weight: 500;
}

.people-list li.active .status p {
  color: #fff;
  font-weight: 500;
}

.people-list .status p {
  margin: 0;
  font-size: 12px;
  color: #9c9a9a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 154px;
}

.people-list .notification .about .name, .people-list .notification .status p {
  font-weight: 500;
  color: #4c4c4c;
}

.people-list ul li.notification .time {
  color: #4c4c4c;
}

.notification .notify {
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  background: #B71B1B;
  border-radius: 50%;
  -webkit-box-shadow: inset 0 -1px 0 0 #EFEFEF;
          box-shadow: inset 0 -1px 0 0 #EFEFEF;
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 10px;
  bottom: 14px;
  left: 26px;
}

.chat-header .user-img {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
}

.chat-header .user-img img {
  width: 100%;
}

.chat {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: auto;
  background: #fff;
  border-radius: 4px;
  color: #434651;
  border: 1px solid #CBD1D5;
  border-radius: 4px;
  min-height: calc(100vh - 160px);
  max-height: 509px;
}

.chat .chat-header {
  margin: 15px;
  border-bottom: 1px solid #EFEFEF;
  padding-bottom: 15px;
  position: relative;
}

.chat .chat-header img {
  float: left;
}

.chat .chat-header .chat-about {
  padding-left: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
      align-self: center;
}

.chat .chat-header .chat-with {
  font-weight: 500;
  font-size: 16px;
  color: #1A1A1A;
}

.speaker-icon.sprite-icon1 {
  width: 20px;
  height: 20px;
  background-position: -128px -331px;
  position: absolute;
  right: 4px;
  top: 8px;
  cursor: pointer;
}

.chat .chat-header .chat-num-messages {
  color: #92959E;
}

.chat .chat-header .fa-star {
  float: right;
  color: #D8DADF;
  font-size: 20px;
  margin-top: 12px;
}

.chat .chat-history {
  padding: 30px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: scroll;
  height: 376px;
}

.chat .chat-history-wrap {
  height: 334px;
}

.chat .chat-history .message-data {
  margin-bottom: 15px;
}

.chat .chat-history .message-data-time {
  color: #9C9A9A;
  font-size: 12px;
}

.chat .chat-history .message {
  padding: 15px 17px;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 0px;
  line-height: 19px;
  width: auto;
  position: relative;
  color: #fff;
  display: inline-block;
}

.message-data1 {
  position: absolute;
  bottom: -19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  left: 0;
}

.message-data1 .seen-tick {
  color: #B71B1B;
  font-size: 12px;
  font-weight: 500;
  padding-right: 30px;
}

.speaker-icon.sprite-icon1 {
  width: 20px;
  height: 20px;
  background-position: -128px -331px;
  position: absolute;
  right: 4px;
  top: 8px;
  cursor: pointer;
}

.chat .chat-header .chat-num-messages {
  color: #92959E;
}

.chat .chat-header .fa-star {
  float: right;
  color: #D8DADF;
  font-size: 20px;
  margin-top: 12px;
}

.chat .chat-history {
  padding: 30px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: scroll;
  height: 376px;
}

.chat .chat-history-wrap {
  height: 334px;
}

.chat .chat-history .message-data {
  margin-bottom: 15px;
}

.chat .chat-history .message-data-time {
  color: #9C9A9A;
  font-size: 12px;
}

.chat .chat-history .message {
  padding: 15px 17px;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 0px;
  line-height: 19px;
  width: auto;
  position: relative;
  color: #fff;
  display: inline-block;
}

.message-data1 {
  position: absolute;
  bottom: -19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  left: 0;
}

.message-data1 .seen-tick {
  color: #B71B1B;
  font-size: 12px;
  font-weight: 500;
  padding-right: 30px;
}

.seen-tick.sprite-icon-before:before {
  width: 27px;
  height: 14px;
  background-position: 32px -458px;
  position: absolute;
  right: 0;
  top: 2px;
}

.typing-icon.sprite-icon-before1 {
  position: relative;
  min-width: 76px;
  padding: 3px 0;
}

.typing-icon.sprite-icon-before1:before {
  width: 34px;
  height: 12px;
  background-position: -121px -288px;
  position: absolute;
  top: 0px;
}

.chat .chat-history .message p {
  margin-bottom: 0;
}

.chat .chat-history .message.my-message {
  color: #4c4c4c;
  word-break: break-all;
}

.chat .chat-history .message.my-message:after {
  bottom: 100%;
  left: 0;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #f2f2f2;
  border-width: 10px;
  margin-left: -8px;
  top: 0;
}

.chat .chat-history .message.other-message:after {
  bottom: 100%;
  left: auto;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #b71b1b;
  border-width: 10px;
  margin-left: -8px;
  top: 0;
  right: -8px;
}

.chat .chat-history .my-message {
  background: #f2f2f2;
}

.chat .chat-history .other-message {
  background: #b71b1b;
  margin-bottom: 35px;
  word-break: normal;
}

.chat .chat-history .other-message:after {
  border-bottom-color: #94c2ed;
  left: 93%;
}

.chat .chat-message {
  padding: 0;
  border-top: 1px solid #cbd1d5;
  margin-top: 40px;
  position: relative;
}

.chat .chat-message textarea {
  width: 100%;
  border: none;
  padding: 18px 66px 0 19px;
  margin-bottom: 0;
  border-radius: 5px;
  resize: none;
  height: 55px;
  outline: none;
}

textarea.message-to-send::-webkit-input-placeholder {
  color: #8b8b8b;
  font-size: 12px;
}

textarea.message-to-send::-moz-placeholder {
  /* Firefox 19+ */
  color: #8b8b8b;
  font-size: 12px;
}

textarea.message-to-send:-ms-input-placeholder {
  color: #8b8b8b;
  font-size: 12px;
}

textarea.message-to-send::-ms-input-placeholder {
  color: #8b8b8b;
  font-size: 12px;
}

textarea.message-to-send::placeholder {
  color: #8b8b8b;
  font-size: 12px;
}

.chat .chat-message .fa-file-o, .chat .chat-message .fa-file-image-o {
  font-size: 16px;
  color: gray;
  cursor: pointer;
}

button.send-btn.sprite-icon1 {
  width: 38px;
  height: 38px;
  position: absolute;
  top: 10px;
  right: 20px;
  /* background-position: -15px -275px; */
  border: none;
}

.attached-btn.sprite-icon {
  width: 38px;
  height: 38px;
  position: absolute;
  top: 8px;
  right: 76px;
  background-position: 39px -497px;
  border: none;
}

/* .chat .chat-message button {
    float: right;
    color: #94C2ED;
    font-size: 16px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    font-weight: bold;
    background: #F2F5F8;
  }
  .chat .chat-message button:hover {
    color: #75b1e8;
  } */

.online, .offline, .me {
  margin-right: 3px;
  font-size: 10px;
}

.online {
  color: #86bb71;
}

.offline {
  color: #e38968;
}

.me {
  color: #94c2ed;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.date-filter {
  width: 51px;
  height: 40px;
  border-radius: 4px;
  background: #fff;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 1px solid #b71b1b;
}

.date-filter.sprite-icon-before:before {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 10px;
  left: 15px;
  background-position: -32px -445px;
  background: url(img/calender-vr-2.png);
  background-repeat: no-repeat;
}

.downloadJob {
  width: 60px;
  height: 40px;
  border-radius: 4px;
  background: #fff;
  -webkit-box-shadow: 0 0 2px 0 rgba(182, 24, 24, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 2px 0 rgba(182, 24, 24, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 1px solid #b71b1b;
  margin-right: 6px;
  color: #b71b1b;
}
/* 

/* end of chat box css */

/* new report screen css */

.report-section {
  border: 2px solid #cbd1d5;
  /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
  background-color: #ffffff;
  padding: 30px;
}

.report-inner-part, .report-inner-group-part {
  border: 1px solid #cbd1d5;
  /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
  background-color: #ffffff;
  padding: 24px;
  margin-bottom: 15px;
}

.btn-rt-wrap {
  text-align: right;
}

.user-name-add-inn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

/* .user-name-add-inn .user-name-fld, .user-name-add-inn .user-address-fld{
    flex:1;
} */

.user-name-add-inn .user-name-fld {
  width: 55%;
}

.user-name-add-inn .user-address-fld {
  width: 45%;
}

.user-address-fld-inn .ofc-no, .user-address-fld-inn .ofc-addr {
  position: relative;
}

.user-address-fld-inn .ofc-no.sprite-icon-before:before {
  width: 12px;
  height: 18px;
  background-position: 52px -456px;
  position: absolute;
  left: -20px;
  z-index: 0;
}

.user-address-fld-inn .ofc-addr.sprite-icon-before:before {
  width: 17px;
  height: 18px;
  background-position: 30px -479px;
  position: absolute;
  left: -23px;
  z-index: 0;
}

.user-name-fld .user-img {
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  background-color: #e4e5e6;
  border-radius: 50%;
  margin-right: 20px;
  overflow: hidden;
}

.user-name-fld .user-img img {
  max-width: 100%;
  width: 100%;
}

.work-list-icon .frequency-drop {
  position: absolute;
  right: 10px;
  top: -16px;
  width: 140px;
}

.frequency-drop .select-selected {
  height: 30px;
  padding: 0px 16px;
  font-size: 12px;
}

.frequency-drop .custom-select+label {
  /* font-size: 10px; */
  margin-bottom: 2px;
}

.frequency-drop .custom-select {
  height: 30px;
}

.frequency-drop .select-selected:after {
  top: 10px;
  background-position: -77px 573px;
  right: 10px;
}

.frequency-drop .select-selected.select-arrow-active:after {
  top: 10px;
  background-position: -97px 573px;
  right: 10px;
}

.user-name-fld {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-items: center;
}

.user-name-fld .user-name p {
  font-size: 24px;
  color: #000;
  line-height: 28px;
}

.user-name-fld .user-house {
  width: 24px;
  height: 24px;
  position: relative;
}

.user-name-fld .user-house:before {
  width: 24px;
  height: 24px;
  background-position: -16px -16px;
  position: absolute;
  background: url(img/building-icon.png);
}

.user-address-fld {
  position: relative;
  padding-left: 20px;
}

.user-address-fld:before {
  content: '';
  width: 2px;
  height: 100%;
  height: -webkit-fill-available;
  /* background: #979797; */
  position: absolute;
  border-left: 2px dashed #979797;
  left: -32px;
  max-height: 100%;
}

.user-address-fld-inn .ofc-no p, .user-address-fld-inn .ofc-cell-no p, .user-address-fld-inn .ofc-addr p {
  margin-bottom: 0;
  color: #8e8e8e;
}

.user-address-fld-inn .ofc-no p span, .user-address-fld-inn .ofc-cell-no p span, .user-address-fld-inn .ofc-addr p span {
  color: #000000;
  padding-left: 6px;
}

.ofc-cell-no {
  margin: 16px 0;
}

.address-icon.sprite-icon1 {
  position: absolute;
  width: 20px;
  height: 20px;
  right: -10px;
  bottom: 0;
  background-position: -148px -15px;
  background: url(img/map-vr-2.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.house-list-wrap {
  display: none;
}

.house-list {
  width: 266px;
  background: #fff;
  margin-top: 13px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
}

.user-name-fld .user-house:hover .house-list-wrap {
  display: block;
  position: absolute;
  top: 20px;
  z-index: 9;
}

.house-list ul {
  padding-left: 0;
  margin-bottom: 0;
}

.house-list ul li {
  border-bottom: 1px solid #f2f2f2;
}

.house-list ul li:last-child {
  border-bottom: none;
}

.house-list ul li:hover {
  background: #f2f2f2;
}

.house-list ul li:hover a {
  color: #4c4c4c;
  font-weight: 500;
}

.house-list ul li a {
  line-height: 48px;
  display: block;
  height: 48px;
  padding: 0 16px;
  font-size: 14px;
  color: #828282;
  text-decoration: none;
}

.address-icon {
  position: relative;
}

.address-icon:hover:before {
  content: "";
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: -7px;
}

.address-icon:hover:after {
  content: "Map";
  width: 52px;
  height: 25px;
  background: #4C4C4C;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
  z-index: 9;
}

#get-start p, #get-start2 p {
  margin: 0;
}

.no-mrg {
  margin: 0;
}

/* vertical pannel wrap */

.vertical-pannel .vertical-pannel-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.vertical-pannel-wrap .left-wrap-panel {
  width: 175px;
}

.vertical-pannel-wrap .right-wrap-panel {
  width: calc(100% - 175px);
  border: 2px solid #cbd1d5;
  background: #fff;
  padding: 30px;
  border-radius: 0 4px 4px 4px;
  min-height: 500px;
}
.report-alarm-monitoring-save-button1 {
  margin-left: 78%;
}

.left-wrap-panel ul li {
  height: 56px;
  margin-bottom: 10px;
}

.left-wrap-panel ul li a {
  height: 56px;
  text-decoration: none;
  background: #fff;
  border: 1px solid #b71b1b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  color: #4c4c4c;
  font-size: 14px;
  line-height: 16px;
  margin-right: 12px;
}

.left-wrap-panel ul.nav-pills li a.nav-link {
  border-radius: 4px 0 0 4px;
}

.left-wrap-panel ul.nav-pills li a.nav-link.active {
  background: #fff;
  margin-right: -2px;
  color: #b71b1b;
  font-weight: 500;
  font-size: 16px;
  border-right: 0px;
  z-index: 1;
  position: relative;
  border: 2px solid #cbd1d5;
  border-right: 0;
}

.vertical-pannel-wrap {
  margin-top: 20px;
}

.report-inner-part input.form-control, .report-inner-part textarea.form-control {
  margin-bottom: 0;
}

.report-inner-part .form-group.dis-flex.f-dir-column {
  margin-bottom: 0;
}

.work-list-icon .list-icon-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.user-name-fld .user-name {
  width: 100%;
}

.work-list-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-justify-content: space-between;
  width: calc(100% - 30px);
  position: relative;
}

.work-list-icon .expand-icon {
  width: 20px;
  height: 20px;
}

.expand-icon.sprite-icon1 {
  width: 24px;
  height: 24px;
  background-position: -16px -60px;
  position: relative;
}
.imag-upload.sprite-icon2 {
  width: 24px;
  height: 24px;
  background-position: -16px -104px;
  position: relative;
  background: url(img/qr-icon.png);
  background-repeat: no-repeat;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-align-self: center;
}

.imag-upload.sprite-icon1 {
  width: 24px;
  height: 24px;
  background-position: -16px -104px;
  position: relative;
  background: url(img/camra-vr-2.png);
  background-repeat: no-repeat;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-align-self: center;
  cursor: pointer;
}

.note.sprite-icon1 {
  width: 24px;
  height: 24px;
  background-position: -16px -148px;
  position: relative;
}

.work-list-icon .imag-upload {
  width: 20px;
  height: 20px;
}

.work-list-icon .note {
  width: 20px;
  height: 22px;
}

.work-list-icon>div {
  margin-right: 15px;
  position: relative;
}

.list-icon-wrap>div {
  margin-right: 15px;
}

.work-list-icon .imag-upload:hover:before {
  content: '';
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: -7px;
}

.work-list-icon .imag-upload:hover:after {
  content: 'Image';
  width: 74px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
  z-index: 1;
}

.work-list-icon .note:hover:before {
  content: '';
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: -7px;
}

.report-alarm-monitoring-save-button {
  float: right;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {
  border-radius: 0.3rem;
  background-color: rgb(183, 27, 27);
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover {
  background-color: rgb(183, 27, 27);
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover{
  background-color: #B72E28;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range{
  background-color: #B72E28;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: rgb(183, 27, 27);
  color: white;
  font-weight: bold;
}

.CalendarDay__highlighted_calendar:active, .CalendarDay__highlighted_calendar:hover{
  background: #B72E28;
}

.CalendarDay__highlighted_calendar{
  background: #B72E28;
}

.CalendarDay__default:hover{
  border: none;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: rgb(183, 27, 27);
}
.dotted {border: 2px dotted black; border-style: none none dotted; color: #fff; background-color: #fff;
margin-top: 25px;
margin-bottom: 25px; }
.isGroupIcon {
  background-color: rgb(183, 27, 27);
  border-radius: 50%;
  width: 23px;
  text-align: center;
  height: 23px;
  color: white;
  position: absolute;
  right: 0;
  top: 5px;
  line-height: 25px;
}

.isGroupIcon:hover {
  cursor: pointer;
}

.isGroupIcon:hover:before {
  content: '';
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -6px;
  left: -6px;
}

.isGroupIcon:hover:after {
  content: 'Group';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.work-list-icon .note:hover:after {
  content: 'Add Note';
  width: 74px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.right-wrap-panel .read-more {
  margin-top: -18px;
}

.tab-text-head {
  font-size: 16px;
  color: #b71b1b;
  line-height: 19px;
}

hr.seperator {
  border-top: 1px dashed #979797;
  margin-top: 30px;
  margin-bottom: 30px;
}

.signature-btn-action button.btn.btn_dark {
  max-width: 88px;
  min-width: 82px;
  margin-right: 10px;
}

.signature-btn-action button.btn.btn_success {
  min-width: 82px;
  max-width: 88px;
}

.signature-btn-action {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.signature-sec {
  position: relative;
  width: 100%;
}

.head-seperation .seperate-line {
  padding: 0 22px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-lign-items: center;
  -webkit-align-self: center;
}

/* vertical icons starts*/

.vertical-bar {
  background: transparent;
  position: fixed;
  bottom: 10px;
  right: 0;
  z-index: 1000;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

/* vertical icons ends */

.monitor-radio label {
  margin-right: 90px;
}

.monitor-radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-display: flex;
  margin-top: 36px;
}

.monitor-notes {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  min-height: 22px;
}

.report-inner-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-flex;
  -webkit-justify-content: space-between;
  position: relative;
}

.hide.sprite-icon-before:before {
  width: 26px;
  height: 20px;
  background-position: 109px -533px;
  left: -21px;
  top: -2px;
  position: absolute;
}

.Notes.sprite-icon-before:before {
  background: url(img/edit-notes.png);
  background-repeat: no-repeat;
  background-size: 23px;
  position: absolute;
}

.material-icons.md-36 { font-size: 36px; }

.hide.sprite-icon-before, .Notes.sprite-icon-before, .eye-hide.sprite-icon-before {
  padding-left: 10px;
  margin-left: 15px;
  margin-right: 20px;
}
.report-inner-part .dis-flex {
  width: 100%;
}

.Na .cust-checkbox {
  color: #000;
}

.option-check {
  position: absolute;
  right: 24px;
  top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.option-check1 {
  position: absolute;
  right: 18px;
  top: -2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.option-check1 .info-icon.sprite-icon-before:before {
  background-position: -58px -481px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.note-edit.sprite-icon-before:before {
  background-position: 144px -296px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: -20px;
  cursor: pointer;
}

.info-icon.sprite-icon-before:before {
  background-position: -58px -481px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 20px;
  top: -24px;
  cursor: pointer;
}

.note-edit.sprite-icon-before {
  margin-left: 40px;
}

.option-check .cust-checkbox {
  color: #000;
}

.left-lable-gp .custom-select+label {
  padding-right: 100px;
}

.superviion-date {
  border: 1px solid #cbd1d5;
  background-color: #ffffff;
  padding: 24px;
  margin-bottom: 15px;
  position: relative;
}

.dlt-save-btn button:first-child {
  margin-right: 18px;
}

.btn-group-section {
  margin-top: 20px;
}

.accordian2 .texted {
  position: absolute;
  top: -15px;
  right: -110px;
  color: #6aa14e;
  font-weight: 500;
}

.accordian2 .texted:after {
  content: '';
  width: 2px;
  height: 20px;
  background: #979797;
  position: absolute;
  left: -44px;
  top: 12px;
}

.accordian2 .texted:before {
  position: absolute;
  width: 20px;
  height: 20px;
}

.accordian2 .texted.sprite-icon-before:before {
  width: 22px;
  height: 22px;
  position: absolute;
  left: -26px;
  top: 11px;
  background-position: 51px -90px;
}

.accordian2 .deleted.sprite-icon-before {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 8px;
  cursor: pointer;
}

.accordian2 .deleted.sprite-icon-before:before {
  width: 20px;
  height: 20px;
  background-position: -18px -310px;
  position: absolute;
}

.device-tab-text {
  max-width: 420px;
  margin-bottom: 4px;
}

label .note-edit.sprite-icon-before {
  position: absolute;
  right: 0;
  top: 0;
}

/* inventory css */

.inventory-finter-group .parts-group-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 498px;
  overflow-x: auto;
}

.parts-group-btn button:first-child {
  margin-right: 12px;
}

.parts-group-btn+.filter-row>button:first-child {
  margin-right: 20px;
}

span.table-image {
  width: 48px;
  height: 48px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  top: 2px;
}

span.table-image img {
  max-width: 100%;
}

.parts-table.global-table .table tbody tr td table tbody tr td:first-child {
  width: 10%;
}

.parts-table.global-table .table tbody tr td table tbody tr td:nth-child(2) {
  width: 15%;
}

.parts-table.global-table .table tbody tr td table tbody tr td:nth-child(3) {
  width: 15%;
}

.parts-table.global-table .table tbody tr td table tbody tr td:nth-child(4) {
  width: 12%;
}

.parts-table.global-table .table tbody tr td table tbody tr td:nth-child(5) {
  width: 15%;
}

.parts-table.global-table .table tbody tr td table tbody tr td:nth-child(6) {
  width: 16%;
}

.parts-table.global-table .table tbody tr td table tbody tr td:nth-child(7) {
  width: 15%;
}

.parts-table.global-table .table thead tr th:first-child {
  width: 10%;
}

.parts-table.global-table .table thead tr th:nth-child(2) {
  width: 15%;
}

.parts-table.global-table .table thead tr th:nth-child(3) {
  width: 15%;
}

.parts-table.global-table .table thead tr th:nth-child(4) {
  width: 12%;
}

.parts-table.global-table .table thead tr th:nth-child(5) {
  width: 15%;
}

.parts-table.global-table .table thead tr th:nth-child(6) {
  width: 16%;
}

.parts-table.global-table .table thead tr th:nth-child(7) {
  width: 15%;
}

.in-stock {
  color: #6aa14e;
  font-size: 14px;
  font-weight: 500;
}

.service {
  color: #1786fe;
  font-size: 14px;
  font-weight: 500;
}

.AddedStock {
  color: #e76c04;
  font-size: 14px;
  font-weight: 500;
}

.out-stock {
  color: #b71b1b;
  font-size: 14px;
  font-weight: 500;
}

.info-head {
  font-size: 16px;
  font-weight: 500;
  color: #4c4c4c;
  margin-bottom: 20px;
}

span.info-tooltip {
  width: 98px;
  display: inline-block;
  padding: 7px;
  font-size: 12px;
  line-height: 12px;
  background: #4c4c4c;
  border-radius: 3px;
  color: #fff;
  position: absolute;
  top: -46px;
  right: 0;
}

span.info-tooltip:before {
  content: '';
  position: absolute;
  border-top: 8px solid #4c4c4c;
  border-left: 8px solid transparent;
  border-bottom: 8px solid transparent;
  top: 38px;
  border-right: 4px solid transparent;
  right: 8px;
}

.custom-select.select-drop-btn {
  min-width: 170px;
}

.custom-select.select-drop-btn .select-selected {
  border: 1.5px solid #6aa14e;
  color: #6aa14e;
  text-align: center;
}

span.date-of-create {
  font-weight: normal;
}

span.date-of-create span {
  font-weight: 500;
}

.tale-part-search p {
  margin: 0;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-align-self: center;
  position: relative;
}

.expand.sprite-icon-before {
  position: static;
}

.expand.sprite-icon-before:before {
  height: 20px;
  width: 20px;
  position: absolute;
  background-position: 53px -239px;
  top: 0;
  right: -30px;
}

.expand.srite-icon-before:before {
  width: 20px;
  height: 20px;
  position: absolute;
  background-position: 0 0;
  content: '';
}

.part-info-icon {
  position: relative;
  display: inline-block;
}

.info-part-icon.sprite-icon-before:before {
  height: 20px;
  width: 20px;
  position: absolute;
  background-position: -58px -481px;
  top: 0;
  right: -30px;
  cursor: pointer;
}

.info-part-icon.sprite-icon-before {
  position: static;
}

.global-sm-table button.clone.btn {
  -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
  border-radius: 4px;
  min-height: 20px;
  height: 20px;
  border: 1px solid #6aa14e;
  font-weight: normal;
  color: #6aa14e;
  padding: 0px 10px;
  font-size: 12px;
}

.tab-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  padding-left: 0;
  margin: 0;
}

.tab-list ul li:last-child {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid #cbd1d5;
}

.tab-list ul li:first-child {
  border-radius: 4px 0 0 4px;
  border-left: 1px solid #cbd1d5;
}

.tab-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-flex;
  -webkit-justify-content: space-between;
}

.tab-list button {
  padding: 0 15px;
  min-width: auto;
}

.tab-list ul li {
  padding: 10px 16px;
  border: 1px solid #cbd1d5;
  border-right: 0;
  border-left: 0;
  position: relative;
  height: 40px;
  /* min-width: 120px; */
  font-size: 14px;
  text-align: center;
  color: #4c4c4c;
  background: #fff;
  /* max-width: fit-content;
  width: -webkit-fill-available; */
  white-space: nowrap;
}

.tab-list ul li:before {
  width: 1px;
  height: 27px;
  background: #cbd1d5;
  position: absolute;
  right: -1px;
  top: 6px;
  content: '';
  z-index: 1;
}

.tab-list ul li.active:before {
  width: 0;
}

.tab-list ul li:last-child:before {
  width: 0;
}

.tab-list ul li.active {
  background: #b71b1b;
  color: #fff;
  font-weight: 500;
  border: 1px solid #b71b1b;
  z-index: 8;
}

.service-due-list ul {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
}

.service-due-list ul li:nth-child(3n+0) {
  margin-right: 0;
  width: calc(33.333% - 0px);
}

.service-due-list ul li {
  border: 1px solid #cbd1d5;
  color: #4c4c4c;
  background: #fff;
  height: 55px;
  width: 237px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  margin-right: 15px;
  margin-bottom: 15px;
  font-size: 14px;
  width: calc(33.333% - 15px);
  text-align: center;
}

.service-due-list ul li p {
  margin: 0;
}

.service-due-list ul li p span {
  font-weight: 500;
  padding-left: 6px;
  display: inline-block;
}

.service-due-section>p {
  font-size: 12px;
  color: #4c4c4c;
  font-weight: 500;
}

.due-service-for p {
  margin: 0;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-align-self: center;
  color: #4c4c4c;
  font-size: 12px;
  font-weight: 500;
}

lable.img-label {
  color: rgba(76, 76, 76, 0.76);
  font-size: 14px;
  padding-bottom: 8px;
  display: inline-block;
}

.btn-rt-align {
  text-align: right;
}

.btn-rt-align button {
  margin-left: 20px;
}

.global-table.quote-table .table tbody tr td table tbody tr td:last-child {
  text-align: left;
  padding-left: 20px;
}

.global-table.quote-table .table thead tr th:last-child {
  text-align: left;
}

.total-part-labor {
  border: 1px solid #cbd1d5;
  border-radius: 4px;
  height: 30px;
  display: inline-block;
}

.total-part-labor .total-text {
  background: #b71b1b;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 16px;
  line-height: 30px;
  height: 30px;
  border-radius: 4px 0 0 4px;
  color: #fff;
}

.total-part-labor .totap-rice {
  font-size: 14px;
  color: #4c4c4c;
  padding: 5px 40px;
  text-align: center;
}

span.drive.sprite-icon-before:before {
  position: absolute;
  width: 24px;
  height: 24px;
  background-position: -456px -472px;
  left: -13px;
  top: 7px;
  background: #4DBCE9;
  border-radius: 50%;
  background-image: url(img/car-vr-2.png);
  background-size: 64%;
  background-repeat: no-repeat;
  background-position: center;
}

span.break.sprite-icon-before:before {
  position: absolute;
  width: 24px;
  height: 24px;
  background-position: -487px -472px;
  left: -13px;
  top: 6px;
  background: #b71b1b;
  border-radius: 50%;
  background-image: url(img/setting-vr-2.png);
  background-size: 64%;
  background-repeat: no-repeat;
  background-position: center;
}

span.working.sprite-icon-before:before {
  position: absolute;
  width: 24px;
  height: 24px;
  background-position: -457px -503px;
  left: -13px;
  top: 6px;
  background: #41ba1a;
  border-radius: 50%;
  background-image: url(img/cafe-vr-2.png);
  background-size: 62%;
  background-repeat: no-repeat;
  background-position: center;
}

span.checkout.sprite-icon-before:before {
  position: absolute;
  width: 24px;
  height: 24px;
  background-position: -488px -503px;
  left: -13px;
  top: 6px;
  background: #A1A1A1;
  border-radius: 50%;
  background-image: url(img/clockout.png);
  background-size: 66%;
  background-repeat: no-repeat;
  background-position: center;
}

/* span.checkout.sprite-icon-before:after{
  content: "";
  width: 14px;
  height: 14px;
  background: url(../img/clockout.png);
} */

span.miscellaneous.sprite-icon-before:before {
  position: absolute;
  width: 24px;
  height: 24px;
  background-position: -456px -532px;
  left: -13px;
  top: 6px;
  background: rgb(244, 207, 73);
  border-radius: 50%;
  background-image: url(img/miscellaneous-vr-2.png);
  background-size: 66%;
  background-repeat: no-repeat;
  background-position: center;
}

span.working {
  color: #41ba1a;
  font-weight: 500;
}

span.checkout {
  color: #ababab;
  font-weight: 500;
}

span.miscellaneous {
  color: #f4cf49;
  font-weight: 500;
}

span.break {
  color: #b71b1b;
  font-weight: 500;
}

span.drive {
  color: #4dbce9;
  font-weight: 500;
}

span.status-arrow:after {
  content: '';
  position: absolute;
  background: url(img/sprite.png);
  background-size: 600px;
  background-position: -76px -27px;
  width: 12px;
  height: 12px;
  top: 2px;
  right: -16px;
}

span.time-icon.sprite-icon1 {
  display: inline-block;
  /* width: 40px; */
  width: 18px;
  /* height: 40px; */
  height: 18px;
  background-position: -222px 0px;
  position: absolute;
  right: 70px;
  top: 21px;
  cursor: pointer;
  background: url(img/time-vr-2.png);
  background-repeat: no-repeat;
}

.time-icon:hover:before {
  content: '';
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -8px;
  left: -9px;
}

.time-icon:hover:after {
  content: 'Time';
  width: 66px;
  height: 25px;
  background: #4c4c4c;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
  display: none;
}

/* team card css */

.team-card .team-card-head {
  height: 68px;
  padding: 25px 30px;
  background-color: #292c36;
}

.team-card .team-card-head p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.team-card .team-card-body {
  border: 1px solid #4c4c4c;
}

.team-card-inn-body>ul>li {
  position: relative;
  padding-left: 80px;
  padding-top: 7px;
  padding-bottom: 40px;
}

.team-card-inn-body.force-overflow-edit-team>ul>li {
  padding-top: 6px;
}

.team-card-inn-body>ul>li.active-checked {
  padding-bottom: 6px;
}

.team-card-inn-body>ul>li:last-child {
  padding-bottom: 0;
}

.team-card-inn-body>ul>li:before {
  content: '';
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 25px;
  background: #4c4c4c;
}

.team-card-inn-body>ul>li:last-child:before {
  width: 0;
}

.team-card-inn-body>ul>li.active-checked:before {
  background: #b71b1b;
  width: 2px;
}

.team-card-inn-body ul>li .steps {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #fff;
  border: 1px solid #4c4c4c;
  border-radius: 50%;
  color: #4c4c4c;
  font-size: 24px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  left: 0;
  top: 0;
}

.team-card-inn-body ul>li.active .steps {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #b71b1b;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  left: 0;
  top: 0;
  border: none;
}

.team-card-inn-body ul>li.active-checked .steps {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  color: #b71b1b;
  font-size: 24px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  left: 0;
  top: 0;
  border: 1.5px solid #b71b1b;
}

.team-card-inn-body {
  padding: 30px;
  background: #fff;
}

.team-card-inn-body ul {
  padding-left: 0;
  margin-bottom: 0;
}

.team-card-inn-body .step-head {
  font-size: 16px;
  font-weight: 500;
  color: #b71b1b;
  line-height: 19px;
  margin-bottom: 30px;
}

.team-card-inn-body hr.seperator {
  border-top: 1px dashed #979797;
  margin-top: 30px;
  margin-bottom: 30px;
  position: absolute;
  bottom: 18px;
  left: 80px;
  width: calc(100% - 80px);
  padding: 0;
  margin: 0;
}

.line-ht label {
  line-height: 18px;
}

.team-card-inn-body .c-checkboc {
  position: absolute;
  right: 0;
}

.step-checks ul {
  padding-left: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
}

.step-checks ul li {
  width: 48%;
  margin-bottom: 30px;
}

.week-date {
  position: absolute;
  right: 0;
  top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-align-items: center;
}

.week-date>span {
  padding-right: 12px;
}

.date-show-feild {
  border: 1px solid #CBD1D5;
  height: 40px;
  background: #f2f2f2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  color: #4c4c4c;
  padding: 5px 75px 5px 15px;
  border-radius: 4px;
}

.date-show-feild.sprite-icon-before:before {
  position: absolute;
  background-position: -30px -443px;
  width: 25px;
  height: 24px;
  right: 10px;
  cursor: pointer;
}

.card-table-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.card-table-head .table-th {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  -webkit-flex: 1;
  -webkit-text-align: center;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-self: center;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  border-bottom: 1px solid #4c4c4c;
  border-right: 1px solid #4c4c4c;
}

.card-table-head .table-th:last-child {
  border-right: 0;
}

.time-card-table {
  border: 1px solid #4c4c4c;
  border-radius: 4px;
}

.rdr-DateRange {
  border: 1px solid #c4c4c4;
  z-index: 100;
  position: absolute;
  top: 45px;
  left: -186px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07);
          box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07);
  border-radius: 3px;
}

.card-tbody-tr .table-td {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-left: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  color: #4c4c4c;
  position: relative;
}

.card-tbody-tr:last-child .table-td {
  border-bottom: none;
}

.card-tbody-tr .table-td:first-child {
  border-left: 0;
}

.card-tbody-tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.table-td.red-td-bg {
  background: #b71b1b;
  color: #fff;
}

.gryth-bg {
  background: rgba(239, 239, 239, 0.5);
  font-weight: 500;
}

.card-table-head .table-th:first-child {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-justify-content: flex-start;
  padding-left: 15px;
}

.card-tbody-tr .table-td:first-child {
  padding-left: 15px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-justify-content: flex-start;
}

.daytask1 {
  font-weight: 500;
}

.Driving1 {
  color: #4dbce9;
  font-weight: 500;
}

.break1 {
  color: #41ba1a;
  font-weight: 500;
}

.workin1 {
  color: #b71b1b;
  font-weight: 500;
}

.checkout1 {
  color: #ababab;
  font-weight: 500;
}

.miscellaneous1 {
  color: #f4cf49;
  font-weight: 500;
}

.emplyee-list-rt {
  position: fixed;
  right: 0;
  top: 64px;
  width: 525px;
  background: white;
}

.emplyee-list-rt .team-card .team-card-head {
  height: 40px;
  padding: 10px 20px;
}

.emplyee-list-rt .team-card-inn-body {
  padding: 19px;
  background: #fff;
}

.team-card-body .global-table .table tbody tr td table tbody tr td {
  width: 34%;
}

.team-card-body .global-table .table tbody tr td table tbody tr td:last-child {
  width: 10%;
}

.team-card-body .global-table .table thead tr th {
  width: 26%;
}

.team-card-body .global-table .table thead tr th:nth-child(2) {
  width: 18%;
}

.team-card-body .global-table .table thead tr th:last-child {
  width: 10%;
}

.team-card-body .global-table .table thead tr th:first-child {
  padding-left: 0px;
  text-align: left;
}

.team-card-body .global-table .table tbody tr td table tbody tr td:first-child {
  padding-left: 12px;
  text-align: left;
}

.team-card-body .global-sm-table .update-icon {
  position: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  background-size: 600px;
  background-position: -445px 31px;
  width: 21px;
  height: 20px;
  background: url(img/restore-vr-2.png);
  background-repeat: no-repeat;
}

.team-card-body .global-table .table tbody tr td table tbody tr td:nth-child(2) {
  width: 18%;
}

.emplyee-list-rt .team-card {
  border-radius: 3px;
  overflow: hidden;
}

.time-card-section .time-card {
  font-size: 17px;
  margin-bottom: 20px;
}

.time-card-section .week-date {
  top: -60px;
}

p.time-card.sprite-icon-before:before {
  background-position: -60px -483px;
  position: absolute;
  width: 16px;
  height: 16px;
  right: -24px;
  top: 1px;
}

.cus-lg-table .card-table-head .table-th {
  height: 44px;
}

.cus-lg-table .card-tbody-tr .table-td {
  height: 48px;
}

.ant-popover-placement-bottom {
  top: 200px !important;
}

.ant-fullcalendar-calendar-body {
  padding-top: 15px !important;
}

.ant-fullcalendar-header {
  margin-top: 0px;
}

.cus-lg-table.time-card-table {
  border: 1px solid #4c4c4c;
  border-radius: 3px;
  border-bottom-right-radius: 0;
  background: #fff;
}

.table-td.gryth-bg.total-amnt {
  position: absolute;
  width: 101%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 48px;
  top: 48px;
  background-color: #D8D8D8;
  border: 1px solid #4c4c4c;
  border-top: none;
  border-radius: 0 0 3px 3px;
  margin-bottom: 20px;
  right: -1px;
}

.custom-select.bg-select-w {
  background: #fff;
}

.bg-select-w .select-selected {
  background: #fff;
}

/* quaterly modal css */

.quaterly-wrap-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.quaterly-wrap-modal .quaterly-left, .quaterly-wrap-modal .quaterly-right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-flex: 1;
  padding-left: 24px;
}

.job-id-no {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.job-id-no input {
  margin-left: 12px;
  border: 1px solid #cbd1d5;
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  height: 30px;
  padding: 0 16px;
  width: calc(100% - 176px);
  background: #f2f2f2f2;
  outline: none;
}

.quaterly-right {
  position: relative;
}

.quaterly-right:before {
  content: '';
  width: 2px;
  height: -webkit-fill-available;
  /* background: #979797; */
  position: absolute;
  border-left: 1px dashed #979797;
  top: -10px;
  left: -32px;
  max-height: inherit;
}

.quaterly-right .ofc-no.sprite-icon-before {
  margin-bottom: 14px;
}

.quaterly-left .user-address-fld-inn .user-icon1.ofc-no.sprite-icon-before:before {
  width: 12px;
  height: 18px;
  background-position: 52px -431px;
  position: absolute;
  left: -20px;
}

.job-id-no {
  margin: 14px 0;
  margin-left: -18px;
}

.job-id-no p {
  margin: 0;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-align-self: center;
}

#job-link hr.seperator {
  border-top: 2px dashed #c4c4c4;
  margin-top: 30px;
  margin-bottom: 30px;
  width: calc(100% + 48px);
  margin-left: -24px;
}

.job-link-date-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.job-link-date-wrap .job-link-date-left, .job-link-date-wrap .job-link-date-right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-flex: 1;
}

.job-link-date-wrap .job-link-date-left {
  margin-right: 40px;
}

p.date-head {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.select-time-wrap>div {
  margin-right: 10px;
  min-width: 100px;
}

.select-time-wrap>div:last-child {
  margin-right: 0;
}

.start-time-head {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-top: 20px;
}

.select-time-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.small-text-msg {
  color: #4C4C4C;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.search-chip .cust-checkbox {
  color: #4dbce9;
}

.search-chip .cust-checkbox .checkmark {
  top: 4px;
}

.search-chip .cust-checkbox input:checked~.checkmark {
  top: 4px;
}

.check-clips-wrap .search-chip {
  margin-right: 5px;
}

.job-link-date-wrap .check-clips-wrap .search-chip {
  margin-top: 8px;
}

.check-clips-wrap.job-pop-clip {
  margin-top: 10px;
}

.job-id-note {
  height: 60px;
  background: #f5f5f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  padding-left: 24px;
  width: calc(100% + 48px);
  margin-left: -24px;
  -webkit-transform: rotate();
          transform: rotate()
}

.job-id-note p {
  color: #000;
  font-size: 16px;
  /* text-transform: uppercase; */
  margin: 0;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-align-self: center;
  font-weight: 500;
}

.job-id-note p.sprite-icon-before:before {
  width: 20px;
  height: 20px;
  background-position: -77px 556px;
  position: absolute;
  right: -30px;
  top: 3px;
}

.job-id-note.open p.sprite-icon-before:before {
  background-position: -95px 556px;
}

.quaterly-wrap-modal .quaterly-right {
  position: relative;
}

.quaterly-wrap-modal .address-icon.sprite-icon1 {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 16px;
  bottom: auto;
  background-position: 0px -1px;
  top: 55px;
}

.modal {
  overflow: auto;
}

p.email-id-icon.sprite-icon-before:before {
  width: 20px;
  height: 20px;
  background-position: -32px -474px;
  position: absolute;
  right: 64px;
  top: -2px;
  cursor: pointer;
}

.resource-popup {
  max-width: 152px;
}

.resource-popup .resource-header {
  background-color: #292C36;
  height: 47px;
  width: 152px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-flex;
  -webkit-justify-content: space-between;
  padding: 0 8px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-items: center;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.resource-popup .resource-header:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #292C36;
  position: absolute;
  top: 14px;
  left: -8px;
}

.resource-popup .resource-header p {
  margin: 0
}

.resource-popup ul li {
  height: 33px;
  padding: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
      align-self: center;
  display: -webkit-flex;
  -webkit-align-self: center;
  line-height: 33px;
  font-size: 14px;
  color: #4c4c4c;
  position: relative;
}

.resource-popup ul li p {
  margin: 0;
}

.resource-popup ul {
  padding-top: 4px;
  background: #fff;
  padding-bottom: 4px;
}

.resource-popup ul li:hover {
  background: #EFEFEF;
}

.driving .joblink-icon1 {
  top: 0px;
  right: 0px;
  background-position: 143px -470px;
  width: 20px;
  height: 20px;
  background: #52b1ec;
  border-radius: 50%;
  position: relative;
}

.driving .joblink-icon1:before {
  content: "";
  background-image: url(img/car-vr-2.png);
  width: 15px;
  height: 11px;
  position: absolute;
  left: 3px;
  top: 5px;
  background-size: cover;
}

.working .joblink-icon1 {
  top: 0px;
  right: 0px;
  background-position: 110px -473px;
  width: 20px;
  height: 20px;
  background: #bb2f2a;
  border-radius: 50%;
  position: relative;
}

.working .joblink-icon1:before {
  content: "";
  background-image: url(img/setting-vr-2.png);
  width: 13px;
  height: 12px;
  position: absolute;
  left: 4px;
  top: 4px;
  background-size: cover;
}

.break .joblink-icon1 {
  top: 0px;
  right: 0px;
  background-position: 143px -501px;
  width: 20px;
  height: 20px;
  background: #6AA14E;
  border-radius: 50%;
  position: relative;
}

.break .joblink-icon1:before {
  content: "";
  background-image: url(img/cafe-vr-2.png);
  width: 13px;
  height: 12px;
  position: absolute;
  left: 4px;
  top: 4px;
  background-size: cover;
}

.checkout .joblink-icon1 {
  top: 0px;
  right: 0px;
  background-position: 112px -501px;
  /* width: 24px;
    height: 24px; */
  width: 20px;
  height: 20px;
  background: #A1A1A1;
  border-radius: 50%;
  position: relative;
}

.checkout .joblink-icon1:before {
  content: "";
  background-image: url(img/clockout.png);
  width: 14px;
  height: 14px;
  position: absolute;
  left: 3px;
  top: 3px;
  background-size: cover;
}

.miscellaneous .joblink-icon1 {
  top: 0px;
  right: 10px;
  background-position: 141px -533px;
  width: 20px;
  height: 20px;
}

.resource-popup .resource-header .close-icon {
  font-size: 20px;
  font-weight: normal;
  position: relative;
  top: -2px;
  cursor: pointer;
}

/* Team Dropdown */

#status-selected span:before {
  position: absolute;
  content: "";
  top: 11px;
  right: -1px;
  width: 11px;
  height: 8px;
  background: url('/img/sprite.png');
  background-size: 600px;
  background-position: -77px 570px;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.status-select .custom-select.status-report-select .select-selected:after {
  display: none;
}

/* change Search Bar design in mobi;le */

.top-search-field>.search-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: webkit-flex;
}

.show-in-mobile-time-sheet-table {
  display: none;
}

.note-description-area .dis-flex.form-group {
  margin-bottom: 4px;
}

.add-note, .add-note:hover {
  font-size: 16px;
  color: #DD231D;
  font-weight: 500;
  padding-bottom: 4px;
  display: inline-block;
  text-decoration: none;
}

.add-note .plus {
  font-size: 22px;
  display: inline-block;
  font-weight: 400;
  padding-right: 6px
}

.note-description-area textarea.form-control {
  height: 54px;
  font-size: 14px;
  color: #4c4c4c;
  line-height: 19px;
  padding-right: 30px;
}

.note-description-area {
  background: #f5f5f5;
  width: calc(100% + 48px);
  margin-left: -24px;
  padding: 0 24px;
  /* height: 198px; */
  overflow-y: auto;
}

.note-description-area .textarea-wrap {
  position: relative;
}

.note-description-area span.dlt-icon.sprite-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 15px;
  top: 10px;
  background-position: 36px -33px;
  background-size: 400px;
}

.scheduler-view .errorMessage {
  text-align: center;
  margin-left: -240px;
  margin-top: 20px;
}

.job-link-table::-webkit-scrollbar {
  display: none;
}

.job-link-table {
  overflow-x: hidden;
  margin-top: -70px;
}

.job-libk-loader {
  margin-top: -142px;
}

#RBS-Scheduler-root .ant-radio-group.ant-radio-group-outline.ant-radio-group-default {
  display: none;
}

#RBS-Scheduler-root {
  position: relative;
}

#RBS-Scheduler-root .ant-row-flex .ant-col {
  position: absolute;
  top: -66px;
  left: calc(50% - 167px);
}

.ant-col .header2-text .header2-text-label {
  font-size: 24px;
  color: #4c4c4c;
  font-weight: 300;
}

.ant-col .header2-text .icon-nav {
  font-size: 24px;
  color: #4c4c4c !important;
  font-weight: 300;
  top: -4px;
  position: relative;
}

.calender-section .dropdown.custom-drop-option {
  margin-left: 12px;
}

.calender-section .select-items .selecthover {
  height: auto;
}

.team-card-inn-body .select-items .selecthover {}

.team-card-inn-body .custom-select .select-items {
  width: 215px;
}

.team-card-inn-body .custom-select .select-items .selecthover {
  width: 198px;
  height: auto;
}

.resource-table .selecthover {
  height: auto;
}

.job-link-date-wrap .selecthover {
  height: auto;
}

.SingleDatePicker_picker {
  z-index: 9;
}

#RBS-Scheduler-root {
  position: relative;
}

#RBS-Scheduler-root .ant-row-flex .ant-col {
  position: absolute;
  top: -66px;
  left: calc(50% - 200px);
}

.ant-col .header2-text .header2-text-label {
  font-size: 24px;
  color: #4c4c4c;
  font-weight: 300;
}

.ant-col .header2-text .icon-nav {
  font-size: 24px;
  color: #4c4c4c !important;
  font-weight: 300;
  top: -4px;
  position: relative;
}

.calender-section .dropdown.custom-drop-option {
  margin-left: 12px;
}

.calender-section .select-items .selecthover {
  height: auto;
}

.team-card-inn-body .select-items .selecthover {}

.team-card-inn-body .custom-select .select-items {
  width: 215px;
}

.team-card-inn-body .custom-select .select-items .selecthover {
  width: 198px;
  height: auto;
}

.resource-table .selecthover {
  height: auto;
}

.job-link-date-wrap .selecthover {
  height: auto;
}

.SingleDatePicker_picker {
  z-index: 9;
}

.note-description-area label {
  display: block;
}

#RBS-Scheduler-root .round-tail.event-item, #RBS-Scheduler-root .round-head.event-item, #RBS-Scheduler-root .round-none.event-item {
  height: 50px !important;
  background: #fff !important;
  color: #4c4c4c;
  font-size: 14px;
  font-weight: 500;
  border-top: 4px solid #B71B1B;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding-top: 4px;
}

.check-clips-wrap.job-pop-clip .search-chip {
  margin-bottom: 8px;
}

#job-link .user-address-fld-inn .ofc-no p span, #job-link .user-address-fld-inn .ofc-cell-no p span, #job-link .user-address-fld-inn .ofc-addr p span {
  word-break: normal;
  width: 165px;
  display: inline-block;
}

#job-link .user-address-fld-inn .ofc-no p, #job-link .user-address-fld-inn .ofc-cell-no p, #job-link .user-address-fld-inn .ofc-addr p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}

.slot-text {
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 118px;
}

.schedule-table-tooltip:hover .tooltip-part {
  display: block;
  position: absolute;
  display: inline-block;
  background: #4c4c4c;
  text-align: left;
  top: 68px;
  left: -80px;
  padding: 8px;
  border-radius: 4px;
  /* border-top-right-radius: 0; */
  color: #fff;
  z-index: 9;
}

.schedule-table-tooltip:hover .tooltip-part:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #4c4c4c;
  position: absolute;
  top: -8px;
  right: 10px;
}

p.tooltip-part {
  display: none;
}

.schedule-table-tooltip ul {
  padding-left: 0;
}

.schedule-table-tooltip:hover {
  position: relative!important;
}

.schedule-table-tooltip {
  position: relative !important;
}

.global-table .table tbody tr td table tbody tr td:first-child span {
  width: 150px;
  display: inline-block;
}

.desktop-hide {
  display: none;
  background: #ffffff;
  padding: 16px;
}

#RBS-Scheduler-root .ant-popover-inner-content {
  padding: 0;
}

#RBS-Scheduler-root a {
  color: #fff;
}

.driving-parent, .working-parent, .miscellaneous-parent, .checkout-parent, .break-parent {
  position: relative;
}

.driving-child, .working-child, .miscellaneous-child, .checkout-child, .break-child {
  position: absolute;
  right: 0px;
  background-color: #52b1ec;
  height: 100%;
  width: 50px;
  top: 0px;
  cursor: pointer;
}

.working-child {
  background-color: #bb2f2a;
}

.miscellaneous-child {
  background-color: #eede36;
}

.checkout-child {
  background-color: #a8a8a8;
}

.break-child {
  background-color: #5baf4f;
}

.resource-table tbody tr:first-child td .driving-child, .resource-table tbody tr:first-child td .working-child, .resource-table tbody tr:first-child td .miscellaneous-child, .resource-table tbody tr:first-child td .checkout-child, .resource-table tbody tr:first-child td .break-child {
  height: 100%;
}

.resource-view, .resource-view>div, .resource-view>div>div {
  overflow: visible !important;
}

.resource-view:last-child div {
  padding-bottom: 0px !important;
}

#RBS-Scheduler-root .resource-table .header3-text {
  padding-left: 0px;
}

.resource-table .select-selected {
  border: none;
  background: #fff;
  border-radius: 0;
}

.resource-table .status-list {
  position: absolute;
  right: -205px;
  top: 0;
  z-index: 9;
  background: #fff;
  width: 195px;
  -webkit-box-shadow: 0px 2px 3px 0px #ccc;
          box-shadow: 0px 2px 3px 0px #ccc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.status-list-header {
  background: #292c36;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
}

span.sprite-icon.close-arrow {
  position: absolute;
  background-position: -20px -679px;
  width: 14px;
  height: 14px;
  right: 8px;
  background-size: 400px;
  cursor: pointer;
}

.status-list-header:before {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #292c36;
  content: '';
  left: -10px;
  position: absolute;
}

.resource-table #change-status-modal ul li p {
  color: #4c4c4c;
  font-weight: 500;
}

.driving-icon, .working-icon, .miscellaneous-icon, .checkout-icon, .break-icon {
  background-image: url(img/driving-icon.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: center;
}

.driving-icon {
  background-image: url(img/car-vr-2.png);
  background-repeat: no-repeat;
  background-position: center;
}

.driving-icon .joblink-icon1:before {
  content: "";
  background-image: url(img/car-vr-2.png);
  width: 13px;
  height: 12px;
  position: absolute;
  left: 4px;
  top: 4px;
  background-size: cover;
}

.working-icon {
  background-image: url(img/setting-vr-2.png);
}

.miscellaneous-icon {
  background-image: url(img/miscellaneous.png);
}

.checkout-icon {
  /* background-image: url(../img/checkout.png); */
  background: url(img/clockout.png);
  background-repeat: no-repeat;
  background-position: center;
}

.break-icon {
  /* background-image: url(../img/break.png); */
  /* background: #6AA14E; */
  background: url(img/cafe-vr-2.png);
  background-repeat: no-repeat;
  background-position: center;
}

.info-timecard-info-show-descr {
  width: 335px;
  height: auto;
  background: #337AB7;
  border-radius: 3px;
  color: white;
  font-size: 13px !important;
  font-weight: normal;
  line-height: 20px;
  position: absolute;
  top: -4px;
  left: 102px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
  padding: 8px;
  z-index: 1000;
}

.coachmark-tooltips {
  width: auto;
  padding: 8px 16px;
  pointer-events: none;
  text-align: center;
}

.info-timecard-info-show-descr::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #337AB7 transparent transparent;
}

.tooltip-triangle-right::after {
  right: unset;
  left: 100%;
  border-color: transparent transparent transparent #337AB7;
}

.tooltip-triangle-top::after {
  right: unset;
  left: 50%;
  top: -5px;
  border-color: transparent transparent #337AB7 transparent;
}

.tooltip-triangle-bottom::after {
  right: unset;
  left: 50%;
  top: 103%;
  border-color: #337AB7 transparent transparent transparent;
}

.icon-image-preview {
  font-size: 30px;
  margin-top: -3px;
}
.info-system-info-show-descr.inventory-responsive-msg {
  top: 0px;
  left: 0px;
  height: auto;
  margin-bottom: 10px;
  width: 100%;
  text-align: -webkit-center;
  display: none;
}
/* @media (min-width: 1560px) {
  .global-table.job-list-large-screen .table tbody tr td table tbody tr td>div {
    line-height: 30px;
  }
  .job-list-large-screen.global-table.global-sm-table table table tbody tr td {
    height: 34px;
  }
  .job-list-large-screen .infinite-scroll-component {
    height: 290px !important;
  }
} */

.backward-arrow {
  color: #B72E28;
  padding: 0 !important;
  cursor: pointer;
  -webkit-transform: translateY(0) translateX(0) rotate(90deg);
}

.forward-arrow {
  color: #B72E28;
  padding: 0 !important;
  cursor: pointer;
  -webkit-transform: translateY(0) translateX(0) rotate(-90deg);
}

.arrows-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.mob-header-logo {
  display: none;
}

.showInMobile {
  display: none;
}

.manage-forget-modal {
  padding: 0 30px;
  /* text-align: center; */
}

.reset-password-link a {
  color: #000;
}

.tab-list ul li {}

.filter-btn-grp.inventory-finter-group .parts-group-btn {
  margin-right: 12px;
}

.inventory-header-btn.inventry-vehicle-btn a button {
  margin-right: 0;
}

.parts-group-btn button.inventory-archived-btn {
  margin-right: 0;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.infinite-scroll-component.job-link-table {
  overflow: visible !important;
}

.infinite-scroll-component.customeTable.notEmpty {
  height: auto !important
}

.infinite-scroll-component.customeTable.notEmpty.mng-table-ht {
  height: 400px !important
}

.infinite-scroll-component.customeTable {
  height: 30px !important
}

.inventory-header-btn {
  display: block !important;
  overflow: hidden;
}

.inventory-btn-groups {
  display: block !important;
}

.inventory-archived-btn {
  float: right;
}

#change-status-modal ul {
  padding-left: 0;
}

.sprite-icon.map-address {
  background: url(img/map-vr-2.png);
  background-size: cover;
  cursor: pointer;
}

.fixMargin div {
  margin-top: 0px;
}

.fixMargin label {
  display: none;
}

.fixTxtOverflow .select-selected {
  padding-left: 0px;
}

.images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.images img {
  max-width: -webkit-fill-available;
}

.bugDescriptionElipses {
  width: 183px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.MuiCardContent-root .form-group .form-control {
  width: 100%;
}

.dific-mng-width {
  width: 240px;
}

#job-link-modal-job-id {
  padding: 15px 0px;
}

.global-table.customer-table.rowwidthtasktable .table thead tr th:first-child {
  width: 14%;
}

.infinite-scroll-component.missedJobTables {
  max-height: 128px;
}

ul.recharts-default-legend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  overflow: auto;
  height: 36px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-align-items: flex-start;
}

ul.recharts-default-legend li {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

ul.recharts-default-legend li .recharts-legend-item-text {
  white-space: nowrap;
}

.question-width-report {
  width: 75%;
}

.cust-select-btns.remove-button-margin button:first-child {
  margin-right: 0px;
}

.global-table.customer-table .table.suppliyerToolTable thead tr th:nth-child(5) {
  width: 26%;
}

.global-table .table.vehiclesToolTableList tbody tr td table tbody tr td:first-child span {}

.global-table .table.jobsDueTable tbody tr td table tbody tr td:first-child span {
  width: 110px;
  display: inline-block;
}

.global-table .table.jobMissedTable tbody tr td table tbody tr td:first-child span {
  width: 110px;
  display: inline-block;
}

/* .global-table.customer-table .table.jobsDueTable thead tr th:nth-child(2) {
  padding-left: 30px;
} */

/* .global-table.customer-table .table.jobMissedTable thead tr th:nth-child(2) {
  padding-left: 30px;
} */

.notifivcatio-header {
  padding: 18px 25px;
}

.list-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.notification-listing {
  height: 330px;
}

.full-content {
  display: none;
}

.hoverToSeeMore:hover .full-content {
  display: block;
}

.notification-listing ul {
  padding: 0;
}

.notification-listing ul li {
  list-style: none;
}

.notification-listing ul li p {
  display: inline-block;
  margin-bottom: 0;
  padding-left: 30px;
}

.notification-listing ul li p.heading .cust-checkbox {
  position: absolute;
  left: 0;
  top: 28px;
}

.notification-listing ul li .list-wrap div p+p {
  margin-bottom: 4px;
}
.nonotifications {
  text-align: center;
  color: #dc3545;
}

.notification-listing ul li p+p {
  padding-left: 30px;
}

.notification-listing ul li .list-wrap {
  /* display: flex;
  justify-content: space-between; */
}

.notification-listing ul li .list-wrap div p+p {
  margin-bottom: 0px;
  display: block;
}

.hoverToSeeMore:hover .full-content {
  padding-top: 10px;
  padding-left: 25px;
}

span.clearClose {
  position: absolute;
  top: 16px;
  right: 16px;
  background-position: -30px -418px;
  display: inline-block;
  width: 12px;
  height: 12px;
  /* background: red; */
  background-position: -75px -135px;
}

span.recentClear {
  color: #B71B1B;
  cursor: pointer;
}

.notification-listing ul li {
  border-top: 1px solid #ccc;
  padding: 12px 12px 12px 10px;
  position: relative;
}

.list-wrap.hoverToSeeMore .cust-checkbox input:checked~.checkmark{
  left: 10px;
}
.list-wrap.hoverToSeeMore .delete-icon:hover:before{
  top: -11px;
  left: -12px;
}
/* .notification-listing ul li:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #B71B1B;
  position: absolute;
  left: 4px;
  border-radius: 2px;
  top: 18px;
} */

.notification-listing ul li:last-child {
  border-bottom: 1px solid #ccc;
}

.notifivcatio-header {
  padding: 18px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.notifivcatio-header h3 {
  margin-bottom: 0;
}

.notification-wrapper-date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

span.linkedIn {
  color: #B71B1B;
  text-decoration: underline;
}

.material-icons.md-light {
  color: white;
}
.material-icons.md-margin {
  margin-top: 25%;
  cursor: pointer;
}

.global-sm-table .sm-tool-mng-table .delete-icon {
  background: url(fonts/delete-vr-2.svg);
  top: 17px;
  width: 12px;
  background-repeat: no-repeat;
  background-size: contain;
}

.global-sm-table .sm-tool-mng-table tbody tr tbody tr:last-child .delete-icon:hover:before {
  top: -4px;
}

.sm-tool-mng-table .infinite-scroll-component {
  overflow: visible !important;
}

.row.manage-spcall {
  margin: 0;
}

.row.manage-spcall .custom-select2 {
  margin-bottom: 16px;
}

.row.manage-spcall input.form-control {
  margin-bottom: 16px;
}

#job-link .modal-body {
  height: 70vh;
  overflow: auto;
  margin-bottom: 6px;
}
#job-link .modal-body .modal-body{
  height: auto;
}

span.errorMessage.mng-error3 {
  position: relative;
  top: -10px;
}

.fab-container {
  bottom: 120px !important;
  position: fixed !important;
  margin: 1em !important;
  right: 10px !important;
}

.fab-container {
  display: none;
}

.OfflineSectionWebBtn p.badge, .chat-btn-link p.badge {
  margin: 0;
  position: absolute;
  background: #fff;
  padding: 3px;
  border-radius: 50%;
  height: auto;
  width: auto;
  min-width: 10px;
  padding: 3px 4px;
  font-size: 10px;
  top: -1px;
  right: -4px;
  border: 1px solid rgb(183, 27, 27);
}

.settingrow>div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-flex;
  -webkit-justify-content: space-between;
  width: 100%;
}

.ariesColorcss {
  color: rgb(183, 27, 27);
}
.settingrow{
  position: relative;
}
.settingrow .loader{
  position: absolute;
    top: 0;
    left: 50%;
    margin-left: -28px;
    margin-top: 10px;
}
.card-body.custom_assign_part{
  height: calc( 100vh - 240px );
}
.custom_assign_part .button_at_btm{
  position: absolute;
  width: calc(100% - 60px);
  bottom: 0;
}
.card-body.custom_assign_part .infinite-scroll-component {
  height: calc(100vh - 530px) !important;
}
.custom_assign_part .delete-icon.sprite-icon.sm-delete-icon{
  top: 30px;
}
.custom_assign_part .global-sm-table tbody tr tbody tr:last-child .delete-icon:hover:after {
  top: 24px;
  z-index: 9;
}
.custom_assign_part .global-sm-table .sm-tool-mng-table .delete-icon {
  top: 32px;
}
.subscription-plan#no-before {
  margin-top: 16px;
}
.global-table.customer-table .table.customerhome-tbl thead tr th:first-child {
  width: 29%;
}
.inventory-chart{
  background: #fff;
  padding: 30px 16px 16px;
  position: relative;
}
.inventory-chart h2{
  text-align: center;
  margin-bottom: 48px;
}
.all-steps-wrap ul {
  width: 45%;
  text-align: center;
}
.all-steps-wrap ul li p span {
  display: block;
  font-size: 25px;
  font-weight: 500;
}
.all-steps-wrap ul li{
  margin-bottom: 20px;
}
.all-steps-wrap ul li p{
  font-size: 24px;
  font-weight: 400;
}
.step-1-2-decr {
  position: absolute;
  top: 120px;
  left: 50%;
  min-height: 170px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-align-items: center;
  border-left: 3px solid #000;
    padding-left: 24px;
    padding-right: 20px;

}
.step-1-2-decr:before {
  content: '';
  position: absolute;
  left: -100px;
  top: 50%;
  width: 100px;
  height: 3px;
  background: #000;
  margin-top: -2px;
}
.step-1-2-decr p:before{
  content: '';
    position: absolute;
    left: -27px;
    top: -25px;
    width: 58px;
    height: 3px;
    background: #000;
    margin-top: -2px;
}
.step-1-2-decr p:after{
  content: '';
    position: absolute;
    left: -27px;
    bottom: -40px;
    width: 58px;
    height: 3px;
    background: #000;
    margin-top: -2px;
}
.step-1-2-decr p{
  font-size: 24px;
  font-weight: 400;
  position: relative;
  min-height: 104px;
  display: inline-grid;
  vertical-align: middle;
}

.add-report-wrapper{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.change-password-text {
  display: none;
}

.list-btn {
  margin-left: 10px !important;
}

.deficiency-work-order-dt-picker .SingleDatePicker_picker {
  bottom: 62px !important;
  left: -62px !important;
}

#job-link .user-address-fld-inn .ofc-addr p.email-id-icon.sprite-icon-before:before {
  right: -25px;
}

#job-link .user-address-fld-inn .ofc-addr p.email-id-icon.sprite-icon-before {
  width: 240px;
}

@media (max-width: 4000px) {
  .step-1-2-decr {
    left: 37%;
  }
}
@media (max-width: 2220px) {
  .step-1-2-decr {
    left: 40%;
  }
  .fixedpreview {
    width: 750px;
    height: 400px;
    overflow: auto;
  }
}

@media (max-width: 1800px) {
  .step-1-2-decr {
    left: 44%;
    /* min-height: 205px; */
  }
}

@media (max-width: 1230px) {
  #RBS-Scheduler-root .ant-row-flex .ant-col {
    top: -35px;
  }
}

@media (max-width: 1135px) and (min-width: 1025px) {
  .step-1-2-decr {
    /* left: 44%; */
    height: 186px;
  }
  .step-1-2-decr p:before{
    top: -31px;
  }
  .step-1-2-decr p:after{
    bottom: -45px;
  }
  .step-1-2-decr:before {
    top: 56%;
  }
}

@media (min-width: 1560px) {
  /* .infinite-scroll-component.customeTable.notEmpty{
    height: 30vh !important
  } */
  .global-table.job-list-large-screen .table tbody tr td table tbody tr td>div {
    line-height: 30px;
  }
  .job-list-large-screen.global-table.global-sm-table table table tbody tr td {
    height: 34px;
  }
  .infinite-scroll-component.missedJobTables {
    max-height: 290px !important;
  }
  .fixedpreview {
    width: 750px;
    height: 400px;
    overflow: auto;
  }
}

@media (min-width: 1100px) {
  #job-link .modal-body {
    height: 70vh;
  }
}

@media (min-width: 1400px) {
  #job-link .modal-body {
    height: auto;
  }
}

@media (min-width: 480px) {
  .ant-fullcalendar-fullscreen {
    margin-top: -70px;
  }
}

@media (max-width: 1365px) {
  span.pdf-icon.sprite-icon {
    right: 15px;
  }
  span.word-icon.sprite-icon {
    right: 50px;
  }
  span.note-icon.sprite-icon {
    right: 90px;
  }
  .tab-list ul li {
    /* min-width: 92px;
    max-width: fit-content;
    width: -webkit-fill-available; */
    /* min-width: 92px; */
    /* max-width: fit-content; */
    /* width: -webkit-fill-available; */
  }
}

@media (min-width: 1366px) {
  .analytic-boxes .analytic-box {
    padding-left: 40px;
  }
  .analytic-boxes .analytic-no {
    padding-right: 30px;
  }
  .analytic-boxes .analytic-text {
    max-width: 130px;
    z-index: 9;
  }
  .admin-table.global-table .table tbody tr td table tbody tr td:nth-child(5) {
    padding-left: 54px;
  }
}

@media (max-width: 1200px) {
  .customer-detail-modal {
    width: 100%;
  }
  .responsive-mob .search-container input {
    min-width: 194px;
  }
  .video-section .video-play-area {
    min-height: 100%;
  }
  .fixedpreview {
    width: 750px;
    height: 400px;
    overflow: auto;
  }
}

@media (min-width: 1560px) {
  .analytic-boxes .analytic-text {
    max-width: 170px;
  }
}

.fixedpreview {
  width: 750px;
  height: 400px;
  overflow: auto;
}

@media (max-width: 1154px) {
  .step-1-2-decr:before {
    left: -53px;
    width: 53px;
  }
}

@media (max-width: 1020px) {
  #RBS-Scheduler-root .ant-row-flex .ant-col {
    top: -66px;
  }
}

@media (max-width: 991px) {
  .ant-col>.header2-text {
    margin-left: 30px;
    margin-top: 6px;
  }
  .ant-col .header2-text .header2-text-label {
    font-size: 16px;
  }
  .settinglist {
    margin-bottom: 0;
    padding: 15px 0;
  }
}

.modal#imagemodalwidth {
  width: auto;
  margin-left: -400px !important;
  left: 50%;
  -ms-flex-item-align: center;
      align-self: center;
  overflow-x: hidden;
}

/*  Info pop-up on hover css */

.info-system-info-show-descr {
  width: 335px;
  height: 85px;
  background: #ffff00;
  color: black;
  border: solid 1.5px;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  top: -28px;
  left: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
  padding: 8px;
  z-index: 1000;
}

.mw-150 {
  min-width: 150px !important;
}

.card .datepicker-wrap .DateInput_fang {
  height: 11px;
}

@media (max-width: 991px) and (min-width: 760px) {
  .step-1-2-decr:before{
    top: 45%;
  }
  .step-1-2-decr p {
    font-size: 21px;
  }
  .responsive-mob .search-container input {
    min-width: 80%;
  }
  .select-job-modal-table .search-container input {
    min-width: 90%;
  }
  #master-acc .search-container input {
    min-width: 92%;
  }
  .report-container {
    height: 93%;
  }
  .modal#imagemodalwidth {
    width: inherit !important;
    margin-left: -384px !important;
  }
  .info-system-info-show-descr {
    top: 23px;
    left: -129px;
  }
  .card .datepicker-wrap .DateInput_fang {
    bottom: -55px !important;
  }
  .card .datepicker-wrap .SingleDatePicker_picker {
    top: 55px;
    bottom: 0px;
  }
  .team-table-body-delete-icon {
    right: 12px;
  }
  span.time-icon.sprite-icon1.team-table-body-time-icon {
    right: 37px;
  }
  .head-back-timecard-header {
    width: 350px;
    word-break: break-word;
  }
  .responsive-mob .team-card-inn-body .search-container input {
    min-width: 92%;
  }
  .responsive-mob .search-container .job-link-search {
    min-width: 40%;
  }
  .mw-150 {
    min-width: 234px !important;
  }
  .report-list-row-edit-icon {
    right: 60px;
  }
  .step-1-2-decr:before {
    left: -38px;
    width: 38px;
  }
}

@media (max-width: 900px) {
  #job-link .modal-body {
    height: 70vh;
  }
}

@media (max-width: 767px) {
  .all-steps-wrap ul li p span{
    font-size: 22px;
  }
  .step-1-2-decr{
    border-left: none;
  }
  #notification-mobile-dropdown {
    left: -930%;
    top: -523%;
    width: 360px;
    height: 517px;
    overflow: auto;
}
  .step-1-2-decr p , .all-steps-wrap ul li p{
    font-size: 20px;
  }
  .step-1-2-decr:before, .step-1-2-decr p:before, .step-1-2-decr p:after{
    display: none;
  }
  .inventory-chart h2 {
    font-size: 20px;
  }
  .all-steps-wrap ul {
    width: 100%;
    text-align: center;
    padding-left: 0;
    padding-top: 92px;
  }
  .step-1-2-decr {
    position: absolute;
    top: 90px;
    left: 0;
    text-align: center;
  }
  .card-body.custom_assign_part .infinite-scroll-component{
    height:  auto !important;
  }
  .custom_assign_part .button_at_btm{
    position: static;
  }
  .bugDescriptionElipsesMob{
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* display: inline-block; */
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
  }
  .global-table.customer-table .table.bugTicketTables thead tr th:first-child {
    width: 60%;
}
  .ofline-on-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .chat-wrap .chat.chat-responsive .no-chat-data {
    display: none;
  }
  span.notification-icon {
    width: 30px;
    height: 30px;
    display: block;
  }
  .fab-container {
    display: block;
  }
  .float-notification-icon .notification-icon {
    background: url(img/notification.png);
    width: 20px;
    height: 22px;
    background-size: cover;
  }
  .float-offline-icon {
    background: url(img/offline.png);
    width: 26px;
    height: 26px;
    background-size: cover;
  }
  .add-float_btn {
    background: url(img/flate-add-icon.png);
    width: 30px;
    height: 28px;
    background-size: cover;
  }
  .tale-part-search {
    position: relative;
  }
  .card.mng-on-res .card-body {
    padding: 30px 30px 20px !important;
  }
  .head-back {
    margin-bottom: 12px;
  }
  .content-box table tbody tr td table tbody tr.accordion-opened {
    overflow: visible;
  }
  .suppliyerToolTable .accordion-table>tbody>tr>td>h3 {
    min-width: 0;
    max-width: 0;
    padding-left: 0;
    margin-left: -9px;
  }
  .emplyee-list-rt {
    width: 100%;
  }
  .inventory-finter-group .parts-group-btn {
    display: block;
    width: auto;
    overflow-x: auto;
    margin-right: 0;
  }
  .filter-btn-grp.inventory-finter-group .parts-group-btn a {
    margin-bottom: 10px;
    display: block;
  }
  .filter-btn-grp.inventory-finter-group .parts-group-btn {
    margin-right: 0;
  }
  .accordion-opened .custom-select {
    min-width: 160px;
  }
  .global-table.customer-table.rowwidthtasktable .table thead tr th:first-child {
    width: 60%;
  }
  .global-table .table tbody tr td table tbody tr td:first-child span {
    width: 150px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .report-section {
    padding: 20px;
  }
  .user-name-add-inn .user-name-fld {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: self-start;
        -ms-flex-align: self-start;
            align-items: self-start;
    display: -webkit-flex;
    -webkit-align-items: self-start;
  }
  .global-table.customer-table.serviceRequestTableonly .table thead tr th:first-child {
    width: 100%;
  }
  .global-table .table tbody tr td table tbody tr td:first-child span {
    line-height: 22px;
  }
  .time-card-section .week-date {
    top: -16px;
    left: 0;
  }
  .info-timecard-info-show-descr {
    width: 248px;
    height: auto;
    background: #ffff00;
    color: black;
    border: solid 1.5px;
    font-size: 14px !important;
    line-height: 20px;
    position: absolute;
    top: 34px;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
    padding: 8px;
    z-index: 1000;
    /* margin-left: 0; */
  }
  .team-card-inn-body>ul>li:last-child .step-head, .team-card-inn-body>ul>li:last-child .link-form {
    padding-left: 62px;
  }
  .vertical-pannel-wrap .left-wrap-panel {
    width: 100%;
  }
  .user-name-add-inn {
    display: block;
  }
  .user-name-add-inn .user-address-fld {
    width: 100%;
  }
  .user-address-fld:before {
    content: "";
    width: calc(100% + 40px);
    height: 1px;
    position: absolute;
    border-bottom: 2px dashed #979797;
    top: -18px;
    left: -20px;
  }
  .desktop-hide {
    display: block;
  }
  .vertical-pannel-wrap .right-wrap-panel {
    display: none;
  }
  .vertical-pannel .nav-tabs {
    display: block;
  }
  .vertical-pannel .tab-content .tab-pane {
    display: block !important;
    opacity: 1;
    margin-bottom: 16px;
  }
  .global-table.customer-table .table thead tr th:first-child {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .responsive-mob .search-container input {
    /* min-width: 100%; */
  }
  .responsive-mob .filter-btn-grp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    display: -webkit-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-align-items: center;
    margin-top: 0px;
  }
  .hide-in-mobile-user-time-sheet-table {
    display: none;
  }
  .show-in-mobile-time-sheet-table {
    display: block;
  }
  ul.tg-list {
    padding-left: 40px;
  }
  .content-box table, .content-box tbody, .content-box tr, .content-box td, .content-box th {
    display: block;
    width: 100%;
  }
  .content-box table tbody tr td table tbody tr, .content-box table thead tr td table thead tr {
    max-height: 60px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
  .content-box tr:first-child td:first-child {
    color: #6e6e6e;
  }
  .content-box td h3 {
    display: block;
  }
  .team-card-inn-body>ul>li {
    padding-left: 62px;
  }
  .team-card-inn-body {
    padding: 15px;
  }
  .team-card-inn-body .c-checkboc {
    position: absolute;
    right: 0;
    left: auto;
    top: 15px;
  }
  .team-card-inn-body .step-head {
    margin-bottom: 44px;
  }
  .people-list {
    width: 100%;
    margin-right: 0;
    min-width: auto;
  }
  .chat-wrap .chat {
    width: 100%;
    z-index: 5;
    display: block;
  }
  .people-list-responsive {
    display: none;
  }
  .chat-header-back {
    display: block;
  }
  .chat-icon-bottom {
    display: block;
    background-color: #00000000;
    position: absolute;
    bottom: 20px;
    right: 10px;
    z-index: 5;
    box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.6);
    -o-box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.6);
    border-radius: 100px;
  }
  .income-chart-head-wrap {
    display: block;
  }
  .income-chart-rt {
    display: block;
    margin-top: 10px;
  }
  .income-chart-head-wrap .filter-dropdown {
    margin-left: 0px;
    margin-top: 10px;
  }
  .income-chart-head-wrap .dis-flex {
    margin-left: 0px;
  }
  .income-chart-head-wrap .dis-flex.min-134 {
    margin-right: 0;
  }
}

@media (max-width: 748px) {
  #RBS-Scheduler-root .ant-row-flex .ant-col {
    top: -35px;
  }
}

@media (max-width: 480px) {
  .deficiency-work-order-dt-picker .SingleDatePicker_picker {
    left: -6px !important;
  }
  .change-password-text {
    display: block;
  }
  .ant-popover-placement-bottom {
    top: 255px !important;
}
  .ant-fullcalendar-calendar-body {
    padding-top: 20px !important;
}
  .name-info-profile {
    width: 100%;
  }
  .image-on-edit-profile {
    float: initial;
  }
  .info-system-info-show-descr.inventory-responsive-msg {
    display: block;
  }
  #notification-mobile-dropdown {
    left: -752%;
    top: -523%;
    width: 290px;
    height: 517px;
    overflow: auto;
}
  .custom_assign_part .button_at_btm {
    position: static;
    display: block !important;
    width: 100%;
}
.custom_assign_part .button_at_btm button {
  display: block;
  width: 100%;
  margin-bottom: 12px;
}
  /* .global-table table table tbody tr td:first-child{
    padding-left: 0;
  } */
  .dific-mng-width {
    width: 200px;
  }
  .custom-select.min-250 {
    min-width: 200px;
  }
  .SingleDatePicker.SingleDatePicker_1 {
    margin-bottom: 16px;
  }
  .tab-list ul {
    width: 100%;
    margin-bottom: 16px;
  }
  .tab-list {
    display: block;
  }
  .global-table.customer-table.serviceRequestTableonly .table thead tr th:first-child {
    width: 100%;
  }
  .posi-relative.time-card-section {
    margin-top: 88px;
  }
  .global-table .table tbody tr td table tbody tr td:first-child span {
    line-height: 22px;
    top: 15px !important
  }
  .income-chart-head-wrap {
    display: block;
  }
  .income-chart-rt {
    display: block;
    margin-top: 16px;
  }
  .income-chart-rt .chart-month-select {
    margin-bottom: 10px;
  }
  .income-chart-head-wrap .filter-dropdown {
    margin-left: 0;
  }
  .income-chart-head-wrap .dis-flex {
    margin-left: 0;
    margin-right: 0;
  }
  .graph-area {
    min-height: 280px;
  }
  .revenue div {
    position: relative;
    top: 15px;
    padding-right: 10px;
    text-align: left;
  }
  .settinglist .cust-radio input {
    width: 40px;
  }
  .settingcontainer {
    padding: 30px 10px 0;
  }
  .settingheading {
    margin-left: 0;
  }
  .settingquickbook, .settinginvoice {
    padding-left: 0;
  }
  .settinginvoice {
    margin-bottom: 16px;
  }
  .manage-forget-modal {
    padding: 0px;
    /* text-align: center; */
  }
  .time-card-section .week-date {
    top: -16px;
    left: 0;
  }
  .info-timecard-info-show-descr {
    width: 248px;
    height: auto;
    background: #ffff00;
    color: black;
    border: solid 1.5px;
    font-size: 14px !important;
    line-height: 20px;
    position: absolute;
    top: 34px;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
    padding: 8px;
    z-index: 1000;
    /* margin-left: 0; */
  }
  .team-card-inn-body>ul>li:last-child {
    /* padding-left: 0; */
  }
  .team-card-inn-body>ul>li:last-child .step-head, .team-card-inn-body>ul>li:last-child .link-form {
    padding-left: 62px;
  }
  .vertical-pannel-wrap .left-wrap-panel {
    width: 100%;
  }
  .user-name-add-inn {
    display: block;
  }
  .user-name-add-inn .user-address-fld {
    width: 100%;
  }
  .user-address-fld:before {
    content: "";
    width: calc(100% + 40px);
    height: 1px;
    position: absolute;
    border-bottom: 2px dashed #979797;
    top: -18px;
    left: -20px;
  }
  .desktop-hide {
    display: block;
  }
  .vertical-pannel-wrap .right-wrap-panel {
    display: none;
  }
  .vertical-pannel .nav-tabs {
    display: block;
  }
  .vertical-pannel .tab-content .tab-pane {
    display: block !important;
    opacity: 1;
    margin-bottom: 16px;
  }
  .global-table.customer-table .table thead tr th:first-child {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .responsive-mob .filter-btn-grp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    display: -webkit-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-align-items: center;
    margin-top: 0px;
  }
  .hide-in-mobile-user-time-sheet-table {
    display: none;
  }
  .show-in-mobile-time-sheet-table {
    display: block;
  }
  ul.tg-list {
    padding-left: 40px;
  }
  .content-box table, .content-box tbody, .content-box tr, .content-box td, .content-box th {
    display: block;
    width: 100%;
  }
  .content-box table tbody tr td table tbody tr, .content-box table thead tr td table thead tr {
    max-height: 60px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
  .responsive-mob .search-container input {
    min-width: 194px;
  }
  .video-section .video-play-area {
    min-height: 100%;
  }
}

@media (min-width: 1560px) {
  .analytic-boxes .analytic-text {
    max-width: 170px;
  }
}

@media (max-width: 767px) {
  .quaterly-wrap-modal {
    display: block;
  }
  #job-link .modal-body {
    padding: 24px 12px;
  }
  .global-table .table tbody tr td table tbody tr td:first-child span {
    line-height: 22px;
    top: 14px !important;
}
  #job-link .user-address-fld-inn .ofc-addr p {
    margin-bottom: 16px;
  }
  #job-link p.email-id-icon.sprite-icon-before:before {
    right: 10px;
    top: -6px;
  }
  #job-link .quaterly-wrap-modal .address-icon.sprite-icon1 {
    top: 16px;
    right: 22px;
  }
  #job-link .job-link-date-wrap {
    display: block;
  }
  #job-link .cust-select-btns {
    display: block;
  }
  #job-link .cust-select-btns button {
    width: 100%;
    margin-bottom: 12px;
    min-height: 48px;
  }
  #job-link .job-id-note {
    width: calc(100% + 24px);
    margin-left: -12px;
  }
  #job-link .seperator {
    display: none;
  }
  #job-link .cust-select-btns button:first-child {
    margin-right: 0;
  }
  #job-link .cust-select-btns button.mr-rt-20 {
    margin-right: 0;
  }
  #job-link .job-link-date-wrap .job-link-date-left {
    margin-right: 0;
  }
  #job-link .job-link-date-right {
    margin-top: 24px;
  }
  .table-responsive .showInMobile {
    display: block !important;
  }
  .table-responsive .showInDesktop {
    display: none !important;
  }
  .time-card-section .week-date {
    top: -16px;
    left: 0;
  }
  .info-timecard-info-show-descr {
    width: 248px;
    height: auto;
    background: #ffff00;
    color: black;
    border: solid 1.5px;
    font-size: 14px !important;
    line-height: 20px;
    position: absolute;
    top: 34px;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
    padding: 8px;
    z-index: 1000;
    /* margin-left: 0; */
  }
  .content-box tr:first-child td:first-child {
    color: #6e6e6e;
  }
  .content-box td h3 {
    display: block;
  }
  .team-card-inn-body>ul>li {
    padding-left: 62px;
  }
  .team-card-inn-body {
    padding: 15px;
  }
  .team-card-inn-body .c-checkboc {
    position: absolute;
    right: 0;
    left: auto;
    top: 15px;
  }
  .team-card-inn-body .step-head {
    margin-bottom: 44px;
  }
  .people-list {
    width: 100%;
    margin-right: 0;
  }
  .chat-wrap .chat {
    width: 100%;
    z-index: 5;
    display: block;
  }
  .people-list-responsive {
    display: none;
  }
  .chat-header-back {
    display: block;
  }
  .chat-icon-bottom {
    display: block;
    background-color: #00000000;
    position: fixed;
    bottom: 20px;
    right: 10px;
    z-index: 5;
    box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.6);
    -o-box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.6);
    border-radius: 100px;
  }
}

@media (max-width: 480px) {
  .time-card-section .time-card {
    margin-bottom: 6px;
  }
  .time-card-section .week-date>span {
    width: 100%;
    margin-bottom: 4px;
  }
  .time-card-section .week-date>span.time-card-tooltip-parent {
    width: 30px;
    height: 24px;
    position: absolute;
    left: 78px;
    top: -2px;
  }
  .responsive-mob .search-container input {
    min-width: 100%;
  }
  .responsive-mob .filter-btn-grp {
    display: block;
  }
  .responsive-mob .filter-btn-grp .filter-row {
    margin-top: 16px;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-justify-content: flex-end;
  }
  .responsive-mob .filter-btn-grp button.btn {
    width: 100%;
  }
  .info-timecard-info-show-descr {
    width: 170px;
    padding: 8px 30px;
  }
  .team-card-inn-body hr.seperator {
    border-top: 1px dashed #979797;
    margin-top: 30px;
    margin-bottom: 30px;
    position: absolute;
    bottom: 18px;
    left: 66px;
    width: calc(100% - 66px);
    padding: 0;
    margin: 0;
  }
  .time-card-section .week-date {
    top: -72px;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    display: -webkit-flex;
    -webkit-justify-content: flex-start;
  }
  .week-date>span {
    padding-right: 12px;
    display: block;
    width: 100%;
    /* width: 100%; */
  }
  .team-card-inn-body .c-checkboc {
    position: absolute;
    right: 0;
    left: 62px;
    top: 60px;
  }
  .custom-select.min-176 {
    min-width: 120px;
  }
  .step-checks ul li {
    width: 100%;
    margin-bottom: 30px;
  }
}

/* css for job-link page*/

.dropdown-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.emplyee-list-rt.job-list {
  width: 316px;
}

.rectangle-copy-20 {
  height: 30px;
  width: 131px;
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
}

.dropdown-container>.dropdown.custom-drop-option .custom-select .select-selected {
  min-width: 120px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-right: 35px;
  padding-left: 10px;
  min-height: 35px;
  max-height: 35px;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 133px;
}

.emplyee-list-rt.job-list {
  width: 316px;
}

.rectangle-copy-20 {
  height: 30px;
  width: 131px;
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
}

.dropdown-container>.dropdown.custom-drop-option .custom-select .select-selected {
  min-width: 120px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-right: 35px;
  padding-left: 10px;
  min-height: 35px;
  max-height: 35px;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 133px;
}

.hardcode-frequency-dropdown-container>.dropdown.custom-drop-option .custom-select .select-selected {
  width: 145px;
}

.dropdown-container>.dropdown.drop-down-for-auto-route .custom-select .select-selected {
  width: 200px;
}

.dropdown-container>.dropdown.custom-drop-option .custom-select {
  height: 35px;
}

.dropdown-container .custom-drop-option {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-flex: 1;
}

.dropdown-container .custom-drop-option:first-child {
  margin-right: 8px;
}

.dropdown-container>.dropdown.custom-drop-option .custom-select .select-selected:after {
  top: 13px;
  right: 15px;
  width: 12px;
  height: 12px;
  background-size: cover;
  background-size: 600px;
  background-position: -76px 573px;
}

.dropdown-container>.dropdown.custom-drop-option .custom-select .select-selected.select-arrow-active:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

p.job-list-icon.sprite-icon-before1 {
  padding-left: 40px;
}

.analytic-boxes .analytic-no {
  padding-right: 30px;
}

.analytic-boxes .analytic-text {
  max-width: 130px;
  z-index: 9;
}

.admin-table.global-table .table tbody tr td table tbody tr td:nth-child(5) {
  padding-left: 54px;
}

@media (min-width: 1560px) {
  .analytic-boxes .analytic-text {
    max-width: 170px;
  }
}

.loader-container {
  text-align: center;
  padding-top: 5px;
  z-index: inherit;
  display: -webkit-flex;
  -webkit-justify-content: center;
}

.content-box table tbody tr td table tbody tr, .content-box table thead tr td table thead tr {
  max-height: 60px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.end-message {
  padding-top: 5px;
  color: #dc3545;
  text-align: center;
}

.content-box td h3 {
  display: block;
}

.team-card-inn-body>ul>li {
  padding-left: 62px;
}

.team-card-inn-body {
  padding: 15px;
}

.team-card-inn-body .step-head {
  margin-bottom: 44px;
}

.content-box table tbody tr td table tbody tr, .content-box table thead tr td table thead tr {
  max-height: 60px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.end-message {
  padding-top: 5px;
  color: #dc3545;
  text-align: center;
}

.content-box td h3 {
  display: block;
}

.team-card-inn-body>ul>li {
  padding-left: 62px;
}

.team-card-inn-body {
  padding: 15px;
}

.team-card-inn-body .step-head {
  margin-bottom: 44px;
}

/* css for job-link page*/

.dropdown-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.emplyee-list-rt.job-list {
  width: 315px;
}

.rectangle-copy-20 {
  height: 30px;
  width: 131px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
}

.dropdown-container>.dropdown.custom-drop-option>button {
  min-width: 120px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-right: 35px;
  padding-left: 10px;
  min-height: 35px;
  max-height: 35px;
}

p.job-list-icon.sprite-icon-before1 {
  padding-left: 40px;
}

p.job-list-icon.sprite-icon-before1:before {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  background-position: -14px -514px;
}

.hide {
  display: none;
}

.lower-arrow:before {
  background-position: -14px -400px !important;
}

/*CSS for job link page ends here*/

.loader-container {
  text-align: -webkit-center;
  padding-top: 5px;
  z-index: inherit;
}

.loader, .pageLoader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #dc3545;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  z-index: inherit;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.end-message {
  padding-top: 5px;
  color: #dc3545;
  text-align: -webkit-center;
}

#overlay, #overlayModal, #overlayLoader {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

#overlayModal {
  z-index: 1050;
}

#overlayLoader {
  z-index: 1060;
  background-color: rgba(243, 239, 239, 0.568);
}

.page-loader-container {
  z-index: inherit;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
}

.pageLoader {
  width: 100px;
  height: 100px;
}

.errorMessage {
  color: #dc3545;
}

input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.validation-error-style {
  color: #dc3545;
}

.stripe-buttons-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.stripe-buttons-container .StripeCheckout {
  margin-top: 10px;
  color: #000!important;
  overflow: hidden!important;
  display: inline-block!important;
  background: transparent!important;
  padding: 1px!important;
  text-decoration: none!important;
  -webkit-box-shadow: none!important;
          box-shadow: none!important;
  cursor: pointer!important;
  visibility: visible!important;
  -webkit-user-select: none!important;
     -moz-user-select: none!important;
      -ms-user-select: none!important;
          user-select: none!important;
  border-radius: 0!important;
  display: block!important;
  text-align: center!important;
  border: 0!important;
  color: #6aa14e !important;
  font-size: 14px!important;
  line-height: 24px!important;
  outline: none;
}

.stripe-buttons-container .StripeCheckout span {
  text-decoration: underline;
  font-size: 14px!important;
  position: relative!important;
  padding: 0px 12px!important;
  display: block!important;
  height: 30px!important;
  line-height: 30px!important;
  color: #000!important;
  font-weight: 300!important;
  -webkit-box-shadow: rgba(255, 255, 255, 0.25) 0px 1px 0px inset!important;
          box-shadow: rgba(255, 255, 255, 0.25) 0px 1px 0px inset!important;
  text-shadow: none!important;
  border-radius: 4px!important;
  background-image: none !important;
}

.stripe-buttons-container .StripeCheckout span:hover {
  color: #b71b1b!important;
}

.default-stripe-case .StripeCheckout, .StripeCheckout1 {
  width: 100%;
  display: block;
  border: 1.5px solid #6aa14e !important;
  color: #6aa14e !important;
  min-width: 154px !important;
  min-height: 40px !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: center;
  background: #fff !important;
  font-weight: 500 !important;
  padding: 6px 18px !important;
}

.default-stripe-case .StripeCheckout span, .StripeCheckout1 span {
  position: relative;
  background-image: none !important;
  font-family: 'Rubik', sans-serif !important;
  font-size: 16px !important;
  padding: 0 !important;
  display: block;
  height: 40px !important;
  line-height: 40px !important;
  color: #6aa14e !important;
  font-weight: 500 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  text-shadow: none !important;
  border-radius: 0 !important;
  display: inline-block !important;
}

.default-stripe-case .StripeCheckout span:before, .StripeCheckout1 span:before {
  content: '';
  background: url(img/sprite1.png);
  background-size: 600px;
  position: absolute;
  top: 8px;
  left: -30px;
  background-position: -97px -64px;
  width: 20px;
  height: 20px;
}

.StripeCheckout1Es span:before {
  left: 4px;
}

.StripeCheckout1Es span {
  line-height: 27px !important;
}

.default-stripe-case .StripeCheckout:hover, .StripeCheckout1:hover {
  border: 1.5px solid #6aa14e !important;
  color: #fff !important;
  background: #6aa14e !important;
}

.default-stripe-case .StripeCheckout:hover span, .StripeCheckout1:hover span {
  color: #fff !important;
}

.default-stripe-case .StripeCheckout:hover span:before, .StripeCheckout1:hover span:before {
  background-position: -126px -64px;
}

.disable_btn.btn {
  background-color: #6c757d;
  color: white;
  cursor: not-allowed;
}

.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  max-height: 80px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
}

/*  Table head for mobile view and tablet view */

.show-in-mobile-table-head {
  display: none;
  /* TErms and condition design */
}

.terms-condtions-content-area {
  font-size: 15px;
}

.tc-main-point {
  font-size: 18px;
  font-weight: 500;
}

.terms-condition-points {
  padding: 20px;
}

.terms-condition-heading {
  font-weight: 500;
  font-size: 20px;
}

.terms-condition-sub-points {
  padding-left: 20px;
}

.temrs-condition-sub-subtype {
  padding-left: 25px;
  padding-top: 2px;
}

.main-container.analytic-id {
  position: relative;
}

.main-container.analytic-id:before {
  display: block;
  content: '';
  background: url(img/analytic-bg.png);
  height: 364px;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}

.analytic-id h1 {
  color: #ffffff;
  position: relative;
}

.analytic-id h1 {
  color: #ffffff;
  position: relative;
}

.buttonWraper {
  margin: auto;
  width: 100%;
  text-align: center;
  margin-top: 5%;
}

/* Tool tip on sytsm info */

.info-system-info {
  background-position: -541px -262px;
  width: 46px;
  height: 20px;
  position: relative;
  min-width: 30px;
  display: inline-block;
}

.info-wrapper {
  display: inline;
}

.customer-portal-tooltip {
  width: 320px;
  height: 43px;
  left: 28px;
  top: -10px;
}

/*  Info pop-up on hover css */

/* span sprite icon on time-card in edit mode */

span.time-card.sprite-icon-before:before {
  background-position: -60px -483px;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 136px;
  top: 1px;
}

.global-table.customer-table.rowwidthtasktable .table thead tr th:nth-child(6) {
  width: 12%;
}

.global-table.customer-table.rowwidthtasktable .table thead tr th:nth-child(5) {
  width: 9%;
}

.global-table.customer-table.rowwidthtasktable .table thead tr th:nth-child(7) {
  width: 18%;
}

.global-table.customer-table.rowwidthtasktable .table tbody tr td table tbody tr td:nth-child(6) {
  width: 11%;
}

.global-table.customer-table.rowwidthtasktable .table tbody tr td table tbody tr td:nth-child(4) {
  width: 11%;
}

.global-table.customer-table.rowwidthtasktable .table tbody tr td table tbody tr td:first-child {
  width: 16%;
}

#imagemodalwidth {
  overflow-x: hidden !important;
}

#imagemodalwidth .images img {
  max-width: initial;
}

#language-label-color {
  color: rgb(183, 27, 27);
}

/* Hide mobile Table */

.showTableInMobileWebView {
  display: none;
}

.form-error-forbasic-detail-1 {
  float: right;
  top: -136px;
}

.form-error-poition-basic-detail-2 {
  float: right;
  top: '-117px'
}

.customer-table .table-striped tbody tr tbody tr.schedule-table-tooltip:last-child:hover .tooltip-part {
  top: -80px;
}

.imagepreviewwidth {
  width: 80px;
  margin-left: 20px;
}

.customer-table .table-striped tbody tr tbody tr.schedule-table-tooltip:last-child:hover .tooltip-part:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #4c4c4c;
  position: absolute;
  top: auto;
  right: 10px;
  bottom: -8px;
}

.imagepreviewclass {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.buttonbottommargin {
  margin-bottom: 20px;
}

.customer-table .table-striped tbody tr tbody tr.schedule-table-tooltip:nth-last-child(2):hover .tooltip-part {
  top: -80px;
}

.customer-table .table-striped tbody tr tbody tr.schedule-table-tooltip:nth-last-child(2):hover .tooltip-part:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #4c4c4c;
  position: absolute;
  top: auto;
  right: 10px;
  bottom: -8px;
}

.map-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-position: -145px -10px;
  position: absolute;
  right: 34px;
  top: 23px;
  cursor: pointer;
  background: url(img/map-vr-2.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.map-icon:hover:before {
  content: "";
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: -7px;
}

.map-icon:hover:after {
  content: "Map";
  width: 66px;
  height: 25px;
  background: #4C4C4C;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 36px;
  left: -29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
}

.cross-button {
  position: relative;
  height: 2em;
  width: 1em;
  background: white;
  color: darkblue;
  cursor: pointer;
  left: -26px;
  top: 10px;
  font-weight: 500;
  margin-right: -14px;
  border-radius: 0px 4px 4px 0px;
}

.cross-icon {
  background-position: -13px -377px;
  width: 0px;
  height: 18px;
  left: 336px;
  top: -23px;
  position: absolute;
  min-width: 30px;
  display: inline-block;
  z-index: 2000;
  cursor: pointer;
}

.cross-icon-timeCard {
  left: 409px;
  top: -9px;
  background-position: -13px -377px;
  width: 0px;
  height: 18px;
  position: absolute;
  min-width: 30px;
  display: none;
  z-index: 2000;
  cursor: pointer;
}

.no-option-dropValue {
  font-weight: 500;
  color: #4c4c4c;
  padding: 8px 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 36px;
  border-bottom: 1px solid #CBD1D5;
}

.checkbox-billable-hr-rate {
  float: right;
}

.signupTableForm .wrapper .card .card-heading+div {
  height: calc(100vh - 144px) !important;
}

.classToAddCustomScroll .card {
  height: calc(100vh + 64px) !important;
}

.classToAddCustomScroll.manage-ht .card {
  height: 72vh !important;
}

#import-customer-btn {
  text-align: center;
}

.task-number {
  position: relative;
}

.task-number>.delete-icon {
  top: 8px;
  right: 0;
}

#rowDataForImage {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#descrip-task-error {
  color: 'red';
}

button.nextBtnInAddMember {
  float: right;
}

.btn-wrapper-add-member {
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.partsHeaderPrice {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.partsPriceLevelIcon .edit-icon {
  position: relative;
  margin-right: 10px;
  left: 0;
  top: 0px;
}

.partsPriceLevelDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 390px;
  margin-right: 8px;
}

.partsPriceLevelDiv input {
  margin-right: 15px;
}

.partImagePreview {
  height: 110px;
  overflow: hidden;
}

.partImagePreview img {
  max-width: 100%;
}

#static_row {
  background: rgba(77, 188, 233, .1);
  border-left: 2px solid #4DBCE9;
  border-right: 2px solid #4DBCE9;
}

.Toastify__toast-container {
  text-align: center;
  text-align: -webkit-center;
}

.Toastify__close-button {
  display: none;
}

.add-image-build-info {
  display: inline-block;
  width: 50%;
  position: absolute;
  right: -112px;
}

.heading-image-build-info {
  padding-bottom: 15px;
  display: inline-block;
  width: 50%;
}

.delete-image-build-info {
  /* background-position: -58px 55px; */
  top: 11px;
  left: 20px;
  width: 20px;
  position: absolute;
  cursor: pointer;
  height: 20px;
  background-repeat: no-repeat;
}

.breadcrumes ul li {
  color: #535252;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-decoration: none;
}

.qr-code-card-body {
  padding: 30px !important;
}

.calender-date-popover .popover-title {
  font-size: 16px;
  color: #4c4c4c;
  font-weight: 600;
  white-space: normal;
  word-break: break-all;
  cursor: pointer;
  width: 225px;
}

.scheduler-content-table .popover-type {
  display: block;
  background: #f2f2f2;
  padding: 0px 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  margin-left: 0px;
  padding-left: 22px;
  font-weight: normal;
  font-size: 12px;
  position: absolute;
  width: calc(100% + 4px);
  left: -1px;
  bottom: -2px;
  color: #4c4c4c;
}

.dnd-row {
  padding-right: 25px;
  word-break: normal;
}

.scheduler-content-table .popover-quote, .job-details-hover .popover-quote {
  position: absolute;
  right: 1px;
  top: 8px;
  background: #6AA14E;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  padding: 0px;
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
}

.job-details-hover .popover-quote {
  margin-right: 5px;
  margin-top: -4px;
}

.job-details-hover #I, .job-details-hover #Q, .job-details-hover #W, .job-details-hover #WO {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
}
.mr-top-10 {
  margin-top: 0px !important;
}
.mr-top-20 {
  margin-top: 20px;
}
#I {
  background: #b71b1b;
}

#W, #WO {
  background: #4dbce9;
}

.calender-pop-date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.calender-pop-date .popover-hour {
  font-size: 26px;
  color: #4c4c4c;
  font-weight: 400;
}

.calender-pop-date .popover-day {
  font-size: 12px;
  color: #4c4c4c;
}

.inspectbutton {
  -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
  background-color: #FFFFFF;
  border-radius: 4px;
  min-height: 30px;
  border: 1px solid #6AA14E;
  width: auto;
  min-width: auto;
  font-weight: normal;
  color: #6AA14E;
  padding: 5px 12px;
}

.cursor {
  cursor: 'pointer';
}

.calender-pop-date .start-date {
  margin-right: 16px;
}

.calender-pop-date .end-date {
  margin-left: 16px;
}

.scheduler {
  margin-top: 75px;
}

.scheduler-content-table .popover-type::before {
  content: "";
  width: 5px;
  height: 5px;
  border: 1px solid #4c4c4c;
  border-radius: 50%;
  position: absolute;
  left: 12px;
  top: 7px;
}

#RBS-Scheduler-root .scheduler-content-table .event-container .timeline-event .round-all {
  padding-right: 22px !important;
  padding-top: 9px;
}

.OfflineSectionWebBtn {
  display: -webkit-inline-box;
  text-align: center;
  float: right;
  margin: 0;
  margin-right: 18px;
  font-size: 15px;
  color: #4C4C4C;
  position: relative;
}

.subDivOfflineBtn {
  margin-right: 35px;
  margin-top: 2px;
  margin-bottom: 0px;
}

.circleBorderOfOffline {
  background: rgb(183, 27, 27);
  border-radius: 50%;
  width: 46px;
  height: 47px;
}

.offlineIconSprite {
  height: 26px;
  width: 24px;
  cursor: pointer;
  margin-top: 10px;
  background-size: cover;
}

#RBS-Scheduler-root thead tr td div {
  margin-bottom: 0px !important;
}

#RBS-Scheduler-root thead tr .header3-text {
  word-break: break-all !important;
}

.ant-pagination-item-active {
  border-color: #bb2f2a;
}

.ant-pagination-next a:hover, .ant-pagination-prev a:hover {
  border-color: #bb2f2a;
}

.messageToGoOfflineMode {
  display: -webkit-inline-box;
}

.buttonWrapperForOfflineSupport {
  display: -webkit-inline-box;
  padding-bottom: 17px;
  padding-left: 15px;
}

.time-card-tooltip {
  top: 25px;
  left: -289px;
}

.time-card-tooltip-parent {
  padding-right: 0px;
  width: 0px;
}

.customer-portal-info {
  position: inherit;
  width: 347px;
  height: 50px;
}

.customer-portal-info-box {
  color: black;
  border: solid 1.5px;
  border-radius: 50%;
  font-size: small;
  font-weight: 500;
  text-align: center;
  min-width: 23px;
}

#download-icon-on-offline-page {
  left: -17px;
}

.remove-offline {
  left: 12px;
}

#ellipsesCollumn {
  position: relative;
}

@media (max-width: 480px) {
  .jobsDueTable .accordion-table > tbody > tr > td > h3 {
    min-width: 92px;
  }
  .signupTableForm .wrapper .card .card-heading+div {
    height: 100vh !important;
  }
  .wrapper--w790 .title {
    font-size: 16px;
  }
  .mr-left-9 {
    margin-left: 9%;
  }
  .step-checks ul li {
    width: 100%;
    margin-bottom: 30px;
  }
  .time-card-tooltip {
    top: 25px;
    left: -50px;
  }
  .info-system-info-show-descr {
    width: 242px;
    top: 26px;
    left: -178px;
  }
  .dropdown-container>.dropdown.drop-down-for-auto-route .custom-select .select-selected {
    width: 100%;
  }
  .partsPriceLevelDiv {
    width: 188px;
    overflow: scroll;
  }
  .filter-btn-grp.inventory-finter-group .parts-group-btn {
    margin-bottom: 0;
  }
  .inventory-finter-group .dis-flex {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-justify-content: space-between;
  }
  .inventory-finter-group .dis-flex .filter-row {
    margin-top: 0;
  }
}

@media (max-width: 360px) {
  .chat-list-btn {
    padding: 6px;
  }
  #notification-mobile-dropdown {
    left: -624%;
    top: -523%;
    width: 263px;
    height: 517px;
    overflow: auto;
  }
}

.mr-top-10{
  margin-top: 10px !important;
}

.dropdown-container>.dropdown.drop-down-for-auto-route .custom-select .select-selected {
  width: 100%;
}

.choose-modal-btns {
  width: 50%;
}

#make-notes.modal .modal-dialog {
  max-width: 730px;
}

#make-notes .note-description-area {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* max-height: 500px; */
}

#make-notes .note-description-area label {
  font-size: large;
  margin: 20px 0;
}

.datepicker-wrap.edit-job-modal-datepicker-icon.sprite-icon-before:before {
  top: 24px;
}

#reset-password-input-field {
  width: 100%;
}

#logout-dropdown li:hover {
  background: rgba(196, 196, 196, 0.4);
}

#logout-dropdown div:hover {
  color: #4a4a4a;
  font-weight: 500;
}

#auto-routing-buttons {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.error-date-picker {
  position: absolute;
  bottom: -25px;
  left: 0px;
}

.joblink-date-picker-error-handling {
  position: relative;
}

#job-link .user-address-fld-inn .ofc-addr p #joblink-email {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  word-break: break-all;
  max-width: 185px;
}

#no-before:before {
  content: none;
}

.user-confirmation-modal-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.user-confirmation-modal-buttons {
  width: 48%;
}

.card .offline-enhanced {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.sidebarinventoryvehiclepadding {
  padding-right: 8px !important;
}

.unreadnotification, .notification-listing li {
  font-size: 15px;
  line-height: 19px;
  color: #4C4C4C;
}

.list-header {
  font-size: 17px;
  line-height: 19px;
  color: #4C4C4C;
  font-weight: 500;
}

p.nonotifications {
  font-size: 15px;
  line-height: 19px;
  margin-top: 30px;
}

#notification-dropdown::-webkit-scrollbar {
  display: none;
}

table.table.table-striped.table-sm.customerhome-tbl tbody tr tbody tr:nth-of-type(odd):hover {
  background-color: #f2f2f2;
}

table.table.table-striped.table-sm.customerhome-tbl tbody tr tbody tr:nth-of-type(even):hover {
  background-color: unset;
}

#inventory-add-part-btn {
  font-size: 12px;
}

.inventory-archived-btn-add-parts {
  font-size: 12px;
}

.field-icon {
  position: relative;
  z-index: 4;
}

.fa-fw {
  position: absolute;
  width: 42px;
  height: 24px;
  top: 10px;
  right: 0px;
}

.fa-eye {
  background: url(img/eye-icon-show.png);
  background-position: 4px -4px;
  background-size: 36px;
}

.fa-eye-slash {
  background: url(img/eye-icon-hide.png);
  background-position: 4px -4px;
  background-size: 36px;
}

.unmask-pw-field {
  padding-right: 42px !important;
}

.form-mask-icon {
  top: 39px;
  right: 20px;
}

#edit-job-address {
  width: auto !important;
  margin-right: 20px;
  word-break: break-word !important;
}

@media (max-width: 1100px) {
  .modal#imagemodalwidth {
    width: auto;
    margin-left: -351px !important;
    left: 50%;
    -ms-flex-item-align: center;
    align-self: center;
    overflow-x: hidden;
}
.fixedpreview {
  width: 550px;
  height: 400px;
  overflow: auto;
}
}

@media (max-width: 991px) {
.modal#imagemodalwidth {
    margin-left: -384px !important;
}
}

@media (max-width: 767px) {
  .fixedpreview {
    width: 350px;
    height: 400px;
    overflow: auto;
  }
  .modal#imagemodalwidth {
    width: auto;
    margin-left: -248px !important;
    left: 50%;
    -ms-flex-item-align: center;
    align-self: center;
    overflow-x: hidden;
}
}

@media (max-width: 480px) {
  .fixedpreview {
    width: 220px;
    height: 368px;
    overflow: auto;
}
.modal#imagemodalwidth {
  width: auto;
  margin-left: -176px !important;
  left: 50%;
  -ms-flex-item-align: center;
  align-self: center;
  overflow-x: hidden;
}
.image-reset-button {
  min-width: 0px !important;
  padding: 5px;
}

}

#user-name .logout-wrapper .logout-sprite-icon {
  min-width: initial;
}

.logout-sprite-icon {
  width: 20px;
    height: 20px;
    position: unset;
    margin-right: 5px;
    background-position: -9px -524px;
}
/*
.table-striped tbody tr tbody tr:last-child */

.admin-table.global-table .table tbody tr td table tbody tr:last-child td:last-child .open-icons .email:hover:after,
.admin-table.global-table .table tbody tr td table tbody tr:last-child td:last-child .open-icons .download:hover:after,
.admin-table.global-table .table tbody tr td table tbody tr:last-child td:last-child .open-icons .user:hover:after,
.admin-table.global-table .table tbody tr td table tbody tr:last-child td:last-child .open-icons .delete:hover:after,
.admin-table.global-table .table tbody tr td table tbody tr:last-child td:last-child .open-icons .edit:hover:after {
  top: -36px;
}

.admin-table.global-table .table tbody tr td table tbody tr:first-child td:last-child .open-icons .email:hover:after,
.admin-table.global-table .table tbody tr td table tbody tr:first-child td:last-child .open-icons .download:hover:after,
.admin-table.global-table .table tbody tr td table tbody tr:first-child td:last-child .open-icons .user:hover:after,
.admin-table.global-table .table tbody tr td table tbody tr:first-child td:last-child .open-icons .delete:hover:after,
.admin-table.global-table .table tbody tr td table tbody tr:first-child td:last-child .open-icons .edit:hover:after {
  top: 36px;
}

.inv-vehicle-dropdown {
  min-width: 160px !important;
}

.resource-table tbody tr td .driving-child:hover:after,
.resource-table tbody tr td .working-child:hover:after,
.resource-table tbody tr td .miscellaneous-child:hover:after,
.resource-table tbody tr td .checkout-child:hover:after,
.resource-table tbody tr td .break-child:hover:after {
  content: 'Change working status.';
  width: 195px;
  height: 25px;
  background: #337AB7;
  color: #fff;
  font-size: 13px;
  line-height: 16px;
  position: absolute;
  top: 12px;
  font-weight: normal;
  left: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  border-radius: 2px;
  z-index: 100;
}

.resource-table tbody tr td .driving-child:hover:before,
.resource-table tbody tr td .working-child:hover:before,
.resource-table tbody tr td .miscellaneous-child:hover:before,
.resource-table tbody tr td .checkout-child:hover:before,
.resource-table tbody tr td .break-child:hover:before {
  content: " ";
  position: absolute;
  top: 25px;
  right: -10%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #337AB7 transparent transparent;
  z-index: 100;
}

#joblink-division-tooltip {
  padding-bottom: 7px !important;
}

.devices-table td, .devices-table th {
  padding: 10px;
}

.devices-table tr:nth-child(even) {
  background-color: #f1f1f1;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.justify-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.items-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.opacity-70 {
  opacity: 0.7;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-medium {
  font-weight: 500;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.text-right {
  text-align: right;
}

.bg-gray-200 {
  background-color: #e6e6eb;
}

.rounded {
  border-radius: 4px;
}

.px-2 {
  padding-left: 5px;
  padding-right: 5px;
}

.py-1 {
  padding-left: 2px;
  padding-right: 2px;
}
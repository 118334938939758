button.close.sprite-icon {
    width: 20px;
    height: 20px;
    display: none;
    background-position: -30px -418px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    outline: none;
    opacity: 1;
    -ms-flex-item-align: center;
        align-self: center;
}
.show-reports, .close-report-pannel-btn{
    display: none;
}

/* Tablet Screen , small desktop and mobile devices */

@media(max-width: 1024px) {
    .show-reports{
        display: block;
    } 
    .report-container{
        display: none;
        position: absolute;
        right:0;    
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
       z-index: 9;
    }
    .modal-header{
        background-color: #B71B1B;
        border-radius: 0em;
    }
   
    .reports-modal-title{
        color:white;
    }
    button.close.sprite-icon{
        display: inline-block;
    }
}

@media (max-width: 991px) {
    .report-container {
        height: 93%;
    }
}

@media(max-width: 480px) {
    .report-container{
        width: 100%;
        min-height: 500px;
    }
}

@media(min-width:1025px){
    .modal-header{
        background-color:black;
        color: white;
    }
}


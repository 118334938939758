.topLayer{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.bottomLayer{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.datepicker{
    margin-left: 58%;
    margin-bottom: 20px;
}
.taskheading{
    font-size: 30px;
}
.filter-width{
    width: 1000px;
}
.topright{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}
.margin{
    margin-top:0px;
}
.wid{
    width:50px;
}
.picker{
    position: relative;
    margin-top:0px;
}

.nullborder{
    border: 0px;
}

@media (max-width: 480px) {
    .topright{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        padding-left: 59%;
    }
    .toprightWithoutcal {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        padding-left: 73%;
    }
}